<template>
  <div>
    <article>
      <div class="reccomend">
        <b-container fluid class="border-box">
          <b-row class="border-box">
            <b-col class="border-box" xl="6" md="6">
              <div
                v-for="(mobilePhone, index) in this.productsByMobileNumber"
                :key="index"
                class="contact-item"
              >
                <div class="icon">
                  <img src="@/assets/images/support.svg" />
                </div>
                <div>
                  <strong v-for="(product, index) in mobilePhone" :key="index">
                    <span v-if="index === mobilePhone.length - 1">{{
                      product
                    }}</span>
                    <span v-else>{{ product }}, </span>
                  </strong>
                  <br />{{
                    getPersonByMobilePhone(index).last_name +
                      " " +
                      getPersonByMobilePhone(index).first_name
                  }}
                  <br />{{ getPersonByMobilePhone(index).mobile_phone }}
                  <br />{{ getPersonByMobilePhone(index).email }}
                </div>
              </div>
              <div class="contact-item">
                <div class="icon">
                  <img src="@/assets/images/location.svg" />
                </div>
                <div>
                  <strong>{{$t("registeredAddressTitle")}}</strong>
                  <br>{{$t("registeredAddressText1")}}
                  <br>{{$t("registeredAddressText2")}}
                  <br>
                  <br>
                  <strong>{{$t("mailingAddressTitle")}}</strong>
                  <br>{{$t("mailingAddressText1")}}
                  <br>{{$t("mailingAddressText2")}}
                </div>
              </div>
              <div class="contact-item">
                <div class="icon">
                  <img src="@/assets/images/phone.svg" />
                </div>
                <div>
                  <a href="tel:021.402.82.23">021.402.82.23</a>
                </div>
              </div>

              <div class="contact-item">
                <div class="icon">
                  <img src="@/assets/images/chat.svg" />
                </div>
                <div>
                  <a href="mailto:info@upromania.ro">info@upromania.ro</a>
                </div>
              </div>

              <div class="contact-item">
                <div class="icon">
                  <img src="@/assets/images/phonechat.svg" />
                </div>
                <div>{{ $t("workingHours") }}</div>
              </div>

              <div class="contact-item">
                <div class="icon">
                  <img src="@/assets/images/CRAVATA.svg" />
                </div>
                <div>
                  {{$t("commerceRegisterID")}}:
                  <br />J40/6468/2002 <br />{{$t("vat")}}: RO 14774435 <br />Capital
                  social: 27.459.160 RON
                  <br />
                </div>
              </div>
            </b-col>
            <b-col class="border-box" offset-xl="1" offset-md="1" xl="5" md="5">
              <div class="feedback-form">
                <div class="feedback-form-title">
                  <h3>{{ $t("feedbackFormTitle") }}</h3>
                </div>
                <div v-if="sentFeedbackError === null">
                  <p v-if="!successSentFeedback">
                    <textarea
                      class="feedback-input"
                      v-model="feedbackMessage"
                      :placeholder="[[$t('placeholderComment')]]"
                    />
                  </p>
                  <a
                    v-if="!successSentFeedback"
                    class="button-orange"
                    @click="sendEmailFeedback()"
                    >{{ $t("textButtonForm") }}</a
                  >
                  <p v-else>{{ $t("msgEmailSuccess") }}</p>
                </div>
                <div v-else>
                  <p
                    v-if="this.sentFeedbackError"
                    v-html="$t('errorActionBeneficiary_500')"
                  ></p>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-container>

        <b-container fluid class="border-box">
          <b-row class="border-box"> </b-row>
        </b-container>

        <br />
        <br />
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2848.797446360822!2d26.190733999997764!3d44.437316997789864!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40b1f959ffca1fd1%3A0x435ca0830a29767e!2sUp+Romania!5e0!3m2!1sen!2sro!4v1435231670508"
          style="border:0"
          width="100%"
          height="300"
          frameborder="0"
        ></iframe>
      </div>
    </article>
  </div>
</template>
<script>
import { BContainer, BRow, BCol } from "bootstrap-vue";
import Cookies from "js-cookie";
import httpServiceCustomer from "@/http/http-customer.js";

export default {
  props: {
    affiliate: Object
  },
  components: {
    "b-container": BContainer,
    "b-row": BRow,
    "b-col": BCol
  },
  data() {
    return {
      feedbackMessage: null,
      successSentFeedback: false,
      sentFeedbackError: null
    };
  },
  methods: {
    getPersonByMobilePhone(mobilePhone) {
      for (let index in this.affiliate.products) {
        if (
          this.affiliate.products[index].person.mobile_phone === mobilePhone
        ) {
          return this.affiliate.products[index].person;
        }
      }
    },
    sendEmailFeedback() {
      let formData = {
        code: this.affiliate.company_id,
        description: this.feedbackMessage
      };
      httpServiceCustomer.customerService
        .sendFeedbackMail(formData, Cookies.get("token"), "AFF")
        .then(function(response) {
          return response;
        })
        .then(response => {
          if (response.status == 200) {
            this.successSentFeedback = true;
            // download content
          } else if (response.status == 400 || response.status == 403) {
            this.sentFeedbackError = response.response;
          } else if (response.status == 404) {
            // show file not found
            this.sentFeedbackError = response.response;
          }
        })
        .catch(error => {
          this.sentFeedbackError = {
            code: error.response.status
          };
        });
    }
  },
  computed: {
    productsByMobileNumber() {
      let returnProducts = Object.create(null);
      this.affiliate.products.forEach(p =>
        (returnProducts[p.person.mobile_phone] =
          returnProducts[p.person.mobile_phone] || []).push(p.name)
      );
      return returnProducts;
    }
  },
  mounted() {}
};
</script>
<style scoped src="@/assets/styles/beneficiary.css"></style>

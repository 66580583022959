import { render, staticRenderFns } from "./Stickers.vue?vue&type=template&id=afbd8a74&scoped=true"
import script from "./Stickers.vue?vue&type=script&lang=js"
export * from "./Stickers.vue?vue&type=script&lang=js"
import style0 from "@/assets/styles/beneficiary.css?vue&type=style&index=0&id=afbd8a74&prod&scoped=true&lang=css&external"
import style1 from "./Stickers.vue?vue&type=style&index=1&id=afbd8a74&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "afbd8a74",
  null
  
)

export default component.exports
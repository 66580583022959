<template>
  <div>
    <div class="banner">
      <b-carousel id="carousel-1" :interval="4000" indicators>
        <b-carousel-slide
          v-for="(entry, index) in affiliateBanners"
          :key="index"
        >
          <template v-slot:img>
            <a
              :href="entry.link"
              target="_blank"
              @click="
                entry.category === 'Up Achizitii'
                  ? sendClickToGA(
                      entry.category,
                      entry.action,
                      affiliate.company_id
                    )
                  : sendClickToGA(entry.category, entry.action, entry.label)
              "
            >
              <img :src="require('@/assets/images/banner/' + entry.fileName)" />
            </a>
          </template>
        </b-carousel-slide>
      </b-carousel>
    </div>
    <br />

    <div class="notification desktop" v-if="okForAdditional">
      <b-container fluid class="border-box">
        <b-row class="border-box">
          <b-col class="border-box" xl="12" md="12">
            <img
              class="d-block mx-auto mb-3"
              src="@/assets/images/banner/afiliat/tazz.png"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col class="border-box" xl="8" md="8">
            <h2>
              {{ $t("signAdditionalActAffiliate") }}
            </h2>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="border-box" xl="9" md="9">
            <small>{{ $t("requestNotifEmailLabel") }}</small>
            <form
              @submit.prevent="
                {
                  return false;
                }
              "
            >
              <input
                id="email_notif"
                type="text"
                class="formedituser"
                v-model="emailNotif"
              />
            </form>
          </b-col>
          <b-col class="border-box" xl="3" md="3">
            <a class="notification-action" @click="requestAdditionalAAct()">
              {{ $t("requestAdditionalTextButton") }}
            </a>
          </b-col>
        </b-row>
        <br />
      </b-container>
    </div>

    <div
      class="notification desktop"
      v-if="
        (!affiliate.ral ||
          !affiliate.ral.email ||
          !affiliate.ral.email.includes('@')) &&
          affiliate.user_config &&
          affiliate.user_config.roles &&
          !affiliate.user_config.roles.includes('EDITOR')
      "
    >
      <table cellpadding="10" cellspacing="0" width="95%" align="center">
        <tr>
          <td width="7%" valign="top">
            <img src="@/assets/images/alerte.svg" />
          </td>
          <td width="75%" valign="top">
            <h2>
              {{ $t("requestMandateTitleSection") }}
            </h2>
            <p class="gri">{{ $t("noRalText") }}</p>
          </td>
          <td width="18%">
            <a   @click="pushRoute('affiliate_account')" class="notification-action cursor-pointer">{{
              $t("posNoMandateTextButton")
            }}</a>
          </td>
        </tr>
      </table>
    </div>

    <div class="tabs-container">
      <div class="tabs">
        <a
          v-for="(entry, index) in affiliate.products"
          :key="entry.name"
          @click="selectProduct(index)"
          :class="entry.name === selectedProduct.name && 'active'"
        >
          <img
            :src="
              require('@/assets/images/logo-up-' +
                (entry.card_profile
                  ? entry.card_profile
                  : entry.card
                  ? entry.card
                  : entry.paper) +
                '.png')
            "
          />
        </a>
      </div>
    </div>

    <div class="allstats-affiliate">
      <div class="content-allstats">
        <div
          class="balance-circle mobile"
          :class="
            'border-color-' +
              (selectedProduct.card
                ? selectedProduct.card
                : selectedProduct.paper)
          "
        >
          <img
            :src="
              require('@/assets/images/logo-up-' +
                (selectedProduct.card
                  ? selectedProduct.card
                  : selectedProduct.paper) +
                '.png')
            "
          />
        </div>
        <div class="arrow-left" @click="previousProduct()">
          <img src="@/assets/images/arrow-left.svg" />
        </div>

        <div class="arrow-right" @click="nextProduct()">
          <img src="@/assets/images/arrow-right.svg" />
        </div>
        <br clear="all" />
      </div>
      <div class="navigator mobile">
        <div
          v-for="(entry, index) in affiliate.products"
          :key="index"
          @click="selectProduct(index)"
          :class="
            entry.name === selectedProduct.name ? 'bubble' : 'bubble-stroke'
          "
        ></div>
      </div>
    </div>
    <div class="container-alb mt-0">
      <div class="filter">
        <select @change="selectPeriod" v-model="selectedPeriod">
          <option value="1">{{ $t("transactionStatsDDLLastMonth") }}</option>
          <option value="3">
            {{ $t("transactionStatsDDLLastMonths", { index: 3 }) }}
          </option>
          <option value="6">
            {{ $t("transactionStatsDDLLastMonths", { index: 6 }) }}
          </option>
          <option value="9">
            {{ $t("transactionStatsDDLLastMonths", { index: 9 }) }}
          </option>
          <option value="12">
            {{ $t("transactionStatsDDLLastMonths", { index: 12 }) }}
          </option>
        </select>
      </div>
      <br />
      <br />
      <div class="chart-wrapper w-40">
        <apexchart
          type="radialBar"
          :options="chartRadialOptions"
          :series="seriesForRadial"
        >
        </apexchart>
      </div>
      <div class="chart-wrapper w-60">
        <apexchart
          type="line"
          :options="chartLineOptions"
          :series="seriesForLine"
        >
        </apexchart>
      </div>
    </div>

    <!-- <div class="mobile-banner">
      <b-carousel id="carousel-2" :interval="4000" indicators>
        <b-carousel-slide
          v-for="(entry, index) in affiliateBanners"
          :key="index"
        >
          <template v-slot:img>
            <a
              :href="entry.link"
              target="_blank"
              @click="
                entry.category === 'Up Achizitii'
                  ? sendClickToGA(
                      entry.category,
                      entry.action,
                      affiliate.company_id
                    )
                  : sendClickToGA(entry.category, entry.action, entry.label)
              "
            >
              <img :src="require('@/assets/images/banner/' + entry.fileName)" />
            </a>
          </template>
        </b-carousel-slide>
      </b-carousel>
    </div> -->

    <div class="container-alb">
      <p class="invoice-title">
        <strong>{{ $t("lastReimbursementsTitle") }}</strong>
      </p>
      <br />
      <div
        v-if="
          this.affiliateReimbursements &&
            this.affiliateReimbursements.length > 0
        "
      >
        <ReimbursementsTable
          @click="selectReimbursement"
          :reimbursements="this.affiliateReimbursements"
        />
        <div class="text-center mt-5">
          <a 
          @click="pushRoute('affiliate_repayment')"
          class="notification-action cursor-pointer">{{
            $t("seeAllInvoicesButtonLabel")
          }}</a>
        </div>
      </div>
      <div class="error-wrapper border-box" v-else>
        {{ $t("noReimbursementsMessage") }}
      </div>
    </div>

    <div class="container-alb">
      <p class="invoice-title">
        <strong>{{ $t("lastInvoicesTitle") }}</strong>
      </p>
      <br />
      <div
        v-if="
          this.affiliateLastInvoices && this.affiliateLastInvoices.length > 0
        "
      >
        <InvoiceTableDesktop
          :company="this.affiliate"
          :companyInvoices="this.affiliateLastInvoices"
        />
        <div class="text-center mt-5">
          <a
            :href="'/affiliate/invoices?product=' + this.selectedProduct.card"
            class="notification-action"
            >{{ $t("seeAllInvoicesButtonLabel") }}</a
          >
        </div>
      </div>
      <div class="error-wrapper border-box" v-else>
        {{ $t("noInvoicesMessage") }}
      </div>
    </div>

    <div class="banner-stats">
      <p class="invoice-title text-left">
        <strong>{{ $t("increaseSaleText") }}</strong>
      </p>
      <br />
      <div class="circle2 stats-circle">
        <h1 v-if="this.dashboardInfos">
          {{ filterDashboardInfos("MEDIUM_TRANS") }} {{ $t("currency") }}
        </h1>
        <small>{{ $t("mediumCartText") }}</small>
      </div>
      <div class="circle1 stats-circle">
        <h1 v-if="this.dashboardInfos">
          {{ filterDashboardInfos("TOTAL_HOLDERS") }}
        </h1>
        <small>{{ $t("monthlyVisitsText") }}</small>
      </div>
      <div class="banner-square">
        <b-carousel id="carousel-1" :interval="4000" indicators>
          <b-carousel-slide>
            <template v-slot:img>
              <img
                src="@/assets/images/cross sell Up Dejun.jpeg"
                style="width: 100%;"
              />
            </template>
          </b-carousel-slide>
          <b-carousel-slide>
            <template v-slot:img>
              <img
                src="@/assets/images/cross sell Up Cadou.jpeg"
                style="width: 100%;"
              />
            </template>
          </b-carousel-slide>
          <b-carousel-slide>
            <template v-slot:img>
              <img
                src="@/assets/images/cross sell Up Vacanta.jpeg"
                style="width: 100%;"
              />
            </template>
          </b-carousel-slide>
          <b-carousel-slide>
            <template v-slot:img>
              <img
                src="@/assets/images/cross sell Up Cultura.jpeg"
                style="width: 100%;"
              />
            </template>
          </b-carousel-slide>
        </b-carousel>
      </div>
      <br clear="all" />
    </div>

    <!-- <div class="container-alb offers-tab">
      <p class="invoice-title text-left">
        <strong>{{ $t("yourOffersLabel") }}</strong>
      </p>
      <br />
      <div class="offers">
        <div class="offer d-flex align-items-center flex-column flex-md-row">
          <div class="w-md-40 order-md-2 mb-md-0 mb-3">
            <img class="w-100" src="https://app.uponline.ro/img/affiliate/offer_2/bestsmart.jpg" alt="">
          </div>
          <div class="w-md-60 order-md-1">
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque sit amet laoreet justo. Ut ac purus dapibus, varius justo ut, gravida tellus. Donec molestie velit ut quam facilisis ornare. Praesent tincidunt ligula nisl, ultrices porta lacus ultricies in. Maecenas nunc velit, volutpat in nisl a, semper cursus eros.</p>
            <br>
            <a href="/affiliate/offers" class="notification-action">{{ $t("addOfferButtonLabel") }}</a>
          </div>
        </div>
      </div>
    </div> -->

    <div id="modal_popup_error" class="modal-popup">
      <div class="modal-popup-content">
        <a
          @click="showModalPopup('modal_popup_error', false)"
          class="close-button"
        >
          <img src="@/assets/images/close-button.svg" />
        </a>
        <p>{{ $t("paperServerDownError") }}</p>
        <a
          class="button-orange"
          @click="showOrHideFirstPopup('modal_popup_error')"
          >{{ $t("nextStepButtonLabel") }}</a
        >
      </div>
    </div>
    <div id="modal_popup_detailsReimbursements" class="modal-popup">
      <div class="modal-popup-content">
        <a
          @click="showModalPopup('modal_popup_detailsReimbursements', false)"
          class="close-button"
        >
          <img src="@/assets/images/close-button.svg" />
        </a>
        <div v-if="reimbursementDetails" class="text-left scroll-popup">
          <h6>
            {{
              $t("reimbursementNumber") +
                " " +
                reimbursementDetails.tally_sheet.tallysheet_id
            }}
          </h6>
          <br />
          <table
            cellpadding="10"
            cellspacing="0"
            width="95%"
            align="center"
            style="font-size: 12px"
          >
            <tr class="trhover">
              <td valign="middle">
                <strong>{{ $t("reimbursementHeaderID") }}: </strong>
              </td>
              <td valign="middle">
                <span>{{
                  reimbursementDetails.tally_sheet.tallysheet_id
                }}</span>
              </td>
            </tr>
            <tr class="trhover gri-cell">
              <td valign="middle">
                <strong>{{ $t("reimbursementHeaderDate") }}: </strong>
              </td>
              <td valign="middle">
                <span>{{
                  displayDate(reimbursementDetails.tally_sheet.creation_date)
                }}</span>
              </td>
            </tr>
            <tr class="trhover">
              <td valign="middle">
                <strong>{{ $t("reimbursementHeaderValueDeclared") }}: </strong>
              </td>
              <td valign="middle">
                <span>{{ reimbursementDetails.tally_sheet.total_value }}</span>
              </td>
            </tr>
            <tr class="trhover gri-cell">
              <td valign="middle">
                <strong>{{ $t("reimbursementHeaderValueRead") }}: </strong>
              </td>
              <td valign="middle">
                <span>{{ reimbursementDetails.tally_sheet.read_value }}</span>
              </td>
            </tr>
          </table>

          <div class="prog-bar" v-if="reimbursementDetails.tally_sheet.states">
            <ul class="step-pog-bar">
              <li
                v-for="(step, index) in reimbursementDetails.tally_sheet.states"
                :key="index"
                class="reimbursements active"
              >
                {{ $t("reimbursementStep_" + step.state_id) }}
                <br />
                {{ displayDateAndTime(step.state_date) }}
              </li>
            </ul>
          </div>
          <table
            cellpadding="10"
            cellspacing="0"
            width="95%"
            align="center"
            class="hover-table"
            style="font-size: 12px"
          >
            <template
              v-for="(detail, index) in reimbursementDetails.tally_sheet
                .products"
            >
              <tr
                :key="index"
                class="trhover parent"
                :class="index % 2 === 0 && 'gri-cell'"
              >
                <td>
                  <a
                    @click="selectTypeTicketMobile(index)"
                    class="detailsOrderMobile display-inline-block"
                  >
                    <font-awesome-icon
                      icon="minus-circle"
                      v-if="detailsMobile && detail === selectedTypeTicket"
                    />
                    <font-awesome-icon icon="plus-circle" v-else />&nbsp;&nbsp;
                  </a>
                  <strong>{{
                    $t("reimbursementTicketTypeLabel", {
                      name: getProductNameMethod(detail.product)
                    })
                  }}</strong>
                </td>
              </tr>
              <tr
                :key="index + reimbursementDetails.tally_sheet.products.length"
                :class="index % 2 === 0 && 'gri-cell'"
                v-show="detailsMobile && detail === selectedTypeTicket"
              >
                <td colspan="12">
                  <table
                    cellpadding="2"
                    cellspacing="0"
                    width="95%"
                    align="center"
                  >
                    <tr>
                      <td>
                        <strong
                          >{{ $t("reimbursementTicketTypeInvoiceLabel") }}:
                        </strong>
                      </td>
                      <td>
                        <span>{{
                          detail.invoice_series + detail.invoice_number
                        }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong
                          >{{ $t("reimbursementTicketTypeInvoiceValueLabel") }}:
                        </strong>
                      </td>
                      <td>
                        <span>{{ detail.invoice_value }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong
                          >{{ $t("reimbursementTicketTypeValueLabel") }}:
                        </strong>
                      </td>
                      <td>
                        <span>{{ detail.paid_value }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong
                          >{{ $t("reimbursementTicketTypePaymentDateLabel") }}:
                        </strong>
                      </td>
                      <td>
                        <span>{{ displayDate(detail.paid_date) }}</span>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </template>
          </table>
          <br />
          <br />
          <br />
          <div>
            <a
              v-if="reimbursementDetails.tally_sheet.content"
              href="#"
              @click="downloadReimbursement()"
            >
              <img
                class="download d-inline-block"
                src="@/assets/images/download.svg"
              />
              {{ $t("reimbursementDownloadLabel") }}
            </a>
            <span v-else>{{ $t("reimbursementNoDownloadLabel") }}</span>
          </div>
        </div>
      </div>
    </div>
    <div id="modal_popup_loading" class="modal-popup">
      <div class="modal-popup-content">
        <img src="@/assets/images/loading.gif" />
        <p v-html="$t('loadingPopupMessage')"></p>
      </div>
    </div>
    <div id="modal_popup_notif" class="modal-popup">
      <div class="modal-popup-content-notif">
        <a
          @click="showModalPopup('modal_popup_notif', false)"
          class="close-button"
        >
          <img src="@/assets/images/close-button.svg" />
        </a>
        <LinkFrame
          v-if="this.uuid !== ''"
          :urlAddress="
            'https://crm.uponline.ro/doc/afiliat-contract-electronic?uuid=' +
              this.uuid
          "
        />
      </div>
    </div>
  </div>
</template>

<script>
import InvoiceTableDesktop from "@/components/company/InvoiceTableDesktop.vue";
import ReimbursementsTable from "@/components/company/affiliate/ReimbursementsTable.vue";
import affiliateBanner from "@/assets/json/affiliate_banner.js";
import LinkFrame from "@/components/beneficiary/LinkFrame.vue";
import fieldValidation from "@/plugins/fieldValidation";

import httpServiceAffiliate from "@/http/http-affiliate.js";
import httpServiceUser from "@/http/http-user.js";
import moment from "moment";
import Cookies from "js-cookie";
import {
  BContainer,
  BRow,
  BCol,
  BCarousel,
  BCarouselSlide
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import utils from "@/plugins/utils.js";

export default {
  props: {
    affiliate: Object,
    user: Object,
    msg: String
  },
  components: {
    InvoiceTableDesktop,
    LinkFrame,
    "b-container": BContainer,
    "b-row": BRow,
    "b-col": BCol,
    "b-carousel": BCarousel,
    "b-carousel-slide": BCarouselSlide,
    apexchart: VueApexCharts,
    ReimbursementsTable
  },
  data() {
    return {
      affiliateBanners: affiliateBanner,
      affiliateDataLoaded: false,
      affiliateBeneficiaries: null,
      selectedProduct: null,
      affiliateLastInvoices: null,
      productIndex: 0,
      config: JSON.parse(sessionStorage.getItem("affiliate_jsonFile")),
      selectedPeriod: 3,
      transactionChartData: null,
      transactionMapData: null,
      dashboardInfos: null,
      dashboardError: null,
      affiliateReimbursements: null,
      okForAdditional: false,
      chartLineOptions: {},
      seriesForLine: [
        {
          name: "Valoare tranzactie",
          data: []
        },
        {
          name: "Numar tranzactii",
          data: []
        }
      ],
      seriesForRadial: [],
      chartRadialOptions: {},
      reimbursementDetails: null,
      selectedTypeTicket: null,
      detailsMobile: false,
      uuid: "",
      emailNotif: ""
    };
  },
  methods: {
    selectProduct(index) {
      this.selectedProduct = this.affiliate.products[index];
      this.transactionMapData = null;
      this.totalTransactionMap = 0;
      this.transactionMapData = null;
      this.dashboardInfos = null;
      this.getAffiliateDashboardInfo();
    },
    previousProduct() {
      let numberOfProduct = this.affiliate.products.length;
      this.productIndex--;
      if (this.productIndex < 0) {
        this.productIndex = numberOfProduct - 1;
      }
      this.selectProduct(this.productIndex);
    },
    nextProduct() {
      let numberOfProduct = this.affiliate.products.length;
      this.productIndex++;
      if (this.productIndex === numberOfProduct) {
        this.productIndex = 0;
      }
      this.selectProduct(this.productIndex);
    },

    getAffiliateDashboardInfo() {
      this.getLastInvoices();
      this.getHistoryReimbursements();
      this.getTransactionsVolumeMapData();
      this.getTransactionsVolumeChartData();
      this.getDashboardGeneralInfos();
    },
    getLastInvoices() {
      var invoicesData =
        "?type=" +
        "affiliate" +
        "&brand=" +
        this.selectedProduct.brand +
        "&paid=L,R,P,U" +
        "&start_date=" +
        "20200101" +
        "&end_date=" +
        moment().format("YYYYMMDD") +
        "&per_page=2" +
        "&page=1" +
        "&desc=date" +
        "&sort=desc";

      httpServiceUser.userService
        .getCompanyInvoices(
          this.affiliate.company_id,
          invoicesData,
          Cookies.get("token")
        )
        .then(function(response) {
          return response;
        })
        .catch(error => {
          this.dashboardError = {
            code: error.response.data,
            description: "Error fetching last invoices"
          };
          this.totalTransactionMap = 0;
        })
        .then(response => {
          if (response.status == 200) {
            this.affiliateLastInvoices = response.data;
          } else if (response.status == 400 || response.status == 403) {
            this.dashboardError = {
              code: response.data,
              description: "Error fetching last invoices"
            };
            this.totalTransactionMap = 0;
          } else {
            this.dashboardError = { description: response.description };
            this.totalTransactionMap = 0;
          }
        });
    },
    getHistoryReimbursements() {
      this.affiliateReimbursements = null;
      var reimbursementsData =
        "?start_date=" +
        "20200101" +
        "&end_date=" +
        moment().format("YYYYMMDD") +
        "&per_page=2" +
        "&page=1" +
        "&desc=date" +
        "&sort=desc";

      httpServiceAffiliate.affiliateService
        .getHistoryReimbursements(
          this.affiliate.company_id,
          reimbursementsData,
          Cookies.get("token")
        )
        .then(function(response) {
          return response;
        })
        .then(response => {
          if (response.status == 200) {
            this.affiliateReimbursements = response.data;
          } else if (response.status == 400 || response.status == 403) {
            this.dashboardError = {
              code: response.data,
              description: "Error fetching invoices"
            };
          } else {
            this.dashboardError = { description: response.description };
          }
        })
        .catch(error => {
          this.dashboardError = {
            code: error.response.data,
            description: "Error fetching invoices"
          };
        });
    },
    getTransactionsVolumeMapData() {
      if (!this.selectedProduct.card) {
        return;
      }
      var transactionsData =
        "?type=" +
        "tr.number.aff" +
        "&customer_id=" +
        this.affiliate.company_id +
        "&product=" +
        this.selectedProduct.card +
        "&months=" +
        this.selectedPeriod;

      httpServiceAffiliate.affiliateService
        .getTransactionVolume(transactionsData, Cookies.get("token"))
        .then(function(response) {
          return response;
        })
        .then(response => {
          if (response.status == 200) {
            this.totalTransactionMap = response.headers["x-up-count1"] * 1;
            this.transactionMapData = response.data;
            if (this.transactionMapData.length === 0) {
              this.totalTransactionMap = 0;
            }
            this.updateChartLine();
          } else if (response.status == 400 || response.status == 403) {
            this.dashboardError = {
              code: response.data,
              description: "Error fetching transactions"
            };
            this.totalTransactionMap = 0;
          } else {
            this.dashboardError = { description: response.description };
            this.totalTransactionMap = 0;
          }
        })
        .catch(error => {
          this.dashboardError = {
            code: error.response.data,
            description: "Error fetching transactions"
          };
          this.updateChartLine();
        });
    },
    getTransactionsVolumeChartData() {
      var transactionsData =
        "?type=" +
        "tr.summary.aff" +
        "&customer_id=" +
        this.affiliate.company_id +
        "&product=" +
        this.selectedProduct.card +
        "&brand=" +
        this.selectedProduct.brand +
        "&months=" +
        this.selectedPeriod;

      httpServiceAffiliate.affiliateService
        .getTransactionVolume(transactionsData, Cookies.get("token"))
        .then(function(response) {
          return response;
        })
        .then(response => {
          if (response.status == 200) {
            this.transactionChartData = response.data;
            if (
              this.transactionMapData &&
              (!this.transactionMapData.length ||
                this.transactionMapData.length === 0)
            ) {
              this.totalTransactionMap = 0;
            }
            this.updateChartRadial();
          } else if (response.status == 400 || response.status == 403) {
            this.dashboardError = {
              code: response.data,
              description: "Error fetching transactions"
            };
            this.totalTransactionMap = 0;
          } else {
            this.dashboardError = { description: response.description };
            this.totalTransactionMap = 0;
          }
        })
        .catch(error => {
          this.dashboardError = {
            code: error.response.data,
            description: "Error fetching transactions"
          };
          this.totalTransactionMap = 0;
        });
    },
    getDashboardGeneralInfos() {
      var formData =
        "?customer_id=" +
        this.affiliate.company_id +
        "&fiscal=" +
        this.affiliate.fiscal_code +
        "&brand=" +
        this.selectedProduct.brand;

      httpServiceAffiliate.affiliateService
        .getDashboardInfos(
          this.affiliate.company_id,
          formData,
          Cookies.get("token")
        )
        .then(function(response) {
          return response;
        })
        .then(response => {
          if (response.status == 200) {
            this.dashboardInfos = response.data;
          } else if (response.status == 400 || response.status == 403) {
            this.dashboardError = {
              code: response.data,
              description: "Error fetching dashboard info data"
            };
          } else {
            this.dashboardError = { description: response.description };
          }
        })
        .catch(error => {
          this.dashboardError = {
            code: error.response.data,
            description: "Error fetching dashboard info data"
          };
        });
    },
    getStyle(width) {
      return "width:" + width + "%";
    },
    showModalPopup(elementId, show) {
      if (show) {
        document.getElementById(elementId).style.visibility = "visible";
      } else {
        document.getElementById(elementId).style.visibility = "hidden";
      }
    },
    showOrHideFirstPopup(modal_id) {
      Cookies.set("first", true);
      this.showModalPopup(modal_id, false);
    },
    affiliateHasPaperProduct(entry) {
      let hasProduct = false;
      this.affiliate.products.forEach(prod => {
        if (prod.brand === entry) {
          hasProduct = true;
        }
      });
      return hasProduct;
    },
    selectPeriod(event) {
      this.selectedPeriod = event.target.value;
      this.getTransactionsVolumeMapData();
      this.getTransactionsVolumeChartData();
    },
    displayDate(date) {
      return moment(new Date(date), "YYYY-MM-DDThh:mm:ss").format("DD/MM/YYYY");
    },
    updateChartLine() {
      var trans = [];
      var date = [];
      var transNumber = [];
      this.transactionMapData.forEach(tr => {
        trans.push(tr.transactions_amount);
        date.push(this.displayDate(tr.transactions_date));
        transNumber.push(parseInt(tr.transactions_number));
      });
      this.seriesForLine = [{ data: trans }, { data: transNumber }];
      this.chartLineOptions = {
        chart: {
          toolbar: {
            show: false
          }
        },
        dataLabels: {
          enabled: false
        },
        colors: ["#f79000", "#864e00"],
        xaxis: {
          categories: date,
          labels: {
            rotate: -45,
            style: {
              fontFamily: "Roboto"
            }
          },
          tickPlacement: "on"
        },
        yaxis: [
          {},
          {
            opposite: true
          }
        ],
        legend: {
          show: true,
          position: "top"
        },
        stroke: {
          width: 2
        }
        // responsive: [
        //   {
        //     breakpoint: 380,
        //     options: {
        //       xaxis: {
        //         labels: {
        //           rotate: -45
        //         }
        //       },
        //       yaxis: {
        //         show: false
        //       }
        //     }
        //   }
        // ]
      };
    },
    updateChartRadial() {
      var transCard = this.transactionChartData[0]
        ? this.transactionChartData[0].transactions_amount
        : 0;
      var paperReimbursements = this.transactionChartData[1]
        ? this.transactionChartData[1].transactions_amount
        : 0;
      var max = paperReimbursements + transCard;
      var paper =
        paperReimbursements > 0
          ? ((paperReimbursements * 100) / max).toFixed(0)
          : 0;
      var card = transCard > 0 ? ((transCard * 100) / max).toFixed(0) : 0;
      this.seriesForRadial = [paper, card];
      this.chartRadialOptions = {
        chart: {
          type: "radialBar"
        },
        plotOptions: {
          radialBar: {
            offsetY: 0,
            startAngle: 0,
            endAngle: 270,
            hollow: {
              size: "70%",
              margin: 20,
              background: "transparent",
              image: undefined
            },
            dataLabels: {
              name: {
                fontSize: "20px",
                color: "#864e00",
                offsetY: -30
              },
              value: {
                fontSize: "30px",
                color: "#f79000",
                formatter: function(val) {
                  return val + " %";
                }
              },
              total: {
                color: "#864e00",
                show: true,
                label: "Venituri aduse",
                formatter: function(w) {
                  return paperReimbursements + transCard + " lei";
                }
              }
            }
          }
        },
        colors: ["#f79000", "#864e00"],
        labels: ["Rambursari tichete", "Tranzactii card"],
        legend: {
          show: true,
          floating: true,
          fontSize: "10px",
          position: "left",
          offsetX: 0,
          offsetY: 0,
          labels: {
            useSeriesColors: true
          },
          markers: {
            width: 5,
            height: 5
          },
          itemMargin: {
            vertical: 0
          }
        }
      };
    },
    selectReimbursement(reimbursement, action) {
      if (action === "details") {
        this.getDetailsReimbursement(reimbursement.tally_sheet.tallysheet_id);
      }
    },
    getDetailsReimbursement(reimbursementId) {
      this.reimbursementDetails = null;
      this.showModalPopup("modal_popup_loading", true);

      httpServiceAffiliate.affiliateService
        .getDetailsReimbursement(
          this.affiliate.company_id,
          reimbursementId,
          Cookies.get("token")
        )
        .then(function(response) {
          return response;
        })
        .then(response => {
          if (response.status == 200) {
            this.reimbursementDetails = response.data;
            this.showModalPopup("modal_popup_loading", false);
            this.showModalPopup("modal_popup_detailsReimbursements", true);
          } else if (response.status == 400 || response.status == 403) {
            this.reimbursementError = {
              code: response.data,
              description: "Error fetching invoices"
            };
            this.showModalPopup("modal_popup_loading", false);
          } else {
            this.reimbursementError = { description: response.description };
            this.showModalPopup("modal_popup_loading", false);
          }
        })
        .catch(error => {
          this.reimbursementError = {
            code: error.response.data,
            description: "Error fetching invoices"
          };
          this.showModalPopup("modal_popup_loading", false);
        });
    },
    displayDateAndTime(date) {
      return moment(new Date(date), "YYYY-MM-DDThh:mm:ss").format(
        "DD/MM/YYYY hh:mm:ss"
      );
    },
    getProductNameMethod(productId) {
      return utils.getCompanyProductName(productId, this.affiliate.products);
    },
    selectTypeTicketMobile(index) {
      this.selectedTypeTicket = this.reimbursementDetails.tally_sheet.products[
        index
      ];
      this.detailsMobile = !this.detailsMobile;
    },
    downloadReimbursement() {
      var blob = new Blob(
        [
          utils.base64ToArrayBuffer(
            this.reimbursementDetails.tally_sheet.content
          )
        ],
        {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheetapplication/vnd.ms-excel"
        }
      );
      saveAs(
        blob,
        this.reimbursementDetails.tally_sheet.tallysheet_id + ".pdf"
      );
    },
    filterDashboardInfos(name) {
      for (let index in this.dashboardInfos) {
        if (this.dashboardInfos[index].name === name) {
          return this.dashboardInfos[index].value;
        }
      }
      return null;
    },
    sendClickToGA(category, action, label) {
      if (this.$ga) {
        this.$ga.event({
          eventCategory: category,
          eventAction: action,
          eventLabel: label
        });
      }
    },
    requestAdditionalAAct() {
      this.showModalPopup("modal_popup_loading", true);
      if (
        this.emailNotif === null ||
        !fieldValidation.validateEmail(this.emailNotif)
      ) {
        this.dashboardError = {
          description: "Email invalid",
          code: ""
        };
        this.showModalPopup("modal_popup_loading", false);

        return;
      }
      httpServiceAffiliate.affiliateService
        .getAddtionalActLink(
          this.affiliate.company_id,
          {
            id: this.affiliate.company_crm_id,
            email: this.emailNotif
          },
          Cookies.get("token")
        )
        .then(response => {
          if (response.status == 200 || response.status == 204) {
            this.uuid = response.data.uuid;

            this.showModalPopup("modal_popup_loading", false);
            this.showModalPopup("modal_popup_notif", true);
          } else {
            this.dashboardError = response.data;

            this.showModalPopup("modal_popup_loading", false);
          }
        })
        .catch(error => {
          if (error.response) {
            this.dashboardError = error.response.data;
          } else {
            this.dashboardError = { description: "undefined" };
          }
          this.showModalPopup("modal_popup_loading", false);
        });
    },
    canSignAdditionalContract() {
      httpServiceAffiliate.affiliateService
        .getCanSignAddtionalAct(
          this.affiliate.company_id,
          Cookies.get("token"),
          this.affiliate.fiscal_code
        )
        .then(response => {
          if (response.status == 200 || response.status == 204) {
            this.okForAdditional = true;
          } else {
            this.okForAdditional = false;
          }
        })
        .catch(error => {
          this.okForAdditional = false;
        });
    }
  },
  mounted() {
    if (!Cookies.get("first")) {
      var now = moment();
      if (now.hour() < 7) {
        this.showModalPopup("modal_popup_error", true);
      }
    }
  },
  created() {
    this.selectProduct(0);
  }
};
</script>
<style scoped src="@/assets/styles/beneficiary.css"></style>
<style scoped>
.allstats-affiliate {
  width: 100%;
  background: #fff;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.chart-wrapper {
  position: relative;
  display: inline-block;
  width: 50%;
}
.w-40 {
  width: 40%;
}
.w-60 {
  width: 60%;
}
/* .offers {
  width: 95%;
  margin: 0 auto;
} */
.offer {
  /* padding: 10px;
  border: 1px solid #ddd;
  border-radius: 10px; */
}
</style>

import { render, staticRenderFns } from "./Offers.vue?vue&type=template&id=f482b198&scoped=true"
import script from "./Offers.vue?vue&type=script&lang=js"
export * from "./Offers.vue?vue&type=script&lang=js"
import style0 from "@/assets/styles/beneficiary.css?vue&type=style&index=0&id=f482b198&prod&scoped=true&lang=css&external"
import style1 from "./Offers.vue?vue&type=style&index=1&id=f482b198&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f482b198",
  null
  
)

export default component.exports
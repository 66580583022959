<template>
  <div>
    <div>
      <h1>{{ $t("searchAffliatePageName") }}</h1>
      <br clear="all" />
      <div class="container-alb">
        <b-container fluid class="border-box">
          <form
            @submit.prevent="
              {
                return false;
              }
            "
          >
            <b-row class="border-box">
              <b-col xl="4" md="4" class="border-box mb-4">
                <span>{{ $t("searchAffliateByCodall") }}:</span>
                <input
                  type="text"
                  class="formedituser"
                  v-model="codall"
                  v-on:keyup.enter="searchAffiliate()"
                  @keyup="updateInputCharacters()"
                />
              </b-col>
              <b-col xl="4" md="4" class="border-box mb-4">
                <span>{{ $t("searchByProduct") }}:</span>
                <select class="w-100" @change="productSelected">
                  <option selected disabled>{{ $t("cardStatus_") }}</option>
                  <option
                    v-for="(entry, index) in this.allProducts"
                    :key="index"
                    :value="entry.paper + ',' + entry.card"
                    :selected="isSelectedProduct(entry.brand)"
                    >{{ entry.name }}</option
                  >
                </select>
              </b-col>
            </b-row>
            <b-row class="border-box">
              <b-col xl="4" md="4" class="border-box mb-4">
                <span>{{ $t("searchAffliateByAffiliateName") }}:</span>
                <input
                  type="text"
                  class="formedituser"
                  v-model="clientName"
                  v-on:keyup.enter="searchAffiliate()"
                  @keyup="updateInputCharacters()"
                />
              </b-col>
              <b-col xl="4" md="4" class="border-box mb-4">
                <span>{{ $t("searchByFiscalCode") }}:</span>
                <input
                  type="text"
                  class="formedituser"
                  v-model="fiscalCode"
                  v-on:keyup.enter="searchAffiliate()"
                  @keyup="updateInputCharacters()"
                />
              </b-col>
              <b-col xl="4" md="4" class="border-box"></b-col>
            </b-row>
            <b-row class="border-box">
              <b-col xl="4" md="4" class="border-box mb-4 mx-auto text-center">
                <a
                  class="notification-action custom-notification-action"
                  @click="searchAffiliate()"
                  >{{ $t("searchButtonLabel") }}</a
                >
              </b-col>
            </b-row>
          </form>
        </b-container>
      </div>
      <div v-if="this.companies" class="container-alb">
        <table
          cellpadding="10"
          cellspacing="0"
          width="95%"
          class="hover-table"
          align="center"
        >
          <thead>
            <tr>
              <td valign="middle">
                <strong>{{ $t("affiliateCodeLabel") }}</strong
                >&nbsp;
              </td>
              <td valign="middle">
                <strong>{{ $t("searchAffliateByAffiliateName") }}</strong
                >&nbsp;
              </td>
              <td class="priority-1" valign="middle">
                <strong>{{ $t("fiscalCodeLabel") }}</strong
                >&nbsp;
              </td>
              <td valign="middle">
                <strong>{{ $t("beneficiariesTableAction") }}</strong
                >&nbsp;
              </td>
            </tr>
          </thead>
          <tbody>
            <tr valign="middle" class="trhover" v-if="companies.length === 0">
              <td colspan="12">{{ $t("searchAffliateNoAffiliates") }}</td>
            </tr>
            <template v-for="(company, index) in companies">
              <tr
                :key="index"
                class="trhover parent"
                :class="index % 2 === 0 && 'gri-cell'"
              >
                <td valign="middle">
                  <a
                    class="detailsOrderMobile priority-show-5 display-inline-block"
                    @click="selectCompany(index)"
                  >
                    <font-awesome-icon
                      icon="minus-circle"
                      v-if="detailsMobile && company === companySelected"
                    />
                    <font-awesome-icon icon="plus-circle" v-else />&nbsp;&nbsp;
                  </a>
                  <strong>{{ company.company_id }}</strong>
                </td>
                <td valign="middle" class="priority-0">{{ company.name }}</td>
                <td valign="middle" class="priority-1">
                  {{ company.fiscal_code }}
                </td>
                <td valign="middle">
                  <a
                    class="see-more cursor-pointer"
                    @click="setCompanyId(company.company_id)"
                  >
                    <font-awesome-icon
                      icon="arrow-alt-circle-right"
                      size="2x"
                    />
                  </a>
                </td>
              </tr>
              <tr
                :key="index + companies.length"
                class="child"
                :class="index % 2 === 0 && 'gri-cell'"
                v-show="detailsMobile && company === companySelected"
              >
                <td colspan="12">
                  <p class="priority-show-0">
                    <strong
                      >{{ $t("searchByCompanyName") }} : &nbsp;&nbsp;</strong
                    >
                    <span>{{ company.name }}</span>
                  </p>
                  <p class="priority-show-1">
                    <strong>{{ $t("fiscalCodeLabel") }} : &nbsp;&nbsp;</strong>
                    <span>{{ company.fiscal_code }}</span>
                  </p>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
      <div v-if="this.searchError" class="container-alb">
        <table
          cellpadding="10"
          cellspacing="0"
          width="95%"
          class="hover-table"
          align="center"
        >
          <thead>
            <tr>
              <td valign="middle">
                <strong v-html="$t('errorMessage_' + this.searchError)" />
              </td>
            </tr>
          </thead>
        </table>
      </div>
    </div>
    <div id="modal_popup_loading" class="modal-popup">
      <div class="modal-popup-content">
        <img src="@/assets/images/loading.gif" />
        <p v-html="$t('loadingPopupMessage')"></p>
      </div>
    </div>
  </div>
</template>

<script>
import httpServiceAffiliate from "@/http/http-affiliate.js";
import Cookies from "js-cookie";
import { BContainer, BRow, BCol } from "bootstrap-vue";
import products from "@/assets/json/products.js";
// import utils from "@/src/plugins/utils.js";

export default {
  props: {
    affiliate: Object,
    msg: String
  },
  components: {
    "b-container": BContainer,
    "b-row": BRow,
    "b-col": BCol
  },
  data() {
    return {
      companies: null,
      detailsMobile: false,
      companySelected: null,
      selectedCompanyId: null,
      codall: null,
      clientCode: null,
      clientName: null,
      fiscalCode: null,
      product: null,
      perPage: 1000,
      currentPage: 1,
      sortDirection: "desc",
      searchError: null
    };
  },
  methods: {
    productSelected(event) {
      this.product = event.target.value;
    },
    selectCompany(index) {
      this.companySelected = this.companies[index];
      this.detailsMobile = !this.detailsMobile;
    },
    isSelectedProduct(product) {
      return product === this.product;
    },
    searchAffiliate() {
      if (this.codall || this.clientName || this.fiscalCode) {
        this.showModalPopup("modal_popup_loading", true);

        var formData =
          "?per_page=" + this.perPage + "&page=" + this.currentPage;
        if (this.codall) {
          formData += "&company_id=" + this.codall;
        }
        if (this.clientName) {
          formData += "&name=" + this.clientName;
        }
        if (this.fiscalCode) {
          formData += "&fiscal_code=" + this.fiscalCode;
        }
        if (this.product) {
          formData += "&product=" + this.product;
        }
        localStorage.removeItem("affilite_data");
        httpServiceAffiliate.affiliateService
          .searchAffiliate(formData, Cookies.get("token"))
          .then(function(response) {
            return response;
          })
          .catch(error => {
            this.searchError = error.response.status;
            this.showModalPopup("modal_popup_loading", false);
          })
          .then(response => {
            if (response.status == 200) {
              this.companies = response.data;
            } else if (response.status == 400 || response.status == 403) {
              this.companies = null;
              this.searchError = response.status;
            }
            this.showModalPopup("modal_popup_loading", false);
          });
      }
    },
    setCompanyId(id) {
      Cookies.set(
        "selectedCompany",
        {
          company_id: id,
          role: "AFA"
        },
        {
          sameSite: "Strict"
        }
      );
      localStorage.removeItem("affiliate_data");
      this.pushRoute('affiliate_invoices');
    },
    showModalPopup(elementId, show) {
      if (show) {
        document.getElementById(elementId).style.visibility = "visible";
      } else {
        document.getElementById(elementId).style.visibility = "hidden";
      }
    },
    onlyAlphaNumericCharacters(variable) {
      if (/^[a-zA-Z0-9]*$/i.test(variable)) {
        return variable;
      } else {
        return variable.replace(/[&\/\\|`#@\[\]\-,+=()$~%.'":*!?<>{}]/g, "");
      }
    },
    updateInputCharacters() {
      this.codall = this.onlyAlphaNumericCharacters(this.codall);
      this.clientName = this.onlyAlphaNumericCharacters(this.clientName);
      this.fiscalCode = this.onlyAlphaNumericCharacters(this.fiscalCode);
      this.$forceUpdate();
    }
  },
  computed: {
    allProducts() {
      return products;
    }
  },
  mounted() {
    Cookies.set(
      "selectedCompany",
      {
        company_id: 0,
        role: "AFA"
      },
      {
        sameSite: "Strict"
      }
    );
    localStorage.removeItem("affiliate_data");
  }
};
</script>
<style scoped src="@/assets/styles/beneficiary.css"></style>

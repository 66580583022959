var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('table',{staticClass:"hover-table invoices-table",attrs:{"cellpadding":"10","cellspacing":"0","width":"95%","align":"center"}},[_c('thead',[_c('tr',[_c('td',{attrs:{"valign":"middle"}},[_c('strong',[_vm._v(_vm._s(_vm.$t("reimbursementHeaderID")))]),_vm._v("  ")]),_c('td',{attrs:{"valign":"middle"}},[_c('strong',[_vm._v(_vm._s(_vm.$t("reimbursementHeaderStatus")))]),_vm._v("  ")]),_c('td',{staticClass:"priority-1",attrs:{"valign":"middle"}},[_c('strong',[_vm._v(_vm._s(_vm.$t("reimbursementHeaderDate")))]),_vm._v("  ")]),_c('td',{staticClass:"priority-2",attrs:{"valign":"middle"}},[_c('strong',[_vm._v(_vm._s(_vm.$t("reimbursementHeaderValue")))]),_vm._v("  ")]),_c('td',{attrs:{"valign":"middle"}})])]),_c('tbody',[(
          this.reimbursements === null || this.reimbursements === undefined
        )?_c('tr',[_vm._m(0)]):_vm._e(),_vm._l((_vm.reimbursements),function(reimbursement,index){return [_c('tr',{key:index,staticClass:"trhover parent",class:index % 2 === 0 && 'gri-cell'},[_c('td',{attrs:{"valign":"middle"}},[_c('a',{staticClass:"detailsOrderMobile priority-show-5 display-inline-block",on:{"click":function($event){return _vm.selectReimbursementMobile(index)}}},[(
                  _vm.detailsMobile &&
                    reimbursement === _vm.reimbursementSelectedMobile
                )?_c('font-awesome-icon',{attrs:{"icon":"minus-circle"}}):_c('font-awesome-icon',{attrs:{"icon":"plus-circle"}}),_vm._v("   ")],1),_c('strong',[_vm._v(_vm._s(reimbursement.tally_sheet.tallysheet_id))])]),_c('td',{attrs:{"valign":"middle"}},[_vm._v(" "+_vm._s(_vm.$t( "reimbursementStatus_" + reimbursement.tally_sheet.states[0].state_id ))+" ")]),_c('td',{staticClass:"priority-1",attrs:{"valign":"middle"}},[_vm._v(" "+_vm._s(_vm.displayDate(reimbursement.tally_sheet.creation_date))+" ")]),_c('td',{staticClass:"priority-2",attrs:{"valign":"middle"}},[_vm._v(" "+_vm._s(reimbursement.tally_sheet.total_value)+" "+_vm._s(_vm.$t("currency"))+" ")]),_c('td',{attrs:{"valign":"middle"}},[_c('a',{staticClass:"action-button",on:{"click":function($event){return _vm.selectReimbursementAction(reimbursement, 'details')}}},[_c('span',[_vm._v(_vm._s(_vm.$t("details")))])])])]),_c('tr',{directives:[{name:"show",rawName:"v-show",value:(
            _vm.detailsMobile && reimbursement === _vm.reimbursementSelectedMobile
          ),expression:"\n            detailsMobile && reimbursement === reimbursementSelectedMobile\n          "}],key:index + _vm.reimbursements.length,staticClass:"child",class:index % 2 === 0 && 'gri-cell'},[_c('td',{attrs:{"colspan":"12"}},[_c('p',{staticClass:"priority-show-1"},[_c('strong',[_vm._v(_vm._s(_vm.$t("reimbursementHeaderDate"))+" :   ")]),_c('span',[_vm._v(_vm._s(_vm.displayDate(reimbursement.tally_sheet.creation_date)))])]),_c('p',{staticClass:"priority-show-2"},[_c('strong',[_vm._v(_vm._s(_vm.$t("reimbursementHeaderValue"))+" :   ")]),_c('span',[_vm._v(_vm._s(reimbursement.tally_sheet.total_value)+" "+_vm._s(_vm.$t("currency")))])])])])]})],2)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('td',{attrs:{"colspan":"6"}},[_c('div',{staticClass:"loading"},[_c('img',{attrs:{"src":require("@/assets/images/loading.gif")}})])])
}]

export { render, staticRenderFns }
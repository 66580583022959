export default [
    {
        fileName: "beneficiary/Cultura_oct_2023.png",
        link:
            "https://upromania.ro/companii/card-cultural/",
        category: "Banner",
        action: "Click",
        label: "afiliati_upcultura_vn_210",
    },
  {
    fileName: "client/Creste-Valoarea-nominala-uponline-1018x166.png",
    link:
      "https://upromania.ro/fisiere/ordonanta-de-urgenta-nr-69-2023-pentru-modificarea-art-14-din-legea-nr-165-2018-privind-acordarea-biletelor-de-valoare-precum-si-pentru-stabilirea-unor-masuri-pentru-aplicarea-acestor-prevederi.pdf",
    category: "Banner",
    action: "Click",
    label: "Companii_VN35",
  },
  {
		fileName: "client/banner_gpay.png",
		link: "https://upromania.ro/utilizatori/google-pay/",
    category: "Banner",
    action: "Click",
		label: "Afiliat_GPay",
  },
  {
    fileName: "client/UP_carburant.jpg",
		link:
      "https://upromania.ro/companii/carburant/?utm_source=uponline&utm_medium=banner&utm_campaign=carburant",
    category: "Up Achizitii",
    action: "UpCarburant",
    label: "Client_UpCarburant",
	},
	{
    fileName: "client/UP_medical.jpg",
		link:
      "https://upromania.ro/companii/sanatate/?utm_source=uponline&utm_medium=banner&utm_campaign=sanatate)",
		category: "Up Achizitii",
    action: "UpMedical",
    label: "Client_UpMedical",
  },
  {
    fileName: "client/banner_pay.png",
		link: "https://upromania.ro/utilizatori/up-mobil-pay/",
    category: "Banner",
    action: "Click",
		label: "Afiliat_UpMobilPay",
  },
	{
    fileName: "client/UP_semnatura.jpg",
		link:
      "https://upromania.ro/companii/semnatura/?utm_source=uponline&utm_medium=banner&utm_campaign=semnatura+electronica",
    category: "Up Achizitii",
    action: "UpSemanaturaElectrica",
    label: "Client_UpSemanaturaElectrica",
  },
];

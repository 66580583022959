<template>
  <div>
    <div class="account-title">
      <h1>{{ $t("companyManagementTitle") }}</h1>
      <p>{{ $t("companyManagementDescription") }}</p>
    </div>
    <div
      v-if="
        this.companyDetails.user_config &&
        !this.companyDetails.state_owned &&
        this.companyDetails.user_config.roles &&
        this.companyDetails.user_config.roles.includes('EDITOR')
      "
      class="content-delivery mb-5"
    >
      <div class="second-tabs">
        <b-container fluid class="border-box">
          <b-row class="border-box">
            <b-col class="border-box" xl="12" md="12">
              <h3 class="title-address-table">
                <strong>{{ $t("inviteUserTitleSection") }}</strong>
              </h3>
              <form
                class="
                  add-user
                  d-flex
                  flex-column flex-md-row
                  align-items-center
                "
                @submit.prevent="
                  {
                    return false;
                  }
                "
              >
                <p>
                  <small>{{ $t("emailField") }}</small>
                  <br />
                  <input
                    type="text"
                    v-model="newUser.email"
                    class="formedituser email"
                  />
                </p>
                <p>
                  <small>{{ $t("roleLabel") }}</small>
                  <br />
                  <select class="w-100" v-model="newUser.role">
                    <option
                      v-for="(role, index) in roles"
                      :key="index"
                      :value="role"
                    >
                      {{ $t("userModeLabel_" + role) }}
                    </option>
                  </select>
                </p>
                <p>
                  <small>{{ $t("rightsLabel") }}</small>
                  <br />
                  <span>{{ $t("userClientRightsLabel_" + newUser.role) }}</span>
                </p>
                <p>
                  <a
                    class="button-orange m-0 min-width-100"
                    @click="addNewUserForManageCompany()"
                    >{{ $t("addNewUserLabel") }}</a
                  >
                </p>
              </form>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </div>
    <div class="content-delivery">
      <div class="second-tabs">
        <b-container fluid class="border-box">
          <b-row class="border-box">
            <b-col class="border-box" xl="12" md="12">
              <h3 class="title-address-table">
                <strong>{{ $t("manageUsersTitleSection") }}</strong>
              </h3>
              <table
                cellpadding="10"
                cellspacing="0"
                width="100%"
                class="hover-table invoices-table"
                align="center"
              >
                <thead>
                  <tr>
                    <td valign="middle">
                      <strong>{{ $t("lastNameField") }}</strong>
                    </td>
                    <td valign="middle">
                      <strong>{{ $t("emailField") }}</strong>
                    </td>
                    <td valign="middle">
                      <strong>{{ $t("roleLabel") }}</strong>
                    </td>
                    <td class="priority-3" valign="middle">
                      <strong>{{ $t("rightsLabel") }}</strong>
                    </td>
                    <td>
                      <strong>{{ $t("beneficiariesTableAction") }}</strong>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-if="
                      this.usersForManageCompany === null ||
                      this.usersForManageCompany === undefined
                    "
                  >
                    <td colspan="6">
                      <div class="loading">
                        <img src="@/assets/images/loading.gif" />
                      </div>
                    </td>
                  </tr>
                  <template v-for="(user, index) in usersForManageCompany">
                    <tr
                      :key="index"
                      class="trhover parent"
                      :class="index % 2 === 0 && 'gri-cell'"
                    >
                      <td valign="middle">
                        <a
                          class="
                            detailsOrderMobile
                            priority-show-3
                            display-inline-block
                          "
                          @click="selectUserMobile(index)"
                        >
                          <font-awesome-icon
                            icon="minus-circle"
                            v-if="detailsMobile && user === userSelectedMobile"
                          />
                          <font-awesome-icon
                            icon="plus-circle"
                            v-else
                          />&nbsp;&nbsp;
                        </a>
                        <strong v-if="user.first_name === user.last_name">{{
                          user.first_name
                        }}</strong>
                        <strong v-else>{{
                          user.first_name + " " + user.last_name
                        }}</strong>
                      </td>
                      <td valign="middle">
                        {{ user.email }}
                      </td>
                      <td valign="middle">
                        <div
                          v-if="
                            user.companies[0].user_config &&
                            user.companies[0].user_config.roles
                          "
                        >
                          <select
                            v-if="user.username === user.email"
                            class="input-add-beneficiary"
                            v-model="user.companies[0].user_config.roles[0]"
                            @change="
                              switchModifyOrDeleteModalPopup('MODIFY', user)
                            "
                          >
                            <option
                              v-for="(role, index) in roles"
                              :key="index"
                              :value="role"
                            >
                              {{ $t("userModeLabel_" + role) }}
                            </option>
                          </select>
                          <span v-else
                            ><span>{{
                              $t(
                                "userModeLabel_" +
                                  user.companies[0].user_config.roles[0]
                              )
                            }}</span></span
                          >
                        </div>
                      </td>
                      <td valign="middle" class="priority-3">
                        <span
                          v-if="
                            user.companies[0].user_config &&
                            user.companies[0].user_config.roles
                          "
                          >{{
                            $t(
                              "userClientRightsLabel_" +
                                user.companies[0].user_config.roles[0]
                            )
                          }}</span
                        >
                      </td>
                      <td valign="middle">
                        <a
                          v-if="user.username === user.email"
                          class="action-button mb-1"
                          @click="
                            switchModifyOrDeleteModalPopup('DELETE', user)
                          "
                        >
                          <span>{{ $t("deletePOSLabel") }}</span>
                        </a>
                      </td>
                    </tr>
                    <tr
                      :key="index + usersForManageCompany.length"
                      class="child"
                      :class="index % 2 === 0 && 'gri-cell'"
                      v-show="detailsMobile && user === userSelectedMobile"
                    >
                      <td colspan="12">
                        <p class="priority-show-3">
                          <strong
                            >{{ $t("rightsLabel") }} : &nbsp;&nbsp;</strong
                          >
                          <span
                            v-if="
                              user.companies[0].user_config &&
                              user.companies[0].user_config.roles
                            "
                            >{{
                              $t(
                                "userClientRightsLabel_" +
                                  user.companies[0].user_config.roles[0]
                              )
                            }}</span
                          >
                        </p>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </div>
    <div id="modal_popup_loading" class="modal-popup">
      <div class="modal-popup-content">
        <img src="@/assets/images/loading.gif" />
        <p v-html="$t('loadingPopupMessage')">
          {{ $t("loadingPopupMessage") }}
        </p>
      </div>
    </div>
    <div id="modal_popup_confirm" class="modal-popup">
      <div class="modal-popup-content">
        <a
          @click="showModalPopup('modal_popup_confirm', false)"
          class="close-button"
        >
          <img src="../../assets/images/close-button.svg" />
        </a>
        <p v-if="switchModifyOrDelete === 'MODIFY'">
          {{ $t("confirmModifyUserMessage") }}
        </p>
        <p v-else>{{ $t("confirmDeleteUserMessage") }}</p>
        <div class="buttons">
          <a
            v-if="switchModifyOrDelete === 'MODIFY'"
            href="#"
            class="yesButton"
            @click="modifyUserForManageCompany()"
          >
            {{ $t("notificationsChooseOptionYes") }}
          </a>
          <a
            v-else
            href="#"
            class="yesButton"
            @click="deleteUsersForManageCompany()"
          >
            {{ $t("notificationsChooseOptionYes") }}
          </a>
          <a href="#" class="noButton" @click="noChangeRole()">
            {{ $t("notificationsChooseOptionNo") }}
          </a>
        </div>
      </div>
    </div>
    <div id="modal_popup_info" class="modal-popup">
      <div class="modal-popup-content">
        <a
          @click="showModalPopup('modal_popup_info', false)"
          class="close-button"
        >
          <img src="@/assets/images/close-button.svg" />
        </a>
        <div v-if="this.userManageError">
          <p
            v-html="$t('userManageError_' + this.userManageError.description)"
          ></p>
        </div>
        <div v-else-if="addNewUserSuccess">
          <p>{{ $t("addUserInviteSuccessMessage") }}</p>
        </div>
        <div v-else-if="modifyUserSuccess">
          <p>{{ $t("modifyUserSuccessMessage") }}</p>
        </div>
        <div v-else-if="deleteUserSuccess">
          <p>{{ $t("deleteUserSuccessMessage") }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import httpServiceUser from "@/http/http-user.js";
import Cookies from "js-cookie";
import { BContainer, BRow, BCol } from "bootstrap-vue";

export default {
  components: {
    "b-container": BContainer,
    "b-row": BRow,
    "b-col": BCol,
  },
  props: {
    companyDetails: Object,
    user: Object,
    userType: String,
  },
  data() {
    return {
      companyDataError: null,
      editMode: false,
      formData: null,
      usersForManageCompany: null,
      roles: ["USER"], //"MASTER" , "EDITOR"
      detailsMobile: false,
      userSelectedMobile: null,
      selectedUserForAction: null,
      beforeSelectedUserForChangeRole: null,
      selectedRole: null,
      newUser: {
        email: "",
        role: "USER",
      },
      userManageError: null,
      addNewUserSuccess: false,
      modifyUserSuccess: false,
      deleteUserSuccess: false,
      switchModifyOrDelete: "MODIFY", // false = Modify | true = Delete
    };
  },
  methods: {
    selectUserMobile(index) {
      this.userSelectedMobile = this.usersForManageCompany[index];
      this.detailsMobile = !this.detailsMobile;
    },
    noChangeRole() {
      this.getUsersForManageCompany();
      this.showModalPopup("modal_popup_confirm", false);
    },
    beforeUserChangeRole(user) {
      this.beforeSelectedUserForChangeRole = user;
      // this.$forceUpdate();
    },
    showModalPopup(elementId, show) {
      if (show) {
        document.getElementById(elementId).style.visibility = "visible";
      } else {
        document.getElementById(elementId).style.visibility = "hidden";
      }
    },
    onlyAlphabetCharacters(variable) {
      if (/^[a-zA-Z]*$/i.test(variable)) {
        return variable;
      } else {
        return variable.replace(/[0-9&\/\\|`#@\[\]\-,+=()$~%.'":*!?<>{}]/g, "");
      }
    },
    onlyNumericCharacters(variable) {
      if (/^[0-9]*$/i.test(variable)) {
        return variable;
      } else {
        return variable.replace(
          /[a-zA-Z&\/\\|`#@\[\]\-,+=()$~%.'":*!?<>{}]/g,
          ""
        );
      }
      return variable;
    },
    onlyAlphaNumericCharacters(variable) {
      if (/^[a-zA-Z0-9@.]*$/i.test(variable)) {
        return variable;
      } else {
        return variable.replace(/[&\/\\|`#\[\]\-,+=()$~%'":*!?<>{}]/g, "");
      }
    },
    updateInputCharacters() {
      this.formData.lastName = this.onlyAlphabetCharacters(
        this.formData.lastName
      );
      this.formData.firstName = this.onlyAlphabetCharacters(
        this.formData.firstName
      );
      this.formData.mobilePhone = this.onlyNumericCharacters(
        this.formData.mobilePhone
      );
      this.formData.email = this.onlyAlphaNumericCharacters(
        this.formData.email
      );
      this.$forceUpdate();
    },
    addNewUserForManageCompany() {
      if (this.newUser.email === "") {
        this.userManageError = {
          description: "EMAIL_EMPTY",
        };
        this.showModalPopup("modal_popup_info", true);
        return;
      }
      this.addNewUserSuccess = false;
      this.modifyUserSuccess = false;
      this.deleteUserSuccess = false;
      this.userManageError = null;
      this.showModalPopup("modal_popup_loading", true);

      httpServiceUser.userService
        .addNewUserForManageCompany(
          this.companyDetails.company_id,
          {
            email: this.newUser.email,
            flow: "add_user_company",
            company_type: this.userType,
            roles: [this.newUser.role],
          },
          Cookies.get("token")
        )
        .then((response) => {
          if (response.status == 200) {
            this.addNewUserSuccess = true;
            this.getUsersForManageCompany();
            this.newUser.email = "";
            this.showModalPopup("modal_popup_loading", false);
            this.showModalPopup("modal_popup_info", true);
          } else {
            this.userManageError = { description: response.status };
            this.showModalPopup("modal_popup_loading", false);
            this.showModalPopup("modal_popup_info", true);
          }
        })
        .catch((error) => {
          if (error.response) {
            this.userManageError = { description: error.response.status };
          } else {
            this.userManageError = { description: "undefined" };
          }
          this.showModalPopup("modal_popup_loading", false);
          this.showModalPopup("modal_popup_info", true);
        });
    },
    getUsersForManageCompany() {
      this.userManageError = null;

      httpServiceUser.userService
        .getUsersForManageCompany(
          this.companyDetails.company_id,
          Cookies.get("token")
        )
        .then((response) => {
          if (response.status == 200) {
            this.usersForManageCompany = response.data;
          } else {
            this.userManageError = { description: response.status };
            this.showModalPopup("modal_popup_info", true);
          }
        })
        .catch((error) => {
          if (error.response) {
            this.userManageError = { description: error.response.status };
          } else {
            this.userManageError = { description: "undefined" };
          }
          this.showModalPopup("modal_popup_info", true);
        });
    },
    modifyUserForManageCompany() {
      this.addNewUserSuccess = false;
      this.modifyUserSuccess = false;
      this.deleteUserSuccess = false;
      this.userManageError = null;
      this.showModalPopup("modal_popup_confirm", false);
      this.showModalPopup("modal_popup_loading", true);

      httpServiceUser.userService
        .modifyUserForManageCompany(
          this.companyDetails.company_id,
          this.selectedUserForAction.id,
          this.selectedUserForAction,
          Cookies.get("token")
        )
        .then((response) => {
          if (response.status == 200 || response.status == 204) {
            this.modifyUserSuccess = true;
            this.getUsersForManageCompany();
            this.showModalPopup("modal_popup_loading", false);
            this.showModalPopup("modal_popup_info", true);
          } else {
            this.userManageError = { description: response.status };
            this.getUsersForManageCompany();
            this.showModalPopup("modal_popup_loading", false);
            this.showModalPopup("modal_popup_info", true);
          }
        })
        .catch((error) => {
          if (error.response) {
            this.userManageError = { description: error.response.status };
          } else {
            this.userManageError = { description: "undefined" };
          }
          this.getUsersForManageCompany();
          this.showModalPopup("modal_popup_loading", false);
          this.showModalPopup("modal_popup_info", true);
        });
    },
    deleteUsersForManageCompany() {
      this.addNewUserSuccess = false;
      this.modifyUserSuccess = false;
      this.deleteUserSuccess = false;
      this.userManageError = null;
      this.showModalPopup("modal_popup_confirm", false);
      this.showModalPopup("modal_popup_loading", true);

      httpServiceUser.userService
        .deleteUsersForManageCompany(
          this.companyDetails.company_id,
          this.selectedUserForAction.id,
          Cookies.get("token")
        )
        .then((response) => {
          if (response.status == 200 || response.status == 204) {
            this.deleteUserSuccess = true;
            this.getUsersForManageCompany();
            this.showModalPopup("modal_popup_loading", false);
            this.showModalPopup("modal_popup_info", true);
          } else {
            this.userManageError = { description: response.status };
            this.getUsersForManageCompany();
            this.showModalPopup("modal_popup_loading", false);
            this.showModalPopup("modal_popup_info", true);
          }
        })
        .catch((error) => {
          if (error.response) {
            this.userManageError = { description: error.response.status };
          } else {
            this.userManageError = { description: "undefined" };
          }
          this.getUsersForManageCompany();
          this.showModalPopup("modal_popup_loading", false);
          this.showModalPopup("modal_popup_info", true);
        });
    },
    switchModifyOrDeleteModalPopup(actionType, user, event = null) {
      this.switchModifyOrDelete = actionType;
      this.selectedUserForAction = user;
      this.selectedRole = event;
      this.showModalPopup("modal_popup_confirm", true);
    },
    getFlows() {
      // this.userManageError = null;

      httpServiceUser.userService
        .getFlows(this.companyDetails.company_id, Cookies.get("token"))
        .then((response) => {
          if (response.status == 200) {
          } else {
            // this.userManageError = {description: response.status};
            // this.showModalPopup("modal_popup_info", true);
          }
        })
        .catch((error) => {
          if (error.response) {
            // this.userManageError = {description: error.response.status};
          } else {
            // this.userManageError = {description: "undefined"};
          }
          // this.showModalPopup("modal_popup_info", true);
        });
    },
  },
  mounted() {
    this.getUsersForManageCompany();
  },
  computed: {},
  created() {},
};
</script>
<style scoped src="@/assets/styles/beneficiary.css"></style>
<style scoped>
.resetButton:not([href]):not([tabindex]) {
  text-decoration: underline;
  color: #585858;
  font-style: italic;
  cursor: pointer;
}
select,
select.input-add-beneficiary {
  min-width: 80px;
}
.container-alb p {
  margin: 0 0 1rem;
}
.add-user {
  font-size: 13px;
}
.add-user > p {
  flex: 1 1 auto;
  margin: 10px;
}
.formedituser.email {
  min-width: 200px;
}
.min-width-100 {
  min-width: 100px;
}
@media (max-width: 767px) {
  .add-user > p {
    width: 100%;
  }
}
</style>

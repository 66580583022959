/* eslint-disable vue/no-side-effects-in-computed-properties */ /*
eslint-disable vue/no-side-effects-in-computed-properties */
<template>
  <div>
    <!-- Tallysheet -->
    <br />
    <div class="d-inline-block w-100">
      <div class="left">
        <h2 class="section-title">{{ $t("tallySheetTitleSection") }}</h2>
      </div>
      <div
        v-if="hasCostCenter"
        class="right position-relative"
        v-click-outside="hide"
      >
        <!-- <a
          v-if="this.costCenterAddress.length === 1"
          class="new-order-button p-0"
          @click="sendReimbursement('PAPER')"
        >
          <span v-if="this.costCenterAddress.length === 0">
            <img class="new-loading" src="@/assets/images/loading2.gif" />
            {{ $t("tallySheetPaperLoadingLabel") }}
          </span>
          <span v-else>
            <img
              class="download d-inline-block"
              src="@/assets/images/download-alb.svg"
            />
            {{ $t("tallySheetPaperButton") }}
          </span>
        </a> -->
        <a class="new-order-button p-0" @click="openPaperReimbursement">
          <span v-if="this.costCenterAddress.length === 0">
            <img class="new-loading" src="@/assets/images/loading2.gif" />
            {{ $t("tallySheetPaperLoadingLabel") }}
          </span>
          <span v-else>
            <img
              class="download d-inline-block"
              src="@/assets/images/download-alb.svg"
            />
            {{ $t("tallySheetPaperButton") }}
          </span>
        </a>
        <div class="chooseAddress" v-show="isOpen">
          <form
            @submit.prevent="
              {
                return false;
              }
            "
          >
            <strong>{{ $t("noOfTallySheetLabel") }}</strong>
            <input
              type="number"
              v-model="noOfTallySheet"
              class="input-add-beneficiary background-transparent text-right"
              :placeholder="$t('noOfTallySheetLabel')"
            />
            <strong>{{ $t("selectCostCenterLabel") }}</strong>
            <multiselect
              v-model="selectedCostCenterAddress"
              :options="costCenterAddress"
              :placeholder="$t('selectCostCenterLabel')"
              select-label=""
              selected-label=""
              deselect-label=""
              openDirection="bottom"
              track-by="codcom"
              :custom-label="customLabel"
              :loading="isLoadingCC"
              @input="sendReimbursement('PAPER')"
            ></multiselect>
          </form>
        </div>
      </div>
      <div v-else class="right">
        <a @click="sendReimbursement('PAPER')" class="new-order-button p-0">
          <span v-if="this.costCenterAddress.length === 0">
            <img class="new-loading" src="@/assets/images/loading2.gif" />
            {{ $t("tallySheetPaperLoadingLabel") }}
          </span>
          <span v-else>
            <img
              class="download d-inline-block"
              src="@/assets/images/download-alb.svg"
            />
            {{ $t("tallySheetPaperButton") }}
          </span>
        </a>
      </div>
    </div>
    <div class="container-alb">
      <div class="section-table">
        <table cellpadding="10" cellspacing="0" width="95%" align="center">
          <tr>
            <td valign="middle">
              <h6>
                <strong>{{ $t("tallySheetElectronicTitleSection") }}</strong>
              </h6>
            </td>
          </tr>
        </table>

        <br />
        <table
          cellpadding="10"
          cellspacing="0"
          width="95%"
          class="hover-table"
          align="center"
        >
          <tr>
            <td valign="middle">
              <strong>{{ $t("ticketTypeLabel") }}</strong>
            </td>
            <td valign="middle">
              <strong>{{ $t("beneficiariesTableNoOfTickets") }}</strong>
            </td>
            <td class="priority-4" valign="middle">
              <strong>{{ $t("beneficiariesTableTicketValue") }}</strong>
            </td>
            <td class="priority-5" valingn="middle">
              <strong>{{ $t("beneficiariesTableTotalValue") }}</strong>
            </td>
            <td valingn="middle"></td>
          </tr>
          <template v-for="(entry, index) in this.ticketsRepayment">
            <tr
              :key="index"
              class="trhover parent"
              :class="index % 2 === 0 && 'gri-cell'"
            >
              <td valign="middle">
                <a
                  @click="selectRepaymentMobile(index)"
                  class="detailsOrderMobile display-inline-block priority-show-5"
                >
                  <font-awesome-icon
                    icon="minus-circle"
                    v-if="detailsMobile && entry === selectedElement"
                  />
                  <font-awesome-icon icon="plus-circle" v-else />&nbsp;&nbsp;
                </a>
                <select class="input-add-beneficiary" v-model="entry.product">
                  <option
                    v-for="(product, index) in paperProducts"
                    :key="index"
                    :value="product"
                    >{{ product.name }}</option
                  >
                </select>
              </td>
              <td valign="middle">
                <input
                  type="text"
                  v-model="entry.tickets"
                  class="input-add-beneficiary background-transparent"
                  :placeholder="$t('noOfTotallyVouchers')"
                  @keyup="updateInputTicket(entry)"
                />
              </td>
              <td class="priority-4" valign="middle">
                <input
                  type="text"
                  v-model="entry.nominal_value"
                  class="input-add-beneficiary background-transparent"
                  :placeholder="$t('amountOfVoucher')"
                  @keyup="convertValueToDecimalVariableForInput(entry)"
                />
              </td>
              <td class="priority-5" valign="middle">
                <input
                  type="text"
                  disabled="disabled"
                  :value="entry.tickets * entry.nominal_value"
                  class="input-add-beneficiary background-transparent"
                />
              </td>
              <td valign="middle">
                <a
                  v-if="entry.newRepayment"
                  @click="deleteRepayment(index)"
                  class="custom-font-awesome culoare-13"
                >
                  <font-awesome-icon icon="minus" />
                </a>
              </td>
            </tr>
            <tr
              :key="ticketsRepayment.length + index"
              class="child"
              :class="index % 2 === 0 && 'gri-cell'"
              v-show="detailsMobile && entry === selectedElement"
            >
              <td colspan="12">
                <p class="priority-show-4">
                  <strong
                    >{{ $t("beneficiariesTableCNP") }} : &nbsp;&nbsp;</strong
                  >
                  <span>
                    <input
                      type="text"
                      v-model="entry.nominal_value"
                      class="input-add-beneficiary background-transparent"
                      placeholder="Introdu valoarea tichetelor"
                      @keyup="convertValueToDecimalVariableForInput(entry)"
                    />
                  </span>
                </p>
                <p class="priority-show-5">
                  <strong
                    >{{ $t("beneficiariesTableNoOfTickets") }}:
                    &nbsp;&nbsp;</strong
                  >
                  <span>
                    <input
                      type="text"
                      disabled="disabled"
                      class="input-add-beneficiary background-transparent"
                      v-model="entry.total_value"
                    />
                  </span>
                </p>
              </td>
            </tr>
          </template>
          <tr class="trhover parent">
            <td valign="middle">
              <a
                @click="openAddRepaymentMobile()"
                class="detailsOrderMobile display-inline-block priority-show-5"
              >
                <font-awesome-icon
                  icon="minus-circle"
                  v-if="openAddRepayment"
                />
                <font-awesome-icon icon="plus-circle" v-else />&nbsp;&nbsp;
              </a>
              <select class="input-add-beneficiary" v-model="productType">
                <option
                  v-for="(product, index) in paperProducts"
                  :key="index"
                  :value="product"
                  >{{ product.name }}</option
                >
              </select>
            </td>
            <td valign="middle">
              <input
                type="text"
                v-model="noOfTickets"
                class="input-add-beneficiary background-transparent"
                :placeholder="$t('noOfTotallyVouchers')"
                @keyup="updateInputTicket()"
              />
            </td>
            <td class="priority-4" valign="middle">
              <input
                type="text"
                v-model="ticketValue"
                class="input-add-beneficiary background-transparent"
                :placeholder="$t('amountOfVoucher')"
                @keyup="convertValueToDecimalVariableForInput()"
              />
            </td>
            <td class="priority-5" valign="middle">
              <input
                type="text"
                disabled="disabled"
                :value="noOfTickets * ticketValue"
                class="input-add-beneficiary background-transparent"
              />
            </td>
            <td>
              <a @click="addRepayment()" class="custom-font-awesome culoare-6">
                <font-awesome-icon icon="plus" />
              </a>
            </td>
          </tr>
          <tr v-show="openAddRepayment">
            <td colspan="12">
              <p class="priority-show-4">
                <strong
                  >{{ $t("beneficiariesTableTicketValue") }}:
                  &nbsp;&nbsp;</strong
                >
                <input
                  type="text"
                  v-model="ticketValue"
                  class="input-add-beneficiary background-transparent"
                  placeholder="Introdu valoarea tichetelor"
                  @keyup="convertValueToDecimalVariableForInput()"
                />
              </p>
              <p class="priority-show-5">
                <strong
                  >{{ $t("beneficiariesTableTotalValue") }}:
                  &nbsp;&nbsp;</strong
                >
                <input
                  type="text"
                  disabled="disabled"
                  :value="noOfTickets * ticketValue"
                  class="input-add-beneficiary background-transparent"
                />
              </p>
            </td>
          </tr>
        </table>

        <div class="tallySheetType">
          <table
            cellpadding="10"
            cellspacing="0"
            width="95%"
            class="hover-table"
            align="center"
          >
            <tr>
              <td valign="middle" style="width: 150px;">
                <strong>{{ $t("ticketTallySheetLabel") }}</strong>
              </td>
              <td valign="middle">
                <b-form-radio-group
                  id="tallySheet-type"
                  v-model="tallySheetType"
                  name="tallySheet-type"
                >
                  <b-form-radio value="false">{{
                    $t("tallySheetTypeNormal")
                  }}</b-form-radio>
                  <b-form-radio v-if="!hasOnlyHolidayProduct" value="true">{{
                    $t("tallySheetTypeExpress")
                  }}</b-form-radio>
                </b-form-radio-group>
              </td>
            </tr>
            <tr>
              <td valign="middle" style="width: 150px;">
                <strong>{{ $t("selectCostCenterLabel") }}</strong>
              </td>
              <td valign="middle">
                <form
                  @submit.prevent="
                    {
                      return false;
                    }
                  "
                >
                  <multiselect
                    v-model="selectedCostCenterAddress"
                    :options="costCenterAddress"
                    :placeholder="$t('selectLabel')"
                    select-label=""
                    selected-label=""
                    deselect-label=""
                    openDirection="bottom"
                    track-by="codcom"
                    :custom-label="customLabel"
                    :loading="isLoadingCC"
                  ></multiselect>
                </form>
              </td>
            </tr>
          </table>
        </div>
      </div>

      <!-- Delivery Method -->
      <div class="subsection section-delivery">
        <h3 class="section-title">{{ $t("deliveryTitleSection") }}</h3>
        <b-form-radio-group
          id="radio-slots"
          class="text-center"
          v-model="selectedDelivery"
          name="radio-options-slots"
        >
          <div
            class="radioButtonCustom"
            :class="selectedDelivery === '0' && 'active'"
          >
            <b-form-radio value="0">{{
              $t("deliveryWithCourier")
            }}</b-form-radio>
          </div>
          <div
            class="radioButtonCustom"
            :class="selectedDelivery === '1' && 'active'"
          >
            <b-form-radio value="1">{{ $t("deliveryPersonal") }}</b-form-radio>
          </div>
        </b-form-radio-group>
      </div>

      <div v-if="selectedDelivery === '0'" class="courier">
        <div v-if="hasOnlyHolidayProduct" class="subsection filter-mobile">
          <div class="pagination-wrapper">
            <span v-html="$t('tallyError_TALLY_NOT_AVAILABLE_HOLIDAY')" />
          </div>
        </div>
        <div v-else>
          <div
            v-if="!this.affiliate.courier_available"
            class="subsection filter-mobile"
          >
            <div class="pagination-wrapper">
              <p>
                <span v-html="$t('reimbursementNoCourierAvailable')" />
              </p>
              <br />
              <p>
                <a
                  class="button-orange m-0"
                  @click="downloadAffiliateDocument()"
                  >{{ $t("invoiceDownloadAgreementForm") }}</a
                >
              </p>
            </div>
          </div>
          <div v-else>
            <div class="subsection filter-mobile">
              <table
                cellpadding="10"
                cellspacing="0"
                width="95%"
                class="hover-table"
                align="center"
              >
                <tr>
                  <td valign="middle" style="width:40%">
                    <form
                      @submit.prevent="
                        {
                          return false;
                        }
                      "
                    >
                      <strong>{{ $t("pickupPointLabel") }}</strong>
                      <multiselect
                        v-model="selectedWorkingPointAddress"
                        :options="workingPointAddress"
                        :placeholder="$t('selectLabel')"
                        select-label=""
                        selected-label=""
                        deselect-label=""
                        openDirection="bottom"
                        track-by="codcom"
                        :custom-label="customLabel"
                        :loading="isLoadingAP"
                        @input="getAddressSelected()"
                      ></multiselect>
                    </form>
                  </td>
                  <td valign="middle">
                    <br />
                    <b-form-checkbox
                      id="checkbox1"
                      v-model="selectedOtherAddress"
                      name="otherAddress"
                      @change="resetAddress()"
                      >{{ $t("selectOtherAddress") }}</b-form-checkbox
                    >
                  </td>
                  <td valign="middle">
                    <br />
                    <b-form-checkbox
                      id="checkbox2"
                      v-model="insurance"
                      name="insurance"
                      >{{ $t("insurancePackageLabel") }}</b-form-checkbox
                    >
                  </td>
                </tr>
              </table>
            </div>

            <div v-if="showAddressDetails" class="delivery-address-details">
              <div class="grey-info">
                <b-container fluid class="border-box">
                  <b-row class="border-box">
                    <b-col xl="12" md="12" class="border-box">
                      <b-row class="border-box">
                        <b-col class="border-box" xl="10" md="10">
                          <p class="title-address-table">
                            <strong>{{
                              $t("deliveryPointAddressTitle")
                            }}</strong>
                          </p>
                          <p>
                            <small>{{
                              $t("deliveryPointAddressStreet")
                            }}</small>
                            <br />
                            <input
                              type="text"
                              v-model="deliveryPoint.address.street_name"
                              class="formedituser"
                            />
                          </p>
                        </b-col>
                        <b-col class="border-box" xl="2" md="2">
                          <p class="title-address-table mobile-hidden">
                            <strong>
                              <pre style="font-size: 100%"></pre>
                            </strong>
                          </p>
                          <p>
                            <small>{{
                              $t("deliveryPointAddressStreetNumber")
                            }}</small>
                            <br />
                            <input
                              type="text"
                              v-model="deliveryPoint.address.street_nr"
                              class="formedituser"
                            />
                          </p>
                        </b-col>
                        <b-col class="border-box" xl="4" md="4">
                          <p>
                            <small>{{ $t("deliveryPointAddressCity") }}</small>
                            <br />
                            <input
                              type="text"
                              v-model="deliveryPoint.address.locality"
                              class="formedituser"
                            />
                          </p>
                        </b-col>
                        <b-col class="border-box" xl="4" md="4">
                          <p>
                            <small>{{
                              $t("deliveryPointAddressDistrict")
                            }}</small>
                            <br />
                            <input
                              type="text"
                              v-model="deliveryPoint.address.county"
                              class="formedituser"
                            />
                          </p>
                        </b-col>
                        <b-col class="border-box" xl="4" md="4">
                          <p>
                            <small>
                              {{ $t("deliveryPointAddressPostalCode") }}
                            </small>
                            <br />
                            <input
                              type="text"
                              v-model="deliveryPoint.address.postal_code"
                              class="formedituser"
                            />
                          </p>
                        </b-col>
                      </b-row>
                      <b-row class="border-box">
                        <b-col class="border-box" xl="12" md="12">
                          <p class="title-address-table">
                            <strong>{{
                              $t("deliveryPointContactTitle")
                            }}</strong>
                          </p>
                        </b-col>
                        <b-col class="border-box" xl="6" md="6">
                          <p>
                            <small>{{
                              $t("deliveryPointContactSurname")
                            }}</small>
                            <br />
                            <input
                              type="text"
                              v-model="deliveryPoint.person.first_name"
                              class="formedituser"
                            />
                          </p>
                          <p>
                            <small>{{ $t("deliveryPointContactEmail") }}</small>
                            <br />
                            <input
                              type="text"
                              v-model="deliveryPoint.person.email"
                              class="formedituser"
                            />
                          </p>
                        </b-col>
                        <b-col class="border-box" xl="6" md="6">
                          <p>
                            <small>{{ $t("deliveryPointContactName") }}</small>
                            <br />
                            <input
                              type="text"
                              v-model="deliveryPoint.person.last_name"
                              class="formedituser"
                            />
                          </p>
                          <p>
                            <small>{{
                              $t("deliveryPointContactMobile")
                            }}</small>
                            <br />
                            <input
                              type="text"
                              v-model="deliveryPoint.person.mobile_phone"
                              class="formedituser"
                            />
                          </p>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </b-container>
              </div>
            </div>

            <div class="subsection filter-mobile">
              <table
                cellpadding="10"
                cellspacing="0"
                width="95%"
                class="hover-table"
                align="center"
              >
                <tr>
                  <td valign="middle" style="width:40%">
                    <strong>{{ $t("numberOfPackageLabel") }}</strong>
                    <input
                      type="number"
                      v-model="numberOfPackages"
                      style="width: auto; display: inline-block;"
                    />
                  </td>
                  <td>
                    <strong>{{ $t("pickupHourLabel") }}</strong>
                    <span>{{ $t("pickupHourText1") }}</span>
                    <date-range
                      class="invoices-filter"
                      :noRanges="true"
                      :single="true"
                      :time="this.pickupHour"
                      :minDateOrder="minDate"
                      :maxDateCourier="minDate"
                      :timePicker="true"
                      :timePicker24Hour="true"
                      style="width: auto; display: inline-block;"
                      @close="changeValidDate"
                      :format="'DD-MM-YYYY HH:mm'"
                    />
                    <span>{{ $t("pickupHourText2") }}</span>
                  </td>
                </tr>
              </table>
            </div>
            <div class="subsection filter-mobile">
              <table
                cellpadding="10"
                cellspacing="0"
                width="95%"
                class="hover-table"
                align="center"
              >
                <tr>
                  <td valign="middle" style="width:40%">
                    <h5>{{ $t("deliverySumarCosts") }}</h5>
                    <p>
                      <strong>{{ $t("costCourier") }}</strong>
                      <span>{{ costExpedition }} lei</span>
                    </p>
                    <p>
                      <strong>{{ $t("costInsurace") }}</strong>
                      <span>{{ costExpeditionWithInsurance }} lei</span>
                    </p>
                  </td>
                  <td>
                    <h5>{{ $t("totalDeliveryCost") }}</h5>
                    <h5>{{ getExpeditionPrice }} lei</h5>
                  </td>
                </tr>
              </table>
            </div>
            <div class="subsection filter-mobile">
              <table
                cellpadding="10"
                cellspacing="0"
                width="95%"
                class="hover-table"
                align="center"
              >
                <tr>
                  <td valign="middle" style="width:40%">
                    <strong>{{ $t("reimbursementInfoMessage") }}</strong>
                  </td>
                </tr>
                <tr>
                  <td valign="middle" style="width:40%">
                    <a class="button-orange" @click="sendReimbursement()">{{
                      $t("textButtonForm")
                    }}</a>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div v-if="selectedDelivery === '1'" class="personal">
        <div class="subsection filter-mobile">
          <table
            cellpadding="10"
            cellspacing="0"
            width="95%"
            class="hover-table"
            align="center"
          >
            <tr>
              <td valign="middle" style="width:40%">
                <h5>{{ $t("deliveryToCenter") }}</h5>
                <p>
                  <strong>{{ $t("addressCenterLabel") }}</strong>
                  <span>{{ $t("addressCenterText") }}</span>
                </p>
                <p>
                  <strong>{{ $t("contactCenterLabel") }}</strong>
                  <span>{{ $t("contactCenterText") }}</span>
                </p>
                <p>
                  <strong>{{ $t("receptionLabel") }}</strong>
                  <span>{{ $t("receptionText") }}</span>
                </p>
              </td>
            </tr>
          </table>
        </div>
        <div class="subsection filter-mobile">
          <table
            cellpadding="10"
            cellspacing="0"
            width="95%"
            class="hover-table"
            align="center"
          >
            <tr>
              <td valign="middle" style="width:40%">
                <a class="button-orange" @click="sendReimbursement()">{{
                  $t("textButtonForm")
                }}</a>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>

    <!-- Repayment -->
    <br />
    <h2 class="section-title">{{ $t("repaymentTitleSection") }}</h2>
    <!-- filter repayment -->
    <div class="container-alb filter-mobile">
      <table
        cellpadding="10"
        cellspacing="0"
        width="95%"
        class="hover-table"
        align="center"
      >
        <tr>
          <td>
            <img src="@/assets/images/filter.svg" />
            <span class="mobile-show margin-left-5">{{
              $t("invoicesFilterTitle")
            }}</span>
          </td>
          <td>
            {{ $t("invoicesFilterStatus") }}
            <select class="filtrare" @change="reimbursementStatusSelected">
              <option value="A" selected>{{ $t("selectLabel") }}</option>
              <option
                v-for="(entry, index) in reimbursementStatuses"
                :key="index"
                :value="entry.value"
                :selected="isSelectedReimbursementStatus(entry.value)"
                >{{ $t("reimbursementStatus_" + entry.value[0]) }}</option
              >
            </select>
          </td>
          <td>
            {{ $t("invoicesFilterPeriod") }}
            <date-range
              class="invoices-filter"
              @close="periodFilterChanged"
              :time="this.filters.period"
            />
          </td>
        </tr>
      </table>
    </div>

    <!-- repayment history -->
    <div class="container-alb">
      <ReimbursementsTable
        :reimbursements="this.affiliateReimbursements"
        @change="sortBy(sortField, sortDirection)"
        @click="selectReimbursement"
      />

      <div class="pagination-wrapper border-box">
        <b-pagination
          v-if="!this.affiliateReimbursements"
          v-model="currentPage"
          :total-rows="this.reimbursementsCount"
          :per-page="perPage"
          size="sm"
        ></b-pagination>
      </div>

      <div
        class="error-wrapper border-box"
        v-if="
          this.affiliateReimbursements &&
            this.affiliateReimbursements.length === 0
        "
      >
        <p>{{ $t("noReimbursementsMessage") }}</p>
      </div>
    </div>

    <div id="modal_popup_loading" class="modal-popup">
      <div class="modal-popup-content">
        <img src="@/assets/images/loading.gif" />
        <p v-html="$t('loadingPopupMessage')">
          {{ $t("loadingPopupMessage") }}
        </p>
      </div>
    </div>

    <div id="modal_popup_info" class="modal-popup">
      <div class="modal-popup-content">
        <a
          @click="showModalPopup('modal_popup_info', false)"
          class="close-button"
        >
          <img src="@/assets/images/close-button.svg" />
        </a>
        <b v-if="succesSentReimbursement">
          <p>{{ $t("reimbursementSentSucces") }}</p>
          <div>
            <a
              v-if="reimbursementDetails.tally_sheet.content"
              href="#"
              @click="downloadReimbursement()"
            >
              <img
                class="download d-inline-block"
                src="@/assets/images/download.svg"
              />
              {{ $t("reimbursementDownloadLabel") }}
            </a>
          </div>
          <div v-if="reimbursementDetails.awb">
            <a
              v-if="reimbursementDetails.awb.content"
              href="#"
              @click="downloadReimbursement(true)"
            >
              <img
                class="download d-inline-block"
                src="@/assets/images/download.svg"
              />
              {{ $t("reimbursementDownloadAWBLabel") }}
            </a>
            <span v-else>{{ $t("tallyError_TALLY_AWB_ERROR") }}</span>
          </div>
        </b>
        <b v-else>
          <p
            v-if="this.error"
            v-html="$t('errorAddRepayment_' + this.error.code)"
          ></p>
          <p
            v-if="this.sendReimbursementError"
            v-html="$t('tallyError_' + this.sendReimbursementError.code)"
          ></p>
        </b>
      </div>
    </div>

    <div id="modal_popup_detailsReimbursements" class="modal-popup">
      <div class="modal-popup-content">
        <a
          @click="showModalPopup('modal_popup_detailsReimbursements', false)"
          class="close-button"
        >
          <img src="@/assets/images/close-button.svg" />
        </a>
        <div v-if="reimbursementDetails" class="text-left scroll-popup">
          <h6>
            {{
              $t("reimbursementNumber") +
                " " +
                reimbursementDetails.tally_sheet.tallysheet_id
            }}
          </h6>
          <br />
          <table
            cellpadding="10"
            cellspacing="0"
            width="95%"
            align="center"
            style="font-size: 12px"
          >
            <tr class="trhover">
              <td valign="middle">
                <strong>{{ $t("reimbursementHeaderID") }}: </strong>
              </td>
              <td valign="middle">
                <span>{{
                  reimbursementDetails.tally_sheet.tallysheet_id
                }}</span>
              </td>
            </tr>
            <tr class="trhover gri-cell">
              <td valign="middle">
                <strong>{{ $t("reimbursementHeaderDate") }}: </strong>
              </td>
              <td valign="middle">
                <span>{{
                  displayDate(reimbursementDetails.tally_sheet.creation_date)
                }}</span>
              </td>
            </tr>
            <tr class="trhover">
              <td valign="middle">
                <strong>{{ $t("reimbursementHeaderValueDeclared") }}: </strong>
              </td>
              <td valign="middle">
                <span>{{ reimbursementDetails.tally_sheet.total_value }}</span>
              </td>
            </tr>
            <tr class="trhover gri-cell">
              <td valign="middle">
                <strong>{{ $t("reimbursementHeaderValueRead") }}: </strong>
              </td>
              <td valign="middle">
                <span>{{ reimbursementDetails.tally_sheet.read_value }}</span>
              </td>
            </tr>
          </table>

          <div class="prog-bar" v-if="reimbursementDetails.tally_sheet.states">
            <ul class="step-pog-bar">
              <li
                v-for="(step, index) in reimbursementDetails.tally_sheet.states"
                :key="index"
                class="reimbursements active"
              >
                {{ $t("reimbursementStatus_" + step.state_id) }}
                <br />
                {{ displayDateAndTime(step.state_date) }}
              </li>
            </ul>
          </div>
          <table
            cellpadding="10"
            cellspacing="0"
            width="95%"
            align="center"
            class="hover-table"
            style="font-size: 12px"
          >
            <template
              v-for="(detail, index) in reimbursementDetails.tally_sheet
                .products"
            >
              <tr
                :key="index"
                class="trhover parent"
                :class="index % 2 === 0 && 'gri-cell'"
              >
                <td>
                  <a
                    @click="selectTypeTicketMobile(index)"
                    class="detailsOrderMobile display-inline-block"
                  >
                    <font-awesome-icon
                      icon="minus-circle"
                      v-if="detailsMobile && detail === selectedTypeTicket"
                    />
                    <font-awesome-icon icon="plus-circle" v-else />&nbsp;&nbsp;
                  </a>
                  <strong>{{
                    $t("reimbursementTicketTypeLabel", {
                      name: getProductNameMethod(detail.product)
                    })
                  }}</strong>
                </td>
              </tr>
              <tr
                :key="index + reimbursementDetails.tally_sheet.products.length"
                :class="index % 2 === 0 && 'gri-cell'"
                v-show="detailsMobile && detail === selectedTypeTicket"
              >
                <td colspan="12">
                  <table
                    cellpadding="2"
                    cellspacing="0"
                    width="95%"
                    align="center"
                  >
                    <tr>
                      <td>
                        <strong
                          >{{ $t("reimbursementTicketTypeInvoiceLabel") }}:
                        </strong>
                      </td>
                      <td>
                        <span>{{
                          detail.invoice_series + detail.invoice_number
                        }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong
                          >{{ $t("reimbursementTicketTypeInvoiceValueLabel") }}:
                        </strong>
                      </td>
                      <td>
                        <span>{{ detail.invoice_value }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong
                          >{{ $t("reimbursementTicketTypeValueLabel") }}:
                        </strong>
                      </td>
                      <td>
                        <span>{{ detail.paid_value }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong
                          >{{ $t("reimbursementTicketTypePaymentDateLabel") }}:
                        </strong>
                      </td>
                      <td>
                        <span>{{ displayDate(detail.paid_date) }}</span>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </template>
          </table>
          <br />
          <br />
          <br />
          <div>
            <a
              v-if="reimbursementDetails.tally_sheet.content"
              href="#"
              @click="downloadReimbursement()"
            >
              <img
                class="download d-inline-block"
                src="@/assets/images/download.svg"
              />
              {{ $t("reimbursementDownloadLabel") }}
            </a>
            <span v-else>{{ $t("reimbursementNoDownloadLabel") }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import utils from "@/plugins/utils.js";
import DateRange from "@/components/DateRange.vue";
import httpServiceUser from "@/http/http-user.js";
import httpServiceAffiliate from "@/http/http-affiliate.js";
import {
  BPagination,
  BFormCheckbox,
  BFormRadio,
  BFormRadioGroup,
  BContainer,
  BRow,
  BCol
} from "bootstrap-vue";
import Cookies from "js-cookie";
import moment from "moment";
import Multiselect from "vue-multiselect";
import ReimbursementsTable from "@/components/company/affiliate/ReimbursementsTable.vue";
import { saveAs } from "file-saver";
import ClickOutside from "vue-click-outside";

export default {
  props: {
    affiliate: Object,
    msg: String
  },
  components: {
    DateRange,
    "b-pagination": BPagination,
    "b-form-checkbox": BFormCheckbox,
    "b-form-radio": BFormRadio,
    "b-form-radio-group": BFormRadioGroup,
    "b-container": BContainer,
    "b-row": BRow,
    "b-col": BCol,
    Multiselect,
    ReimbursementsTable
  },
  directives: {
    ClickOutside
  },
  data() {
    return {
      ticketsRepayment: [],
      productType: null,
      ticketValue: null,
      noOfTickets: null,
      totalValue: null,
      detailsMobile: null,
      openAddRepayment: false,
      error: null,
      tallySheetType: null,
      selectedDelivery: "0",
      insurance: false,
      deliveryPoint: {
        address: {
          street_name: "",
          street_nr: null,
          locality: "",
          county: "",
          postal_code: ""
        },
        person: {
          first_name: "",
          last_name: "",
          email: "",
          mobile_phone: ""
        }
      },
      numberOfPackages: 1,
      pickupHour: null,
      workingPointAddress: [],
      selectedWorkingPointAddress: "",
      costCenterAddress: [],
      selectedCostCenterAddress: "",
      selectedProduct: null,
      otherAddress: null,
      selectedOtherAddress: false,
      showAddressDetails: false,

      reimbursementError: null,
      affiliateReimbursements: null,
      reimbursementDetails: null,
      filters: {
        product: "dejun",
        reimbursementStatus: "A",
        period: [moment().subtract(3, "month"), moment()]
      },
      reimbursementStatuses: [
        { value: [0, 1, 2], description: "Introdus" },
        { value: [304, 716481], description: "Procesat" },
        { value: [378, 381, 716364], description: "Lecturat" },
        { value: [332, 334], description: "Facturat" }
      ],
      reimbursementsCount: 10,
      perPage: 10,
      currentPage: 1,
      sortField: "date",
      sortDirection: "desc",
      reimbursementSelectedMobile: null,
      succesSentReimbursement: false,
      sendReimbursementError: null,
      minDate: moment()
        .add(60, "minutes")
        .toDate(),
      maxDate: moment().toDate(),
      reimbursementStep: 1,
      selectedTypeTicket: null,
      configJSON: JSON.parse(sessionStorage.getItem("client_jsonFile")),
      isOpen: false,
      hasCostCenter: false,
      costExpedition: 12,
      costExpeditionWithInsurance: 0,
      percentageExpeditionInsurance: 0.07 / 100,
      noOfTallySheet: 1,
      isLoadingCC: false,
      isLoadingAP: false
    };
  },
  methods: {
    addRepayment() {
      this.totalValue = this.ticketValue * this.noOfTickets;
      if (this.ticketValue === null) {
        this.error = { code: "NO_VALUE_VOUCHERS" };
        this.showModalPopup("modal_popup_info", true);
      } else if (this.noOfTickets === null) {
        this.error = { code: "NO_DATA_VOUCHERS" };
        this.showModalPopup("modal_popup_info", true);
      } else if (this.totalValue === null) {
        this.error = { code: "NO_TOTAL_VALUE" };
        this.showModalPopup("modal_popup_info", true);
      } else {
        this.ticketsRepayment.push({
          product: this.productType,
          tickets: this.noOfTickets,
          nominal_value: this.ticketValue,
          total_value: this.totalValue,
          newRepayment: true
        });
        this.resetRepayment();
      }
    },
    getPaperProductFromConfig(paperProductid) {
      for (let index in this.configJSON.products) {
        if (this.configJSON.products[index].paper === paperProductid) {
          return this.configJSON.products[index];
        }
      }
      return paperProducts;
    },
    resetRepayment() {
      this.ticketValue = null;
      this.noOfTickets = null;
      this.totalValue = null;
      this.error = null;
    },
    openAddRepaymentMobile() {
      this.openAddRepayment = !this.openAddRepayment;
    },
    deleteRepayment(index) {
      this.ticketsRepayment.splice(index, 1);
    },
    productSelected(event) {
      this.filters.product = event.target.value;
      this.getHistoryReimbursements();
    },
    reimbursementStatusSelected(event) {
      this.filters.reimbursementStatus = event.target.value;
      this.getHistoryReimbursements();
    },
    periodFilterChanged(value) {
      this.filters.period = value;
      this.getHistoryReimbursements();
    },
    isSelectedProduct(product) {
      return product === this.filters.product;
    },
    isSelectedReimbursementStatus() {
      return status === this.filters.reimbursementStatus;
    },
    sortBy(sortField, sortDirection, status) {
      this.sortField = sortField;
      this.sortDirection = sortDirection;
      this.getHistoryReimbursements();
    },
    selectReimbursement(reimbursement, action) {
      if (action === "details") {
        this.getDetailsReimbursement(reimbursement.tally_sheet.tallysheet_id);
      }
    },
    downloadAffiliateDocument() {
      let link = httpServiceAffiliate.affiliateService
        .getDocumentFile(Cookies.get("token"), this.affiliate.company_id, 1) // pentr actul aditional codul este 1
        .then(function(response) {
          return response;
        })
        .then(response => {
          if (response.status == 200) {
            var blob = new Blob(
              [utils.base64ToArrayBuffer(response.data.content)],
              {
                type:
                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheetapplication/vnd.ms-excel"
              }
            );
            saveAs(blob, this.affiliate.company_id + ".pdf");
            this.showModalPopup("modal_popup_loading", false);
            // download content
          } else if (response.status == 400 || response.status == 403) {
            this.reimbursementError = response.data;
            this.showModalPopup("modal_popup_loading", false);
            this.showModalPopup("modal_popup_info", true);
          } else if (response.status == 404) {
            // show file not found
            this.reimbursementError = response.data;
            this.showModalPopup("modal_popup_loading", false);
            this.showModalPopup("modal_popup_info", true);
          } else {
            this.reimbursementError = response.data;
            this.showModalPopup("modal_popup_loading", false);
            this.showModalPopup("modal_popup_info", true);
          }
        })
        .catch(error => {
          this.reimbursementError = error.response.data;
          this.showModalPopup("modal_popup_loading", false);
          this.showModalPopup("modal_popup_info", true);
        });
    },
    getHistoryReimbursements() {
      this.affiliateReimbursements = null;
      var reimbursementsData =
        "?start_date=" +
        moment(this.filters.period[0]).format("YYYYMMDD") +
        "&end_date=" +
        moment(this.filters.period[1]).format("YYYYMMDD");

      if (this.filters.reimbursementStatus !== "A") {
        reimbursementsData += "&state=" + this.filters.reimbursementStatus;
      }

      reimbursementsData +=
        "&per_page=" +
        this.perPage +
        "&page=" +
        this.currentPage +
        "&sort=" +
        this.sortDirection;
      if (this.sortDirection === "desc") {
        reimbursementsData += "&desc=" + this.sortField;
      } else {
        reimbursementsData += "&asc=" + this.sortField;
      }
      httpServiceAffiliate.affiliateService
        .getHistoryReimbursements(
          this.affiliate.company_id,
          reimbursementsData,
          Cookies.get("token")
        )
        .then(function(response) {
          return response;
        })
        .then(response => {
          if (response.status == 200) {
            this.affiliateReimbursements = response.data;
            this.reimbursementsCount = response.headers["x-up-count"];
          } else if (response.status == 400 || response.status == 403) {
            this.reimbursementError = {
              code: response.data,
              description: "Error fetching invoices"
            };
          } else {
            this.reimbursementError = { description: response.description };
          }
        })
        .catch(error => {
          this.reimbursementError = {
            code: error.response.data,
            description: "Error fetching invoices"
          };
        });
    },
    getDetailsReimbursement(reimbursementId) {
      this.showModalPopup("modal_popup_loading", true);

      httpServiceAffiliate.affiliateService
        .getDetailsReimbursement(
          this.affiliate.company_id,
          reimbursementId,
          Cookies.get("token")
        )
        .then(function(response) {
          return response;
        })
        .then(response => {
          if (response.status == 200) {
            this.reimbursementDetails = response.data;
            this.showModalPopup("modal_popup_loading", false);
            this.showModalPopup("modal_popup_detailsReimbursements", true);
          } else if (response.status == 400 || response.status == 403) {
            this.reimbursementError = {
              code: response.data,
              description: "Error fetching invoices"
            };
            this.showModalPopup("modal_popup_loading", false);
          } else {
            this.reimbursementError = { description: response.description };
            this.showModalPopup("modal_popup_loading", false);
          }
        })
        .catch(error => {
          this.reimbursementError = {
            code: error.response.data,
            description: "Error fetching invoices"
          };
          this.showModalPopup("modal_popup_loading", false);
        });
    },
    repeymentForSubmit() {
      let returnReimbursements = [];
      if (this.noOfTickets !== null && this.ticketValue !== null) {
        this.addRepayment();
      }
      for (let index in this.ticketsRepayment) {
        returnReimbursements.push({
          product: this.ticketsRepayment[index].product.paper,
          nominal_value: this.ticketsRepayment[index].nominal_value,
          tickets: this.ticketsRepayment[index].tickets,
          total_value: this.ticketsRepayment[index].total_value
        });
      }
      return returnReimbursements;
    },
    sendReimbursement(type) {
      if (this.costCenterAddress.length === 0) {
        this.sendReimbursementError = {
          code: "NOT_LOADED_CENTER_COST"
        };
        this.showModalPopup("modal_popup_info", true);
        return;
      } else if (!this.selectedCostCenterAddress.codcom) {
        this.sendReimbursementError = {
          code: "NOT_SELECTED_CENTER_COST"
        };
        this.showModalPopup("modal_popup_info", true);
        return;
      }
      this.showModalPopup("modal_popup_loading", true);

      var reimbursementsData;
      if (type === "PAPER") {
        var reimbursementsData = {
          tally_sheet: {
            affiliate_id:
              this.selectedCostCenterAddress === ""
                ? this.affiliate.company_id
                : this.selectedCostCenterAddress.codcom,
            express: false,
            tallysheet_id: this.noOfTallySheet
          },
          tally_type: "PAPER"
        };
      } else {
        var reimbursementsData = {
          tally_sheet: {
            affiliate_id:
              this.selectedCostCenterAddress === ""
                ? this.affiliate.company_id
                : this.selectedCostCenterAddress.codcom,
            express: this.tallySheetType,
            details: this.repeymentForSubmit()
          },

          tally_type: "ONLINE",
          insurance_request: this.insurance,
          no_of_packages: this.numberOfPackages
        };
        if (this.selectedDelivery === "0") {
          reimbursementsData.awb = {
            sender: {
              code: this.affiliate.company_id,
              name: this.affiliate.name,
              fiscal: this.affiliate.fiscal_code
            },
            sender_address: this.deliveryPoint.address,
            sender_person: this.deliveryPoint.person,
            pickup: this.pickupHour[0]
          };
        }
        if (this.ticketsRepayment.length === 0) {
          this.showModalPopup("modal_popup_loading", false);
          return;
        }
      }
      httpServiceAffiliate.affiliateService
        .sendReimbursement(
          this.affiliate.company_id,
          reimbursementsData,
          Cookies.get("token")
        )
        .then(function(response) {
          return response;
        })
        .then(response => {
          if (response.status == 200) {
            this.reimbursementDetails = response.data;
            this.succesSentReimbursement = true;
            this.showModalPopup("modal_popup_loading", false);
            if (type === "PAPER") {
              if (this.reimbursementDetails.tally_sheet.content) {
                this.downloadReimbursement();
              } else {
                this.sendReimbursementError = {
                  code: response.data.error_code,
                  description: "Error fetching invoices"
                };
                this.showModalPopup("modal_popup_info", true);
              }
            } else {
              this.showModalPopup("modal_popup_info", true);
            }
          } else if (response.status == 400 || response.status == 403) {
            this.sendReimbursementError = {
              code: response.data.error_code,
              description: "Error fetching invoices"
            };
            this.showModalPopup("modal_popup_loading", false);
            this.showModalPopup("modal_popup_info", true);
          } else {
            this.sendReimbursementError = { code: "500" };
            this.showModalPopup("modal_popup_loading", false);
            this.showModalPopup("modal_popup_info", true);
          }
        })
        .catch(error => {
          if (error.response.status == 400 || error.response.status == 403) {
            this.sendReimbursementError = {
              code: error.response.data.error_code,
              description: "Error fetching invoices"
            };
          } else {
            this.sendReimbursementError = {
              code: "500"
            };
          }
          this.showModalPopup("modal_popup_loading", false);
          this.showModalPopup("modal_popup_info", true);
        });
    },
    showModalPopup(elementId, show) {
      if (show) {
        document.getElementById(elementId).style.visibility = "visible";
      } else {
        document.getElementById(elementId).style.visibility = "hidden";
      }
    },
    displayDate(date) {
      return moment(new Date(date), "YYYY-MM-DDThh:mm:ss").format("DD/MM/YYYY");
    },
    displayDateAndTime(date) {
      return moment(new Date(date), "YYYY-MM-DDThh:mm:ss").format(
        "DD/MM/YYYY hh:mm:ss"
      );
    },
    selectReimbursementMobile(index) {
      this.reimbursementSelectedMobile = this.affiliateReimbursements[index];
      this.detailsMobile = !this.detailsMobile;
    },
    checkExpressTallySheet() {
      var hasExpress = false;

      this.paperProducts.forEach(p => {
        if (p.express_reimbursement === true) {
          hasExpress = true;
        }
      });
      return hasExpress;
    },
    changeValidDate(value) {
      this.pickupHour = value;
    },
    getAddressPoints(env, brand) {
      this.environment = env;
      this.isLoadingAP = true;
      var params =
        "?type=affiliate" +
        "&brand=" +
        brand +
        "&environment=" +
        this.environment +
        "&per_page=10000&page=1";

      if (this.affiliate.user_config && this.affiliate.user_config.config) {
        if (this.affiliate.user_config.config.invoice_group) {
          params += "&invoice_group=1";
        }
      }

      httpServiceUser.userService
        .getAddressPoints(
          this.affiliate.company_id,
          params,
          Cookies.get("token")
        )
        .then(response => {
          if (response.status == 200) {
            this.addressPoints = response.data;
            this.isLoadingAP = false;
            this.addressPoints.forEach(x => {
              if (
                !this.workingPointAddress.find(
                  ({ codcom }) => codcom === x.codcom
                )
              ) {
                this.workingPointAddress.push({
                  codcom: x.codcom,
                  name: x.name,
                  address: x.address.street_name + ", " + x.address.locality
                });
              }
            });
            if (this.addressPoints.length === 0) {
              this.noAddressPoints = true;
            }
            this.showModalPopup("modal_popup_loading", false);
          } else if (response.status == 400 || response.status == 403) {
            this.showModalPopup("modal_popup_loading", false);
            return null;
          } else {
            this.showModalPopup("modal_popup_loading", false);
            return null;
          }
        })
        .catch(error => {
          this.showModalPopup("modal_popup_loading", false);
          return null;
        });
    },
    getCenterCost(env) {
      this.environment = env;
      this.isLoadingCC = true;
      var params =
        "?type=affiliate" +
        "&cost=1" +
        "&environment=" +
        this.environment +
        "&per_page=10000&page=1";
      if (this.affiliate.user_config && this.affiliate.user_config.config) {
        if (this.affiliate.user_config.config.invoice_group) {
          params += "&invoice_group=1";
        }
      }
      httpServiceUser.userService
        .getAddressPoints(
          this.affiliate.company_id,
          params,
          Cookies.get("token")
        )
        .then(response => {
          if (response.status == 200) {
            this.centerPoints = response.data;
            this.isLoadingCC = false;
            this.centerPoints.forEach(x => {
              if (
                !this.costCenterAddress.find(
                  ({ codcom }) => codcom === x.codcom
                )
              ) {
                this.costCenterAddress.push({
                  codcom: x.codcom,
                  name: x.name,
                  address: x.address.street_name + ", " + x.address.locality
                });
              }
            });
            // if (this.costCenterAddress.length === 1) {
            //   this.selectedCostCenterAddress = this.costCenterAddress[0];
            // }
            this.hasCostCenter = true;
            if (this.centerPoints.length === 0) {
              this.noAddressPoints = true;
            }
            this.showModalPopup("modal_popup_loading", false);
          } else if (response.status == 400 || response.status == 403) {
            this.showModalPopup("modal_popup_loading", false);
            return null;
          } else {
            this.showModalPopup("modal_popup_loading", false);
            return null;
          }
        })
        .catch(error => {
          this.showModalPopup("modal_popup_loading", false);
          return null;
        });
    },
    selectProduct(index) {
      this.selectedProduct = this.paperProducts[index];
      let environment = "paper";
      if (this.selectedProduct.paper === 0) {
        environment = "card";
      }
      this.getAddressPoints(environment, this.selectedProduct.brand);
    },
    getAddressSelected() {
      if (
        this.selectedWorkingPointAddress !== "" &&
        this.selectedWorkingPointAddress !== null
      ) {
        const result = this.addressPoints.filter(
          x => x.codcom === this.selectedWorkingPointAddress.codcom
        );
        this.showAddressDetails = true;
        this.deliveryPoint = result[0];
        this.selectedOtherAddress = false;
      } else {
        this.resetAddress();
        this.showAddressDetails = false;
      }
    },
    resetAddress() {
      if (this.selectedOtherAddress) {
        this.showAddressDetails = true;
        this.deliveryPoint = {
          address: {
            street_name: "",
            street_nr: null,
            locality: "",
            county: "",
            postal_code: ""
          },
          person: {
            first_name: "",
            last_name: "",
            email: "",
            mobile_phone: ""
          }
        };
        this.selectedWorkingPointAddress = "";
      } else {
        this.showAddressDetails = false;
      }
    },
    getProductNameMethod(productId) {
      return utils.getCompanyProductName(productId, this.paperProducts);
    },
    selectTypeTicketMobile(index) {
      this.selectedTypeTicket = this.reimbursementDetails.tally_sheet.products[
        index
      ];
      this.detailsMobile = !this.detailsMobile;
    },
    downloadReimbursement(awb = false) {
      var content;
      var fileName;
      if (awb) {
        content = this.reimbursementDetails.awb.content;
        fileName = this.reimbursementDetails.awb.reference;
      } else {
        content = this.reimbursementDetails.tally_sheet.content;
        fileName = this.reimbursementDetails.tally_sheet.tallysheet_id;
      }
      var blob = new Blob([utils.base64ToArrayBuffer(content)], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheetapplication/vnd.ms-excel"
      });
      saveAs(blob, fileName + ".pdf");
    },
    openPaperReimbursement() {
      this.isOpen = !this.isOpen;
    },
    hide() {
      this.isOpen = false;
    },
    customLabel({ codcom, name, address }) {
      return `${codcom} ${name} – ${address}`;
    },
    convertValueToDecimalVariable(variable) {
      if (/^-?\d*[.]?\d*$/.test(variable)) {
        this.oldValue = variable;
      } else {
        variable = this.oldValue;
      }
      return variable;
    },
    onlyNumericCharacters(variable) {
      if (/^[0-9]*$/i.test(variable)) {
        return variable;
      } else {
        return variable.replace(
          /[a-zA-Z&\/\\|`#@\[\]\-,+=()$~%.'":*!?<>{}]/g,
          ""
        );
      }
      return variable;
    },
    convertValueToDecimalVariableForInput(entry = null) {
      if (entry) {
        entry.nominal_value = this.convertValueToDecimalVariable(
          entry.nominal_value
        );
      } else {
        this.ticketValue = this.convertValueToDecimalVariable(this.ticketValue);
      }
      this.$forceUpdate();
    },
    formatTicketValue() {
      this.ticketValue = Number(this.ticketValue)
        .toFixed(2)
        .toString()
        .replace(",", ".")
        .replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },
    updateInputTicket(entry = null) {
      if (entry) {
        entry.tickets = this.onlyNumericCharacters(entry.tickets);
      } else {
        this.noOfTickets = this.onlyNumericCharacters(this.noOfTickets);
      }
      this.$forceUpdate();
    }
  },
  computed: {
    paperProducts() {
      let paperProducts = [];
      for (let index in this.affiliate.products) {
        if (this.affiliate.products[index].paper > 0) {
          paperProducts.push(this.affiliate.products[index]);
        }
      }
      return paperProducts;
    },
    hasOnlyHolidayProduct() {
      if (
        this.paperProducts.length === 1 &&
        this.paperProducts[0].paper === 5
      ) {
        return true;
      }
      return false;
    },
    getExpeditionPrice() {
      let totalExp = 0;
      let totalValueReimbursment = 0;
      if (parseInt(this.ticketValue) > 0 && parseInt(this.noOfTickets) > 0) {
        totalValueReimbursment +=
          parseInt(this.ticketValue) * parseInt(this.noOfTickets);
      }
      this.ticketsRepayment.forEach(function(el) {
        totalValueReimbursment += parseInt(el.total_value);
      });
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.costExpeditionWithInsurance = 0;
      if (this.insurance) {
        this.costExpeditionWithInsurance = (
          totalValueReimbursment * this.percentageExpeditionInsurance
        ).toFixed(2);
      }
      totalExp =
        this.costExpedition + parseFloat(this.costExpeditionWithInsurance);
      return totalExp;
    }
  },
  mounted() {
    (this.pickupHour = [
      moment().add(60, "minutes"),
      moment().add(60, "minutes")
    ]),
      (this.productType = this.paperProducts[0]);
    this.tallySheetType = this.checkExpressTallySheet();
    this.affiliateReimbursements = this.getHistoryReimbursements();
  },
  created() {
    this.selectProduct(0);
    this.getCenterCost("paper");
  },
  watch: {
    currentPage: function() {
      this.getHistoryReimbursements();
    }
  }
};
</script>

<style scoped src="@/assets/styles/beneficiary.css"></style>
<style>
.invoices-filter .reportrange-text {
  height: 27px;
  font-size: 13px;
  padding: 3px 10px;
  border: none !important;
  color: #585858;
  background: #f1f1f1;
}

#tallySheet-type .custom-control-input:checked ~ .custom-control-label::before,
.courier .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #4e5b59;
}

.chooseAddress {
  display: block;
  position: absolute;
  top: 100%;
  right: 0px;
  width: 100%;
  min-width: 500px;
  background: #fff;
  padding: 10px;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
}

.new-loading {
  height: 30px;
  padding: 5px 0;
}

@media (max-width: 600px) {
  .chooseAddress {
    min-width: 250px;
  }
}
</style>

<template>
  <div>
    <div class="delivery-title left" v-if="window.width > 1003">
      <h1>{{ $t("workingPoints") }}</h1>
      <p>{{ $t("workingPointsDescription") }}</p>
    </div>
    <div class="right">
      <a
        v-if="
          affiliate &&
            affiliate.role !== 'FAC' &&
            !affiliate.state_owned &&
            affiliate.user_config &&
            affiliate.user_config.roles &&
            affiliate.user_config.roles.includes('EDITOR')
        "
        class="notification-action mb-3"
        @click="addNewWorkingPoint()"
        >{{ $t("workingPointButtonAdd") }}</a
      >
    </div>
    <br clear="all" />
    <div class="content-delivery">
      <div class="second-tabs">
        <form
          @submit.prevent="
            {
              return false;
            }
          "
        >
          <p>
            <strong>{{ $t("workingPointSelectLabel") }}</strong>
          </p>
          <multiselect
            v-model="selectedWorkingPointAddress"
            :options="workingPointAddress"
            :placeholder="$t('selectLabel')"
            select-label=""
            selected-label=""
            deselect-label=""
            openDirection="bottom"
            track-by="address"
            :custom-label="customLabel"
            :loading="isLoading"
          >
          </multiselect>
        </form>
      </div>

      <div class="second-tabs">
        <div
          v-if="addressPoints === null || addressPoints.length === 0"
          class="loading"
        >
          <img src="@/assets/images/loading.gif" />
        </div>
        <p v-if="noAddressPoints">
          {{ $t("deliveryNoHasAddress") }}
        </p>
        <div v-for="(point, index) in filterWorkingPoints" :key="index">
          <h3>{{ point.name }} - {{ point.address.street_name }}</h3>
          <div class="grey-info">
            <b-container fluid class="border-box">
              <form
                class="margin-paragraph"
                @submit.prevent="
                  {
                    return false;
                  }
                "
              >
                <b-row class="border-box">
                  <b-col class="border-box" xl="12" md="12">
                    <p class="title-address-table">
                      <strong>{{ $t("deliveryPointAddressTitle") }}</strong>
                    </p>
                  </b-col>
                  <b-col class="border-box" xl="4" md="4">
                    <p>
                      <small>{{ $t("workingPointAddress") }}</small>
                      <br />
                      <input
                        v-if="point !== selectedPoint"
                        type="text"
                        v-model="point.address.street_name"
                        class="formedituser"
                        maxlength="64"
                        disabled
                      />
                      <input
                        v-else
                        type="text"
                        v-model="point.succursal.address.street_name"
                        class="formedituser"
                        maxlength="64"
                        @keyup="updateInputCharacters()"
                      />
                    </p>
                  </b-col>
                  <b-col class="border-box" xl="2" md="2">
                    <small>{{ $t("deliveryPointAddressDistrict") }}</small>
                    <br />
                    <input
                      v-if="point !== selectedPoint"
                      type="text"
                      v-model="point.address.county"
                      class="formedituser"
                      disabled
                    />
                    <multiselect
                      v-else
                      v-model="point.address.county_obj"
                      :options="county"
                      class="margin-top-1rem"
                      :placeholder="$t('selectLabel')"
                      select-label=""
                      selected-label=""
                      deselect-label=""
                      track-by="id"
                      label="value"
                      :loading="isLoading"
                      @input="
                        getNomenclatures(
                          'locality',
                          point.address.county_obj.id
                        )
                      "
                    />
                  </b-col>
                  <b-col class="border-box" xl="2" md="2">
                    <small>{{ $t("deliveryPointAddressCity") }}</small>
                    <br />
                    <input
                      v-if="point !== selectedPoint"
                      type="text"
                      v-model="point.address.locality"
                      class="formedituser"
                      disabled
                    />
                    <multiselect
                      v-else
                      v-model="point.address.locality_obj"
                      :options="locality"
                      class="margin-top-1rem"
                      :placeholder="$t('selectLabel')"
                      select-label=""
                      selected-label=""
                      deselect-label=""
                      track-by="id"
                      label="value"
                      :loading="isLoading"
                      :disabled="point !== selectedPoint"
                      @open="locality = map.get('locality')"
                      @input="updateMultiSelect()"
                    />
                  </b-col>
                  <b-col class="border-box" xl="2" md="2">
                    <p>
                      <small>
                        {{ $t("workingPointGPSLon") }}
                      </small>
                      <br />
                      <input
                        v-if="point !== selectedPoint"
                        type="text"
                        v-model="point.address.gps_longitude"
                        class="formedituser"
                        disabled
                      />
                      <input
                        v-else
                        type="text"
                        v-model="point.succursal.address.gps_longitude"
                        class="formedituser"
                        @keyup="updateInputCharacters()"
                      />
                    </p>
                  </b-col>
                  <b-col class="border-box" xl="2" md="2">
                    <p>
                      <small>
                        {{ $t("workingPointGPSLat") }}
                      </small>
                      <br />
                      <input
                        v-if="point !== selectedPoint"
                        type="text"
                        v-model="point.address.gps_latitude"
                        class="formedituser"
                        disabled
                      />
                      <input
                        v-else
                        type="text"
                        v-model="point.succursal.address.gps_latitude"
                        class="formedituser"
                        @keyup="updateInputCharacters()"
                      />
                    </p>
                  </b-col>
                </b-row>
                <b-row class="border-box">
                  <b-col class="border-box mb-3" xl="4" md="4">
                    <span class="title-address-table">
                      <strong>{{ $t("workingPointAddressFac") }}</strong>
                    </span>
                    <input
                      v-if="point !== selectedPoint"
                      type="text"
                      v-model="point.invoice_address"
                      class="formedituser"
                      disabled
                    />
                    <multiselect
                      v-else
                      v-model="point.invoice_address_obj"
                      :options="invoiceAddresses"
                      class="margin-top-1rem"
                      :placeholder="$t('selectLabel')"
                      select-label=""
                      selected-label=""
                      deselect-label=""
                      track-by="id"
                      :custom-label="customLabelInvoiceAddresses"
                      :loading="isLoading"
                      :disabled="point !== selectedPoint"
                      @input="updateMultiSelect()"
                    />
                  </b-col>
                  <b-col class="border-box mb-3" xl="4" md="4">
                    <span class="title-address-table">
                      <strong>{{ $t("workingPointAddressFacEmail") }}</strong>
                    </span>
                    <input
                      v-if="point !== selectedPoint"
                      type="text"
                      v-model="point.invoice_email"
                      class="formedituser"
                      disabled
                    />
                    <input
                      v-else
                      type="text"
                      v-model="point.succursal.invoice_email"
                      class="formedituser"
                      @keyup="updateInputCharacters()"
                    />
                  </b-col>
                  <b-col class="border-box mb-3" xl="4" md="4">
                    <span class="title-address-table">
                      <strong>{{ $t("deliveryPointContactTitle") }}</strong>
                    </span>
                    <input
                      v-if="point !== selectedPoint"
                      type="text"
                      v-model="point.person.full_name"
                      class="formedituser"
                      disabled
                    />
                    <multiselect
                      v-else
                      v-model="point.person.person_obj"
                      :options="contactPersons"
                      :placeholder="$t('selectLabel')"
                      select-label=""
                      selected-label=""
                      deselect-label=""
                      track-by="id"
                      :custom-label="customLabelPersons"
                      :loading="isLoading"
                      class="margin-top-1rem"
                      :disabled="point !== selectedPoint"
                      @input="updateMultiSelect('function')"
                    />
                    <b-form-checkbox
                      v-if="point === selectedPoint"
                      class="d-inline-block"
                      v-model="addNewPersonBool"
                      @change="addNewPerson($event)"
                      >{{
                        $t("workingPointAddNewPersonLabel")
                      }}</b-form-checkbox
                    >
                  </b-col>
                </b-row>
                <b-row
                  v-if="editMode && point.person.person_obj"
                  class="border-box"
                >
                  <b-col class="border-box" xl="2" md="6">
                    <p>
                      <small>{{ $t("deliveryPointContactSurname") }}</small>
                      <br />
                      <input
                        :disabled="point !== selectedPoint"
                        type="text"
                        v-model="point.person.person_obj.firstName"
                        class="formedituser"
                        @keyup="updateInputCharacters()"
                      />
                    </p>
                  </b-col>
                  <b-col class="border-box" xl="2" md="6">
                    <p>
                      <small>{{ $t("deliveryPointContactName") }}</small>
                      <br />
                      <input
                        :disabled="point !== selectedPoint"
                        type="text"
                        v-model="point.person.person_obj.lastName"
                        class="formedituser"
                        @keyup="updateInputCharacters()"
                      />
                    </p>
                  </b-col>
                  <b-col class="border-box" xl="3" lg="6" md="6">
                    <p>
                      <small>{{ $t("workingPointPersonPosition") }}</small>
                      <br />
                      <multiselect
                        v-model="point.person.person_obj.function_obj"
                        :options="work"
                        class="margin-top-1rem"
                        :placeholder="$t('selectLabel')"
                        select-label=""
                        selected-label=""
                        deselect-label=""
                        track-by="id"
                        label="value"
                        :loading="isLoading"
                        :disabled="point !== selectedPoint"
                        @input="updateMultiSelect()"
                      />
                    </p>
                  </b-col>
                  <b-col class="border-box" xl="3" md="6">
                    <p>
                      <small>{{ $t("workingPointContactEmailLabel") }}</small>
                      <br />
                      <input
                        :disabled="point !== selectedPoint"
                        type="text"
                        v-model="point.person.person_obj.email1"
                        class="formedituser"
                        @keyup="updateInputCharacters()"
                      />
                    </p>
                  </b-col>
                  <b-col class="border-box" xl="2" md="6">
                    <p>
                      <small>{{ $t("workingPointContactMobileLabel") }}</small>
                      <br />
                      <input
                        :disabled="point !== selectedPoint"
                        type="text"
                        v-model="point.person.person_obj.mobile1"
                        class="formedituser"
                        maxlength="14"
                        minlength="10"
                        @keyup="updateInputCharacters()"
                      />
                    </p>
                  </b-col>
                </b-row>
                <b-row class="border-box d-flex align-items-center mt-2">
                  <b-col class="border-box" xl="12" md="12">
                    <b-row class="border-box">
                      <b-col
                        v-if="point !== selectedPoint"
                        class="border-box"
                        xl="12"
                        md="12"
                      >
                        <table
                          cellpadding="0"
                          cellspacing="0"
                          width="100%"
                          class="hover-table"
                          align="center"
                        >
                          <thead>
                            <tr>
                              <td>
                                <strong>{{
                                  $t("workingPointProductsLabel")
                                }}</strong>
                              </td>
                              <td>
                                <strong>{{
                                  $t("workingPointProductsCardLabel")
                                }}</strong>
                              </td>
                              <td>
                                <strong>{{
                                  $t("workingPointProductsPaperLabel")
                                }}</strong>
                              </td>
                            </tr>
                          </thead>
                          <tbody>
                            <template
                              v-for="(entry, index) in point.productsAffiliate"
                            >
                              <tr
                                :key="index"
                                class="parent"
                                :class="index % 2 === 0 && 'gri-cell'"
                              >
                                <td>
                                  <div class="d-flex align-items-center">
                                    <div>
                                      <img
                                        :src="
                                          require('@/assets/images/logo-up-' +
                                            (entry.card
                                              ? entry.card
                                              : entry.paper) +
                                            '.png')
                                        "
                                      />
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <b-form-checkbox
                                    v-if="entry.card !== 15"
                                    class="d-inline-block"
                                    v-model="point.products"
                                    :value="entry.card"
                                    :disabled="point !== selectedPoint"
                                  ></b-form-checkbox>
                                  <img
                                    :src="require('@/assets/images/NEW.svg')"
                                    class="logo-product"
                                    :class="entry.card === 15 && 'ml-4'"
                                  />
                                </td>
                                <td>
                                  <b-form-checkbox
                                    class="d-inline-block"
                                    v-model="point.products"
                                    :value="entry.paper"
                                    :disabled="point !== selectedPoint"
                                  ></b-form-checkbox>
                                  <img
                                    :src="require('@/assets/images/PAPER.svg')"
                                    class="logo-product"
                                  />
                                </td>
                              </tr>
                            </template>
                          </tbody>
                        </table>
                      </b-col>
                      <b-col v-else class="border-box" xl="12" md="12">
                        <table
                          cellpadding="0"
                          cellspacing="0"
                          width="100%"
                          class="hover-table"
                          align="center"
                        >
                          <thead>
                            <tr>
                              <td class="pr-3">
                                <strong>{{
                                  $t("workingPointProductsLabel")
                                }}</strong>
                              </td>
                              <td class="pr-3">
                                <strong>{{
                                  $t("workingPointProductsCardLabel")
                                }}</strong>
                              </td>
                              <td class="priority-0 pr-3">
                                <strong>{{
                                  $t("workingPointProductsPaperLabel")
                                }}</strong>
                              </td>
                              <td class="priority-4 pr-3">
                                <strong>{{
                                  $t("workingPointTypeShop")
                                }}</strong>
                              </td>
                              <td class="priority-5 pr-3">
                                <strong>{{
                                  $t("workingPointCommercialNameLabel")
                                }}</strong>
                              </td>
                            </tr>
                          </thead>
                          <tbody>
                            <template
                              v-for="(entry,
                              index) in point.productsAffiliateEdit"
                            >
                              <tr
                                :key="index"
                                class="parent"
                                :class="index % 2 === 0 && 'gri-cell'"
                              >
                                <td class="pr-3">
                                  <a
                                    class="
                                      detailsOrderMobile
                                      priority-show-5
                                      display-inline-block
                                    "
                                    @click="selectProductDetailsMobile(index)"
                                  >
                                    <font-awesome-icon
                                      icon="minus-circle"
                                      v-if="
                                        detailsMobile &&
                                          entry === productSelectedMobile
                                      "
                                    />
                                    <font-awesome-icon
                                      icon="plus-circle"
                                      v-else
                                    />&nbsp;&nbsp;
                                  </a>
                                  <img
                                    :src="
                                      require('@/assets/images/logo-up-' +
                                        (entry.card
                                          ? entry.card
                                          : entry.paper) +
                                        '.png')
                                    "
                                  />
                                </td>
                                <td class="pr-3">
                                  <b-form-checkbox
                                    v-if="entry.hasCard && entry.card !== 15"
                                    class="d-inline-block"
                                    v-model="point.products"
                                    :value="entry.card"
                                    :disabled="point !== selectedPoint"
                                  ></b-form-checkbox>
                                  <img
                                    :src="require('@/assets/images/NEW.svg')"
                                    class="logo-product"
                                    :class="
                                      (!entry.hasCard || entry.card === 15) &&
                                        'ml-4'
                                    "
                                  />
                                </td>
                                <td class="priority-0 pr-3">
                                  <b-form-checkbox
                                    v-if="entry.hasPaper"
                                    class="d-inline-block"
                                    v-model="point.products"
                                    :value="entry.paper"
                                    :disabled="point !== selectedPoint"
                                  ></b-form-checkbox>
                                  <img
                                    :src="require('@/assets/images/PAPER.svg')"
                                    class="logo-product"
                                    :class="!entry.hasPaper && 'ml-4'"
                                  />
                                </td>
                                <td class="priority-4 pr-3">
                                  <multiselect
                                    v-model="entry.shopType"
                                    :options="
                                      map
                                        .get('shop_type')
                                        .get(
                                          (entry.paper && entry.paper !== 0
                                            ? entry.paper
                                            : entry.card) + ''
                                        )
                                    "
                                    :placeholder="$t('selectLabel')"
                                    select-label=""
                                    selected-label=""
                                    deselect-label=""
                                    track-by="id"
                                    label="value"
                                    :loading="isLoading"
                                    @input="updateMultiSelect()"
                                  >
                                  </multiselect>
                                </td>
                                <td class="priority-5 pr-3">
                                  <input
                                    type="text"
                                    v-model="entry.commercial_name"
                                    class="formedituser"
                                  />
                                </td>
                              </tr>
                              <tr
                                :key="
                                  index + point.productsAffiliateEdit.length
                                "
                                class="child"
                                :class="index % 2 === 0 && 'gri-cell'"
                                v-show="
                                  detailsMobile &&
                                    entry === productSelectedMobile
                                "
                              >
                                <td colspan="12">
                                  <p class="priority-show-0 mt-3">
                                    <strong
                                      >{{
                                        $t("workingPointProductsPaperLabel")
                                      }}
                                      : &nbsp;&nbsp;</strong
                                    >
                                    <span>
                                      <b-form-checkbox
                                        v-if="entry.hasPaper"
                                        class="d-inline-block"
                                        v-model="point.products"
                                        :value="entry.paper"
                                        :disabled="point !== selectedPoint"
                                      ></b-form-checkbox>
                                      <img
                                        :src="
                                          require('@/assets/images/PAPER.svg')
                                        "
                                        class="logo-product"
                                        :class="!entry.hasPaper && 'ml-4'"
                                      />
                                    </span>
                                  </p>
                                  <p class="priority-show-4">
                                    <strong
                                      >{{ $t("workingPointTypeShop") }} :
                                      &nbsp;&nbsp;</strong
                                    >
                                    <span>
                                      <multiselect
                                        v-model="entry.shopType"
                                        :options="
                                          map
                                            .get('shop_type')
                                            .get(
                                              (entry.paper && entry.paper !== 0
                                                ? entry.paper
                                                : entry.card) + ''
                                            )
                                        "
                                        :placeholder="$t('selectLabel')"
                                        select-label=""
                                        selected-label=""
                                        deselect-label=""
                                        track-by="id"
                                        label="value"
                                        :loading="isLoading"
                                        @input="updateMultiSelect()"
                                      >
                                      </multiselect>
                                    </span>
                                  </p>
                                  <p class="priority-show-5">
                                    <strong
                                      >{{
                                        $t("workingPointCommercialNameLabel")
                                      }}
                                      : &nbsp;&nbsp;</strong
                                    >
                                    <span>
                                      <input
                                        type="text"
                                        v-model="entry.commercial_name"
                                        class="formedituser"
                                      />
                                    </span>
                                  </p>
                                </td>
                              </tr>
                            </template>
                          </tbody>
                        </table>
                      </b-col>
                      <!-- <b-col v-else class="border-box" xl="12" md="12">
                        <b-row
                          v-for="(entry, index) in point.productsAffiliateEdit"
                          :key="index"
                          class="border-box"
                        >
                          <b-col class="border-box mb-2" xl="2" md="2">
                            <img
                              :src="
                                require('@/assets/images/logo-up-' +
                                  (entry.card ? entry.card : entry.paper) +
                                  '.png')
                              "
                            />
                          </b-col>
                          <b-col class="border-box mb-2" cols="6" sm="2">
                            <b-form-checkbox
                              v-if="entry.hasCard"
                              class="d-inline-block"
                              v-model="point.products"
                              :value="entry.card"
                              :disabled="point !== selectedPoint"
                            ></b-form-checkbox>
                            <img
                              :src="require('@/assets/images/NEW.svg')"
                              class="logo-product"
                              :class="!entry.hasCard && 'ml-4'"
                            />
                          </b-col>
                          <b-col class="border-box mb-2" cols="6" sm="2">
                            <b-form-checkbox
                              v-if="entry.hasPaper"
                              class="d-inline-block"
                              v-model="point.products"
                              :value="entry.paper"
                              :disabled="point !== selectedPoint"
                            ></b-form-checkbox>
                            <img
                              :src="require('@/assets/images/PAPER.svg')"
                              class="logo-product"
                              :class="!entry.hasPaper && 'ml-4'"
                            />
                          </b-col>
                          <b-col
                            v-if="point === selectedPoint"
                            class="border-box mb-2"
                            xl="2"
                            md="2"
                          >
                            <p class="mb-0">
                              <small>{{ $t("workingPointTypeShop") }}: </small>
                            </p>
                          </b-col>
                          <b-col
                            v-if="point === selectedPoint"
                            class="border-box mb-2"
                            xl="4"
                            md="4"
                          >
                            <multiselect
                              v-model="entry.shopType"
                              :options="
                                map
                                  .get('shop_type')
                                  .get(
                                    (entry.paper && entry.paper !== 0
                                      ? entry.paper
                                      : entry.card) + ''
                                  )
                              "
                              :placeholder="$t('selectLabel')"
                              select-label="Selecteaza"
                              track-by="id"
                              label="value"
                              :loading="isLoading"
                            >
                            </multiselect>
                          </b-col>
                        </b-row>
                      </b-col> -->
                    </b-row>
                  </b-col>
                </b-row>
                <br clear="all" />
                <b-row
                  class="border-box"
                  v-if="
                    point !== selectedPoint &&
                      affiliate &&
                      affiliate.role !== 'FAC' &&
                      !affiliate.state_owned &&
                      affiliate.user_config &&
                      affiliate.user_config.roles &&
                      affiliate.user_config.roles.includes('EDITOR')
                  "
                >
                  <b-col class="border-box" md="12">
                    <p>
                      <a
                        class="notification-action custom-notification-action"
                        @click="editAddressPoint(index)"
                        >{{ $t("editButtonLabel") }}</a
                      >
                    </p>
                  </b-col>
                </b-row>
                <b-row
                  class="border-box"
                  v-if="
                    point === selectedPoint &&
                      affiliate &&
                      affiliate.role !== 'FAC' &&
                      !affiliate.state_owned &&
                      affiliate.user_config &&
                      affiliate.user_config.roles &&
                      affiliate.user_config.roles.includes('EDITOR')
                  "
                >
                  <b-col class="border-box" cols="6" md="6">
                    <p>
                      <a
                        class="notification-action"
                        @click="submitAddressPoint()"
                        >{{ $t("saveChangesLabel") }}</a
                      >
                    </p>
                  </b-col>
                  <b-col class="border-box" cols="6" md="6">
                    <p class="line-height-43 text-right">
                      <a
                        class="resetButton"
                        @click="resetAddressPoint(point)"
                        >{{ $t("cancelChangesLabel") }}</a
                      >
                    </p>
                  </b-col>
                </b-row>
              </form>
            </b-container>
          </div>
        </div>
      </div>
    </div>
    <div id="modal_popup_info" class="modal-popup">
      <div class="modal-popup-content">
        <a
          @click="showModalPopup('modal_popup_info', false)"
          class="close-button"
        >
          <img src="@/assets/images/close-button.svg" />
        </a>
        <div v-if="this.sendAddressError">
          <p>{{ $t("errorSentAddressPoint_" + this.sendAddressError.code) }}</p>
        </div>
        <div v-else>
          <p>{{ $t("sentAddressPointSuccess") }}</p>
        </div>
      </div>
    </div>
    <div id="modal_popup_loading" class="modal-popup">
      <div class="modal-popup-content">
        <img src="@/assets/images/loading.gif" />
        <p v-html="$t('loadingPopupMessage')"></p>
      </div>
    </div>
  </div>
</template>

<script>
import httpServiceUser from "@/http/http-user.js";
import httpServiceCommon from "@/http/http-common.js";
import httpServiceAffiliate from "@/http/http-affiliate.js";
import Cookies from "js-cookie";
import { BContainer, BRow, BCol, BFormCheckbox } from "bootstrap-vue";
import Multiselect from "vue-multiselect";
import validator from "@/plugins/fieldValidation.js";

export default {
  props: {
    affiliate: Object,
    msg: String
  },
  components: {
    "b-container": BContainer,
    "b-row": BRow,
    "b-col": BCol,
    "b-form-checkbox": BFormCheckbox,
    Multiselect
  },
  data() {
    return {
      selectedProduct: null,
      addressPoints: [],
      environment: null,
      window: {
        width: 0,
        height: 0
      },
      workingPointAddress: [],
      selectedWorkingPointAddress: "",
      noAddressPoints: false,
      isLoading: false,
      selectedPerson: "",
      // selectedProduct: [],
      editMode: false,
      formData: null,
      selectedPoint: null,
      allProducts: false,
      map: new Map(),
      mapIndex: 0,
      county: [],
      locality: [],
      work: [],
      shopType: [],
      sendAddressError: null,
      invoiceAddresses: [],
      contactPersons: [],
      shopProfile: null,
      wpDTO: {
        actionType: "", // c sau u
        id: null,
        societyCodall: null,
        street: "",
        communeId: "",
        latitude: "",
        longitude: "",
        contact: {
          id: null,
          function_id: null,
          first_name: "",
          last_name: "",
          phone1: "",
          mobile1: "",
          email1: ""
        },
        invoiceAddressId: "",
        invoiceEmail: "",
        workPointProducts: {
          productId: null,
          profileId: null,
          inactive: false,
          unitTypeId: null,
          commercialName: ""
        }
      },
      detailsMobile: false,
      addNewPersonBool: false,
      errorWppArrayToSubmit: false
    };
  },
  methods: {
    selectProduct(index) {
      this.selectedProduct = this.affiliate.products[index];
      let environment = "paper";
      if (this.selectedProduct.paper === 0) {
        environment = "card";
      }
      this.getAddressPoints(environment, this.selectedProduct.brand);
    },
    isSelectedProduct(product) {
      return product + "" === this.selectedProduct;
    },
    selectProductDetailsMobile(index) {
      this.productSelectedMobile = this.selectedPoint.productsAffiliateEdit[
        index
      ];
      this.detailsMobile = !this.detailsMobile;
    },
    selectedProductMobile(event) {
      this.selectedProduct = this.affiliate.products[event.target.value];
      this.getAddressPoints("paper", this.selectedProduct.brand);
    },
    getAddressPoints(env, brand = null) {
      this.environment = env;
      this.isLoading = true;
      this.workingPointAddress = [];
      var params =
        "?type=affiliate" +
        "&environment=" +
        this.environment +
        "&per_page=10000&page=1";

      if (brand) {
        params += "&brand=" + brand;
      }

      httpServiceUser.userService
        .getAddressPoints(
          this.affiliate.company_id,
          params,
          Cookies.get("token")
        )
        .then(response => {
          if (response.status == 200) {
            this.addressPoints = response.data;
            this.isLoading = false;
            this.addressPoints.forEach(x => {
              x.person.full_name =
                x.person.first_name + " " + x.person.last_name;
              this.workingPointAddress.push({
                codcom: x.codcom,
                address: x.address.street_name + ", " + x.address.locality,
                id: x.address.id
              });
            });
            if (this.addressPoints.length === 0) {
              this.noAddressPoints = true;
            }
            this.showModalPopup("modal_popup_loading", false);
          } else if (response.status == 400 || response.status == 403) {
            this.showModalPopup("modal_popup_loading", false);
            return null;
          } else {
            this.showModalPopup("modal_popup_loading", false);
            return null;
          }
        })
        .catch(error => {
          this.showModalPopup("modal_popup_loading", false);
          return null;
        });
    },
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
    showModalPopup(elementId, show) {
      if (show) {
        document.getElementById(elementId).style.visibility = "visible";
      } else {
        document.getElementById(elementId).style.visibility = "hidden";
      }
    },
    customLabel({ codcom, address }) {
      return `${codcom} – ${address}`;
    },
    customLabelPersons({ firstName, lastName }) {
      return `${firstName}  ${lastName}`;
    },
    customLabelInvoiceAddresses({ street_name, locality }) {
      return `${street_name}  ${locality}`;
    },
    addNewWorkingPoint() {
      this.selectedWorkingPointAddress = null;
      this.addressPoints.unshift({
        address: {
          county: "",
          county_id: null,
          gps_latitude: "",
          gps_longitude: "",
          id: null,
          locality: "",
          locality_id: null,
          postal_code: "",
          street_name: ""
        },
        codcom: null,
        invoice_address: "",
        invoice_address_id: null,
        invoice_email: "",
        name: "",
        person: {
          email: "",
          first_name: "",
          full_name: "",
          functionId: null,
          id: null,
          last_name: "",
          mobile_phone: ""
        },
        products: [],
        productsAffiliate: [],
        new: true,
        succursal: {
          address: {
            county: "",
            county_id: null,
            gps_latitude: "",
            gps_longitude: "",
            id: null,
            locality: "",
            locality_id: null,
            postal_code: "",
            street_name: ""
          },
          invoice_email: ""
        }
      });
      this.editAddressPoint(0);
    },
    editAddressPoint(index) {
      this.editMode = true;
      this.selectedPoint = this.filterWorkingPoints[index];
      this.selectedPoint.succursal = {
        address: {
          street_name: this.selectedPoint.address.street_name,
          locality_id: this.selectedPoint.address.locality_obj
            ? this.selectedPoint.address.locality_obj.id
            : null,
          locality: null,
          county_id: this.selectedPoint.address.county_obj
            ? this.selectedPoint.address.county_obj.id
            : null,
          county: null,
          postal_code: null,
          gps_latitude: this.selectedPoint.address.gps_latitude,
          gps_longitude: this.selectedPoint.address.gps_longitude
        },
        invoice_email: this.selectedPoint.invoice_email
      };

      this.county = this.map.get("county");
      this.work = this.map.get("work");
      if (this.selectedPoint.address.id) {
        this.selectedPoint.address.county_obj = this.county.find(
          x => x.id === this.selectedPoint.address.county_id
        );
        this.getNomenclatures(
          "locality",
          this.selectedPoint.address.county_obj.id,
          null,
          null,
          index
        );
        this.selectedPoint.invoice_address_obj = this.invoiceAddresses.find(
          x => x.id === this.selectedPoint.invoice_address_id
        );
        this.selectedPoint.person.person_obj = this.contactPersons.find(
          x => x.id === this.selectedPoint.person.id
        );
        this.selectedPoint.person.person_obj.function_obj = this.work.find(
          x => x.id === this.selectedPoint.person.person_obj.functionId
        );
      }

      // this.selectedPerson = {...this.selectedPoint.person.person_obj};

      this.selectedPoint.productsAffiliate.forEach(x => {
        x.shopType = this.map
          .get("shop_type")
          .get(x.paper ? x.paper.toString() : x.card.toString())
          .find(y => y.id === x.unit_type_id);
      });
      this.selectedPoint.productsAffiliateEdit = [
        ...this.selectedPoint.productsAffiliate
      ];
      this.affiliate.products.forEach(x => {
        let address = this.selectedPoint.productsAffiliate.find(
          y =>
            (y.card === x.card && y.card > 0) ||
            (y.paper === x.paper && y.paper > 0)
        );
        if (!address) {
          address = {
            shop_profile_id: 0,
            unit_type_id: 0,
            wpp_id: this.filterWorkingPoints[
              this.filterWorkingPoints.length - 1
            ].productsAffiliate[0].wpp_id,
            commercial_name: ""
          };
          this.selectedPoint.productsAffiliateEdit.push(address);
        }
        if (x.paper > 0) {
          address.paper = x.paper;
          address.hasPaper = true;
        }
        if (x.card > 0) {
          address.hasCard = true;
          address.card = x.card;
        }
      });
    },
    submitAddressPoint() {
      let wppArrayToSubmit = [];
      this.errorWppArrayToSubmit = false;
      try {
        this.selectedPoint.productsAffiliateEdit.forEach(p => {
          if (p.card && this.selectedPoint.products.includes(p.card)) {
            if (!p.shopType) {
              this.sendAddressError = { code: "NO_UNIT_TYPE" };
              this.closeAndOpenPopup("submitAddressPoint");
              this.errorWppArrayToSubmit = true;
              throw "Break";
            } else if (p.commercial_name.length === 0) {
              this.sendAddressError = { code: "NO_COMMERCIAL_NAME" };
              this.closeAndOpenPopup("submitAddressPoint");
              this.errorWppArrayToSubmit = true;
              throw "Break";
            } else {
              wppArrayToSubmit.push({
                productId: p.card,
                profileId: p.shop_profile_id,
                unitTypeId: p.shopType.id,
                commercialName: p.commercial_name,
                inactive: false
              });
            }
          }
          if (p.paper && this.selectedPoint.products.includes(p.paper)) {
            if (!p.shopType) {
              this.sendAddressError = { code: "NO_UNIT_TYPE" };
              this.closeAndOpenPopup("submitAddressPoint");
              this.errorWppArrayToSubmit = true;
              throw "Break";
            } else if (p.commercial_name.length === 0) {
              this.sendAddressError = { code: "NO_COMMERCIAL_NAME" };
              this.closeAndOpenPopup("submitAddressPoint");
              this.errorWppArrayToSubmit = true;
              throw "Break";
            } else {
              wppArrayToSubmit.push({
                productId: p.paper,
                profileId: p.shop_profile_id,
                unitTypeId: p.shopType.id,
                commercialName: p.commercial_name,
                inactive: false
              });
            }
          }
        });
      } catch (e) {
        if (e !== "Break") throw e;
        return;
      }

      if (this.errorWppArrayToSubmit) {
        return;
      }

      this.sendAddressError = null;
      this.showModalPopup("modal_popup_loading", true);

      // if (wppArrayToSubmit.length === 0) {
      //   this.sendAddressError = { code: "NO_WPP" };
      //   this.closeAndOpenPopup("submitAddressPoint");
      //   return;
      // }

      if (
        this.selectedPoint.succursal.address.street_name.length === 0 ||
        this.selectedPoint.succursal.address.street_name.length > 64
      ) {
        this.sendAddressError = { code: "NO_LENGTH_ADDRESS" };
        this.closeAndOpenPopup("submitAddressPoint");
        return;
      } else if (!this.selectedPoint.address.county_obj) {
        this.sendAddressError = { code: "NO_LENGTH_COUNTY" };
        this.closeAndOpenPopup("submitAddressPoint");
        return;
      } else if (!this.selectedPoint.address.locality_obj) {
        this.sendAddressError = { code: "NO_LENGTH_LOCALITY" };
        this.closeAndOpenPopup("submitAddressPoint");
        return;
      } else if (!this.selectedPoint.invoice_address_obj) {
        this.sendAddressError = { code: "NO_INVOICE_ADDRESS" };
        this.closeAndOpenPopup("submitAddressPoint");
        return;
      } else if (
        this.selectedPoint.succursal.invoice_email.length === 0 ||
        !validator.validateEmail(this.selectedPoint.succursal.invoice_email)
      ) {
        this.sendAddressError = { code: "NO_INVOICE_EMAIL" };
        this.closeAndOpenPopup("submitAddressPoint");
        return;
      } else if (
        this.selectedPoint.succursal.address.gps_latitude.length !== 0 &&
        !validator.validateGPSCoordonate(
          this.selectedPoint.succursal.address.gps_latitude
        )
      ) {
        this.sendAddressError = { code: "NO_VALID_GPS_LATITUDE" };
        this.closeAndOpenPopup("submitAddressPoint");
        return;
      } else if (
        this.selectedPoint.succursal.address.gps_longitude.length !== 0 &&
        !validator.validateGPSCoordonate(
          this.selectedPoint.succursal.address.gps_longitude
        )
      ) {
        this.sendAddressError = { code: "NO_VALID_GPS_LONGITUDE" };
        this.closeAndOpenPopup("submitAddressPoint");
        return;
      } else if (!this.selectedPoint.person.person_obj) {
        this.sendAddressError = { code: "NO_PERSON" };
        this.closeAndOpenPopup("submitAddressPoint");
        return;
      }
      // else if (this.selectedPoint.person.person_obj.email1.length === 0 || !validator.validateEmail(this.selectedPoint.person.person_obj.email1)) {
      //   this.sendAddressError = { code: "NO_PERSON_EMAIL" };
      //   this.closeAndOpenPopup("submitAddressPoint");
      //   return;
      // } else if (this.selectedPoint.person.person_obj.mobile1.length === 0 || !validator.validateMobilePhone(this.selectedPoint.person.person_obj.mobile1)) {
      //   this.sendAddressError = { code: "NO_PERSON_MOBILE" };
      //   this.closeAndOpenPopup("submitAddressPoint");
      //   return;
      // }
      else {
        let formData = {
          actionType: this.selectedPoint.new ? "C" : "U", // C sau U
          id: this.selectedPoint.address.id,
          societyCodall: this.affiliate.company_id,
          address: this.selectedPoint.succursal.address.street_name,
          communeId: this.selectedPoint.address.locality_obj
            ? this.selectedPoint.address.locality_obj.id
            : this.selectedPoint.address.locality_id,
          latitude: this.selectedPoint.succursal.address.gps_latitude,
          longitude: this.selectedPoint.succursal.address.gps_longitude,
          contact: {
            id: this.selectedPoint.person.person_obj.id,
            functionId: this.selectedPoint.person.person_obj.function_obj.id,
            firstName: this.selectedPoint.person.person_obj.firstName,
            lastName: this.selectedPoint.person.person_obj.lastName,
            phone1: this.selectedPoint.person.person_obj.phone1,
            mobile1: this.selectedPoint.person.person_obj.mobile1,
            email1: this.selectedPoint.person.person_obj.email1
          },
          invoiceAddressId: this.selectedPoint.invoice_address_obj.id,
          invoiceEmail: this.selectedPoint.succursal.invoice_email,
          workPointProducts: wppArrayToSubmit
        };

        httpServiceAffiliate.affiliateService
          .changeWorkingPoint(
            this.affiliate.company_id,
            formData,
            Cookies.get("token")
          )
          .then(response => {
            if (response.status == 200 || response.status == 204) {
              this.sendAddressError = null;
              if (this.selectedPoint.person.person_obj.new) {
                this.addNewPersonBool = false;
                this.getContactPerson();
              }
              this.getAddressPoints(
                this.environment,
                this.selectedProduct.brand
              );
              this.getInvoiceAdresses();
              this.closeAndOpenPopup("submitAddressPoint");
            } else {
              this.sendAddressError = { code: "NOT_SENT" };
              this.closeAndOpenPopup("submitAddressPoint");
            }
          })
          .catch(error => {
            if (error.response) {
              this.sendAddressError = { code: "NOT_SENT" };
              this.closeAndOpenPopup("submitAddressPoint");
              // this.companyDataError = { description: error.response.status };
            } else {
              this.sendAddressError = { code: "NOT_SENT" };
              this.closeAndOpenPopup("submitAddressPoint");
            }
          });
      }
    },
    resetAddressPoint(addressPoint) {
      this.formData = addressPoint;
      this.selectedPoint = null;
      this.editMode = false;
    },
    getNomenclatures(
      item,
      countyId = null,
      shopProductId = null,
      shopProfileId = null,
      index = null
    ) {
      this.isLoading = true;

      let url = "type=" + item + "&per_page=200&page=1";

      if (item === "locality") {
        url += "&county=" + countyId;
      } else if (item === "shop_type") {
        url +=
          "&shop_product=" + shopProductId + "&shop_profile=" + shopProfileId;
      }

      httpServiceCommon.companyService
        .getNomenclature(url, Cookies.get("token"))
        .then(response => {
          if (response.status == 200 || response.status == 204) {
            if (item === "shop_type") {
              if (shopProductId === "15") {
                this.map
                  .get(item)
                  .get(shopProductId)
                  .set(shopProfileId, response.data);
              } else {
                this.map.get(item).set(shopProductId, response.data);
              }
            } else {
              this.map.set(item, response.data);
              this.mapIndex++;
            }
            this.isLoading = false;
            if (item === "locality") {
              this.locality = this.map.get("locality");
              this.selectedPoint.address.locality_obj = this.locality.find(
                x => x.id === this.selectedPoint.address.locality_id
              );
            }
          } else {
            // this.companyDataError = { description: response.status };
          }
        })
        .catch(error => {
          if (error.response) {
            this.isLoading = true;
            // this.companyDataError = { description: error.response.status };
          } else {
            // this.companyDataError = { description: "undefined" };
          }
        });
    },
    selectLocality(countyId) {
      this.getNomenclatures("locality", countyId);
    },
    getInvoiceAdresses() {
      httpServiceAffiliate.affiliateService
        .getInvoiceAddress(this.affiliate.company_id, Cookies.get("token"))
        .then(response => {
          if (response.status == 200 || response.status == 204) {
            this.invoiceAddresses = response.data;
            this.addressPoints.forEach(ad => {
              let obj = this.invoiceAddresses.find(
                i => i.id === ad.invoice_address_id
              );
              ad.invoice_address = obj.street_name + ", " + obj.locality;
            });
            this.$forceUpdate();
          } else {
            // this.companyDataError = { description: response.status };
          }
        })
        .catch(error => {
          if (error.response) {
            // this.companyDataError = { description: error.response.status };
          } else {
            // this.companyDataError = { description: "undefined" };
          }
        });
    },
    getContactPerson() {
      httpServiceAffiliate.affiliateService
        .getContactPerson(this.affiliate.company_id, Cookies.get("token"))
        .then(response => {
          if (response.status == 200 || response.status == 204) {
            this.contactPersons = response.data;
          } else {
            // this.companyDataError = { description: response.status };
          }
        })
        .catch(error => {
          if (error.response) {
            // this.companyDataError = { description: error.response.status };
          } else {
            // this.companyDataError = { description: "undefined" };
          }
        });
    },
    getShopType() {
      this.map.get("product").forEach(p => {
        if (p.id === 16) {
          this.map.get("shop_profile").forEach(sp => {
            this.getNomenclatures("shop_type", null, p.description, sp.id);
          });
        } else {
          this.getNomenclatures("shop_type", null, p.description, 0);
        }
      });
    },
    sendWorkingPoint() {},
    updateMultiSelect(param) {
      if (param === "function") {
        if (this.selectedPoint.person.person_obj) {
          this.selectedPoint.person.person_obj.function_obj = this.work.find(
            x => x.id === this.selectedPoint.person.person_obj.functionId
          );
        }
      }
      this.$forceUpdate();
    },
    closeAndOpenPopup(type) {
      if (type === "submitAddressPoint") {
        this.showModalPopup("modal_popup_loading", false);
        this.showModalPopup("modal_popup_info", true);
      }
    },
    onlyAlphabetCharacters(variable) {
      if (/^[a-zA-Z]*$/i.test(variable)) {
        return variable;
      } else {
        return variable.replace(/[0-9&\/\\|`#@\[\]\-,+=()$~%.'":*!?<>{}]/g, "");
      }
    },
    onlyAlphaNumericCharacters(variable) {
      if (/^[a-zA-Z0-9]*$/i.test(variable)) {
        return variable;
      } else {
        return variable.replace(/[&\/\\|`#\[\]\-,+=()@$~%'":*!?<>{}]/g, "");
      }
    },
    onlyAlphaNumericCharactersMail(variable) {
      if (/^[a-zA-Z0-9@.]*$/i.test(variable)) {
        return variable;
      } else {
        return variable.replace(/[&\/\\|`#\[\]\-,+=()$~%'":*!?<>{}]/g, "");
      }
    },
    onlyNumericCharacters(variable) {
      if (/^[0-9.]*$/i.test(variable)) {
        return variable;
      } else {
        return variable.replace(
          /[a-zA-Z&\/\\|`#@\[\]\-,+=()$~%'":*!?<>{}]/g,
          ""
        );
      }
    },
    updateInputCharacters() {
      if (this.selectedPoint.succursal.address.street_name) {
        this.selectedPoint.succursal.address.street_name = this.onlyAlphaNumericCharacters(
          this.selectedPoint.succursal.address.street_name
        )
          .normalize("NFD")
          .replace(/\p{Diacritic}/gu, "");
      }
      if (this.selectedPoint.succursal.invoice_email) {
        this.selectedPoint.succursal.invoice_email = this.onlyAlphaNumericCharactersMail(
          this.selectedPoint.succursal.invoice_email
        )
          .normalize("NFD")
          .replace(/\p{Diacritic}/gu, "")
          .trim("");
      }
      if (this.selectedPoint.succursal.address.gps_latitude) {
        this.selectedPoint.succursal.address.gps_latitude = this.onlyNumericCharacters(
          this.selectedPoint.succursal.address.gps_latitude
        ).trim("");
      }
      if (this.selectedPoint.succursal.address.gps_longitude) {
        this.selectedPoint.succursal.address.gps_longitude = this.onlyNumericCharacters(
          this.selectedPoint.succursal.address.gps_longitude
        ).trim("");
      }
      if (this.selectedPoint.person.person_obj.firstName) {
        this.selectedPoint.person.person_obj.firstName = this.onlyAlphabetCharacters(
          this.selectedPoint.person.person_obj.firstName
        )
          .normalize("NFD")
          .replace(/\p{Diacritic}/gu, "")
          .trim("");
      }
      if (this.selectedPoint.person.person_obj.lastName) {
        this.selectedPoint.person.person_obj.lastName = this.onlyAlphabetCharacters(
          this.selectedPoint.person.person_obj.lastName
        )
          .normalize("NFD")
          .replace(/\p{Diacritic}/gu, "")
          .trim("");
      }
      if (this.selectedPoint.person.person_obj.email1) {
        this.selectedPoint.person.person_obj.email1 = this.onlyAlphaNumericCharactersMail(
          this.selectedPoint.person.person_obj.email1
        )
          .normalize("NFD")
          .replace(/\p{Diacritic}/gu, "")
          .trim("");
      }
      if (this.selectedPoint.person.person_obj.mobile1) {
        this.selectedPoint.person.person_obj.mobile1 = this.onlyNumericCharacters(
          this.selectedPoint.person.person_obj.mobile1
        ).trim("");
      }
      this.$forceUpdate();
    },
    addNewPerson(e) {
      if (e) {
        this.selectedPoint.person.person_obj = {
          changed: false,
          email1: "",
          firstName: "",
          functionId: null,
          id: null,
          lastName: "",
          mobile1: "",
          phone1: "",
          new: true
        };
      } else {
        this.selectedPoint.person.person_obj = this.contactPersons.find(
          x => x.id === this.selectedPoint.person.id
        );
        if (
          this.selectedPoint.person.person_obj &&
          this.selectedPoint.person.person_obj.functionId
        ) {
          this.selectedPoint.person.person_obj.function_obj = this.work.find(
            x => x.id === this.selectedPoint.person.person_obj.functionId
          );
        }
      }
      this.$forceUpdate();
    }
  },
  created() {
    // this.selectProduct(0);
    let productMap = new Map();
    let storeSocialMap = new Map();
    this.map.set("shop_type", productMap);
    this.map.get("shop_type").set("15", storeSocialMap);

    this.getAddressPoints("both");
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  mounted() {
    this.token = Cookies.get("token");
    this.getInvoiceAdresses();
    this.getContactPerson();
    this.getNomenclatures("county");
    this.getNomenclatures("work");
    this.getNomenclatures("shop_profile");
    this.getNomenclatures("product");
  },
  computed: {
    filterWorkingPoints: function() {
      if (
        this.selectedWorkingPointAddress &&
        this.selectedWorkingPointAddress !== ""
      ) {
        const result = this.addressPoints.filter(
          x => x.address.id === this.selectedWorkingPointAddress.id
        );
        return result;
      } else {
        return this.addressPoints;
      }
    }
  },
  watch: {
    mapIndex: function() {
      if (
        this.map.get("shop_profile") &&
        this.map.get("product") &&
        this.map.get("shop_type").size === 1
      ) {
        this.getShopType();
      }
    },
    addNewPersonBool: function() {
      if (!this.selectedPoint.person.person_obj) {
        return false;
      } else {
        return true;
      }
    }
  }
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style src="@/assets/styles/multiselect.css"></style>
<style scoped src="@/assets/styles/beneficiary.css"></style>
<style scoped>
.margin-paragraph {
  margin: 1rem 0;
}
.margin-top-1rem {
  margin-top: 1rem;
}
.notification-action {
  display: inline-block;
  width: auto;
  padding: 10px;
}
</style>

<template>
  <div>
    <div class="delivery-title left" v-if="window.width > 1003">
      <h1>{{ $t("pos") }}</h1>
    </div>
    <br clear="all" />
    <div class="content-delivery mt-3">
      <div class="second-tabs p-0">
        <form
          @submit.prevent="
            {
              return false;
            }
          "
        >
          <p>
            <strong>{{ $t("workingPointSelectLabel") }}</strong>
          </p>
          <multiselect
            v-model="selectedWorkingPointAddress"
            :options="workingPointAddress"
            :placeholder="$t('selectLabel')"
            select-label=""
            selected-label=""
            deselect-label=""
            openDirection="bottom"
            track-by="codcom"
            :custom-label="customLabel"
            :loading="isLoading"
            @input="subsidiarySelected()"
          >
          </multiselect>
        </form>
      </div>
    </div>
    <div class="tabs-container">
      <div class="tabs">
        <a
          v-for="(entry, index) in cardProducts"
          :key="index"
          @click="selectProduct(index)"
          :class="entry.name === selectedProduct.name && 'active'"
        >
          <img
            :src="
              require('@/assets/images/logo-up-' +
                (entry.card ? entry.card : entry.paper) +
                '.png')
            "
            class="logo-product"
          />
        </a>
      </div>
    </div>
    <div align="center" class="select-left">
      <select class="mobile" @change="selectedProductMobile">
        <option
          v-for="(entry, index) in cardProducts"
          :key="index"
          :value="index"
          :selected="isSelectedProduct(entry)"
        >
          {{ entry.name }}
        </option>
      </select>
    </div>
    <div class="content-delivery">
      <div class="second-tabs">
        <b-container fluid class="border-box">
          <b-row class="border-box">
            <b-col class="border-box" xl="8" md="8">
              <h3 class="title-address-table">
                <strong>{{ $t("updatePOSLabel") }}</strong>
              </h3>
              <table
                cellpadding="10"
                cellspacing="0"
                width="95%"
                class="hover-table invoices-table"
                align="center"
              >
                <thead>
                  <tr>
                    <td valign="middle">
                      <strong>{{ $t("posBankLabel") }}</strong>
                    </td>
                    <td valign="middle">
                      <strong>{{ $t("posMIDLabel") }}</strong>
                    </td>
                    <td class="priority-1" valign="middle">
                      <strong>{{ $t("posChannelPayment") }}</strong>
                    </td>
                    <!-- <td class="priority-2" valign="middle">
                      <strong>{{ $t("posStatusLastTransaction") }}</strong>
                    </td> -->
                    <td
                      valign="middle"
                      v-if="
                        affiliate &&
                          affiliate.role !== 'FAC' &&
                          !affiliate.state_owned &&
                          affiliate.user_config &&
                          affiliate.user_config.roles &&
                          affiliate.user_config.roles.includes('EDITOR')
                      "
                    >
                      <strong>{{ $t("beneficiariesTableAction") }}</strong>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="this.listPOS === null">
                    <td colspan="6">
                      <div class="loading">
                        <img src="@/assets/images/loading.gif" />
                      </div>
                    </td>
                  </tr>
                  <template v-for="(pos, index) in listPOS">
                    <tr
                      :key="index"
                      class="trhover parent"
                      :class="index % 2 === 0 && 'gri-cell'"
                    >
                      <td valign="middle">
                        <a
                          class="
                            detailsOrderMobile
                            priority-show-2
                            display-inline-block
                          "
                          @click="selectPOSMobile(index)"
                        >
                          <font-awesome-icon
                            icon="minus-circle"
                            v-if="detailsMobile && pos === posSelectedMobile"
                          />
                          <font-awesome-icon
                            icon="plus-circle"
                            v-else
                          />&nbsp;&nbsp;
                        </a>
                        <strong>{{ pos.bank_name }}</strong>
                      </td>
                      <td valign="middle">
                        {{ pos.mid }}
                      </td>
                      <td valign="middle" class="priority-1">
                        {{ convertChannelPaymentToText(pos.payment_method) }}
                      </td>
                      <!-- <td valign="middle" class="priority-2"> -->
                      <!-- {{ pos.status }} -->
                      <!-- </td> -->
                      <td
                        valign="middle"
                        v-if="
                          affiliate &&
                            affiliate.role !== 'FAC' &&
                            !affiliate.state_owned &&
                            affiliate.user_config &&
                            affiliate.user_config.roles &&
                            affiliate.user_config.roles.includes('EDITOR')
                        "
                      >
                        <a
                          class="action-button mb-1"
                          @click="selectedPOSFromList(pos, 'delete')"
                        >
                          <span>{{ $t("deletePOSLabel") }}</span>
                        </a>
                        &nbsp;&nbsp;
                        <a
                          class="action-button mb-1"
                          @click="selectedPOSFromList(pos, 'modify')"
                        >
                          <span>{{ $t("modifyPOSLabel") }}</span>
                        </a>
                        &nbsp;&nbsp;
                      </td>
                    </tr>
                    <tr
                      :key="index + listPOS.length"
                      class="child"
                      :class="index % 2 === 0 && 'gri-cell'"
                      v-show="detailsMobile && pos === posSelectedMobile"
                    >
                      <td colspan="12">
                        <p class="priority-show-1">
                          <strong
                            >{{ $t("posChannelPayment") }} :
                            &nbsp;&nbsp;</strong
                          >
                          <span>{{
                            convertChannelPaymentToText(pos.payment_method)
                          }}</span>
                        </p>
                        <p class="priority-show-2">
                          <strong
                            >{{ $t("posStatusLastTransaction") }} :
                            &nbsp;&nbsp;</strong
                          >
                          <span>{{ pos.status }}</span>
                        </p>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </b-col>
            <b-col class="border-box" xl="4" md="4">
              <h3 class="title-address-table">
                <strong>{{ $t("addPOSLabel") }}</strong>
              </h3>
              <form
                @submit.prevent="
                  {
                    return false;
                  }
                "
                v-if="
                  affiliate &&
                    affiliate.role !== 'FAC' &&
                    !affiliate.state_owned &&
                    affiliate.user_config &&
                    affiliate.user_config.roles &&
                    affiliate.user_config.roles.includes('EDITOR')
                "
              >
                <p>
                  <small>{{ $t("posBankLabel") }}</small>
                  <br />
                  <select class="w-100" @change="selectBank" v-model="posBank">
                    <option
                      v-for="(bank, index) in this.listBank"
                      :key="index"
                      :value="bank.id"
                      :selected="posBank === bank"
                    >
                      {{ bank.value }}
                    </option>
                  </select>
                </p>
                <p>
                  <small>{{ $t("posMIDLabel") }}</small>
                  <br />
                  <input
                    type="text"
                    v-model="posMID"
                    maxlength="15"
                    class="formedituser"
                    @keyup="updateInputCharacters()"
                  />
                </p>
                <p>
                  <small>{{ $t("posChannelPayment") }}</small>
                  <br />
                  <select
                    class="w-100"
                    @change="selectChannel"
                    v-model="posChannel"
                  >
                    <option
                      v-for="(channel, index) in this.channelList"
                      :key="index"
                      :value="channel.id"
                      :selected="posChannel === channel"
                    >
                      {{ channel.name }}
                    </option>
                  </select>
                </p>
                <p>
                  <small>{{ $t("addImagePOS") }}</small>
                  <br />
                  <b-form-file
                    v-model="fileReceived"
                    ref="file"
                    accept=".jpg, .jpeg"
                    :placeholder="[[$t('uploadFileLabel')]]"
                    @input="uploadFile"
                  ></b-form-file>
                </p>
                <p class="culoare-13" v-if="fileReceivedInvaliFormat">
                  {{ $t("invalidFormatFilePOS") }}
                </p>
                <p>
                  <b-form-checkbox
                    id="allProductsC"
                    v-model="allProductsC"
                    name="allProductsC"
                  >
                    <span>{{ $t("addPosAllProductsLabel") }}</span>
                  </b-form-checkbox>
                </p>
                <p>
                  <a class="button-orange m-0" @click="addNewTerminal()">{{
                    $t("posTextButton")
                  }}</a>
                </p>
              </form>
              <div v-else>
                <div v-if="!user.username.includes('@')">
                  <p>
                    {{ $t("posNoMandateText") }}
                  </p>
                  <a class="button-orange m-0" :href="'/affiliate/account'">{{
                    $t("posNoMandateTextButton")
                  }}</a>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </div>
    <div id="modal_popup_modifyPOS" class="modal-popup">
      <div class="modal-popup-content">
        <a
          @click="showModalPopup('modal_popup_modifyPOS', false)"
          class="close-button"
        >
          <img src="@/assets/images/close-button.svg" />
        </a>
        <h3>{{ $t("modifyPOSPopupLabel") }}</h3>
        <p>
          <strong>{{ $t("posBankLabel") }}</strong>
          <br />
          <span>{{ posBankModify }}</span>
        </p>
        <p>
          <strong>{{ $t("posMIDLabel") }}</strong>
          <br />
          <input
            type="text"
            v-model="posMIDModify"
            maxlength="15"
            class="formedituser"
            @keyup="updateInputCharacters()"
          />
        </p>
        <p>
          <strong>{{ $t("posChannelPayment") }}</strong>
          <br />
          <select class="w-100" v-model="posChannelModify">
            <option
              v-for="(channel, index) in this.channelList"
              :key="index"
              :value="channel.id"
              :selected="posChannelModify === channel"
            >
              {{ channel.name }}
            </option>
          </select>
        </p>
        <p>
          <strong>{{ $t("posProductsLabel") }}</strong>
          <br />
          <br />
          <b-form-checkbox
            id="onlyOneProduct"
            v-model="onlyOneProduct"
            name="onlyOneProduct"
          >
            <span>{{
              $t("modifyOnlySelectedProductLabel", {
                card: selectedProduct.name
              })
            }}</span>
          </b-form-checkbox>
          <b-form-checkbox
            id="allProductsU"
            v-model="allProductsU"
            name="allProductsU"
          >
            <span>{{ $t("deliveryPointConfirmData") }}</span>
          </b-form-checkbox>
        </p>
        <p>
          <a
            class="button-white"
            @click="showModalPopup('modal_popup_modifyPOS', false)"
          >
            {{ $t("cancelModifyLabel") }}
          </a>
          <a class="button-orange float-right" @click="modifyTerminal()">{{
            $t("saveChangesLabel")
          }}</a>
        </p>
      </div>
    </div>
    <div id="modal_popup_deletePOS" class="modal-popup">
      <div class="modal-popup-content">
        <a
          @click="showModalPopup('modal_popup_deletePOS', false)"
          class="close-button"
        >
          <img src="@/assets/images/close-button.svg" />
        </a>
        <p>
          <span>{{ $t("confirmDeleteTerminalMsg") }}</span>
          <br /><br />
          <b-form-checkbox
            id="allProductsD"
            v-model="allProductsD"
            name="allProductsD"
          >
            <span>{{ $t("deliveryPointConfirmData") }}</span>
          </b-form-checkbox>
        </p>
        <div class="buttons">
          <a href="#" class="yesButton" @click="deleteTerminal()">{{
            $t("yesText")
          }}</a>
          <a
            href="#"
            class="noButton"
            @click="showModalPopup('modal_popup_deletePOS', false)"
            >{{ $t("noText") }}</a
          >
        </div>
      </div>
    </div>
    <div id="modal_popup_info" class="modal-popup">
      <div class="modal-popup-content">
        <a
          @click="showModalPopup('modal_popup_info', false)"
          class="close-button"
        >
          <img src="@/assets/images/close-button.svg" />
        </a>
        <p v-if="this.sentPOS">
          {{ $t("posAddSuccess") }}
        </p>
        <p v-if="this.modifyPOS">
          {{ $t("posModifySuccess") }}
        </p>
        <p v-if="this.deletedPOS">
          {{ $t("posDeletedSuccess") }}
        </p>
        <p v-if="this.sentPOSError">
          {{ $t("posError_" + this.sentPOSError.code) }}
        </p>
        <p v-if="this.deletedPOSError">
          {{ $t("posError_" + this.deletedPOSError.code) }}
        </p>
      </div>
    </div>
    <div id="modal_popup_loading" class="modal-popup">
      <div class="modal-popup-content">
        <img src="@/assets/images/loading.gif" />
        <p v-html="$t('loadingPopupMessage')"></p>
      </div>
    </div>
  </div>
</template>

<script>
import httpServiceUser from "@/http/http-user.js";
import httpServiceAffiliate from "@/http/http-affiliate.js";
import httpServiceCommon from "@/http/http-common.js";
import Cookies from "js-cookie";
import {
  BContainer,
  BRow,
  BCol,
  BFormFile,
  BFormCheckbox
} from "bootstrap-vue";
import Multiselect from "vue-multiselect";

export default {
  props: {
    user: Object,
    affiliate: Object,
    msg: String
  },
  components: {
    "b-container": BContainer,
    "b-row": BRow,
    "b-col": BCol,
    "b-form-file": BFormFile,
    "b-form-checkbox": BFormCheckbox,
    Multiselect
  },
  data() {
    return {
      selectedProduct: null,
      addressPoints: [],
      environment: null,
      window: {
        width: 0,
        height: 0
      },
      workingPointAddress: [],
      selectedWorkingPointAddress: "",
      posSelectedMobile: null,
      detailsMobile: false,
      posBank: null,
      posMID: null,
      posChannel: null,
      posBankModify: null,
      posMIDModify: null,
      posChannelModify: null,
      posProductsModify: null,
      channelList: [
        {
          id: "1",
          name: "Offline"
        },
        {
          id: "3",
          name: "Online/Offline"
        }
      ],
      imagePOS: null,
      fileReceived: null,
      sentPOS: false,
      modifyPOS: false,
      deletedPOS: false,
      sentPOSError: null,
      deletedPOSError: null,
      listBank: null,
      listBankError: null,
      listPOS: null,
      listPOSError: null,
      filters: {
        product: 11,
        subsidiary: "A"
      },
      selectedPOS: "",

      perPage: 1000,
      currentPage: 1,
      isLoading: false,
      fileReceivedInvaliFormat: false,
      allProductsC: false,
      allProductsU: false,
      allProductsD: false,
      onlyOneProduct: true
    };
  },
  methods: {
    selectPOSMobile(index) {
      this.posSelectedMobile = this.listPOS[index];
      this.detailsMobile = !this.detailsMobile;
    },
    selectProduct(index) {
      this.selectedProduct = this.cardProducts[index];
      this.filters.product = this.selectedProduct.card;
      this.getAddressPoints("card", this.selectedProduct.brand);
      // this.selectedWorkingPointAddress = this.workingPointAddress[0];
    },
    isSelectedProduct(product) {
      return product + "" === this.selectedProduct;
    },
    selectedProductMobile(event) {
      this.selectedProduct = this.cardProducts[event.target.value];
      this.getAddressPoints("card", this.selectedProduct.brand);
    },
    getAddressPoints(env, brand) {
      this.environment = env;
      this.isLoading = true;
      var params =
        "?type=affiliate" +
        "&brand=" +
        brand +
        "&environment=" +
        this.environment +
        "&per_page=10000&page=1";
      if (this.affiliate.user_config && this.affiliate.user_config.config) {
        if (this.affiliate.user_config.config.invoice_group) {
          params += "&invoice_group=1";
        }
      }
      httpServiceUser.userService
        .getAddressPoints(
          this.affiliate.company_id,
          params,
          Cookies.get("token")
        )
        .then(response => {
          if (response.status == 200) {
            this.addressPoints = response.data;
            this.isLoading = false;
            this.workingPointAddress = [];
            this.addressPoints.forEach(x => {
              this.workingPointAddress.push({
                codcom: x.codcom,
                address: x.address.street_name + ", " + x.address.locality,
                wppId: x.productsAffiliate[0].wpp_id
              });
            });
            this.selectedWorkingPointAddress = this.workingPointAddress[0];
            this.filters.subsidiary = this.selectedWorkingPointAddress.wppId;
            this.getAllTerminals();
            this.showModalPopup("modal_popup_loading", false);
          } else if (response.status == 400 || response.status == 403) {
            this.showModalPopup("modal_popup_loading", false);
            return null;
          } else {
            this.showModalPopup("modal_popup_loading", false);
            return null;
          }
        })
        .catch(error => {
          this.showModalPopup("modal_popup_loading", false);
          return null;
        });
    },
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
    showModalPopup(elementId, show) {
      if (show) {
        document.getElementById(elementId).style.visibility = "visible";
      } else {
        document.getElementById(elementId).style.visibility = "hidden";
      }
    },
    selectChannel(event) {
      this.posChannel = event.target.value;
    },
    selectBank(event) {
      this.posBank = event.target.value;
    },
    subsidiarySelected() {
      if (
        this.selectedWorkingPointAddress === "" ||
        !this.selectedWorkingPointAddress
      ) {
        this.filters.subsidiary = "A";
      } else {
        this.filters.subsidiary = this.selectedWorkingPointAddress.wppId;
      }
      this.getAllTerminals();
    },
    getAllBank() {
      var formData = "?per_page=" + this.perPage + "&page=" + this.currentPage;

      httpServiceAffiliate.affiliateService
        .getAllBank(formData, Cookies.get("token"))
        .then(function(response) {
          return response;
        })
        .then(response => {
          if (response.status == 200) {
            this.listBank = response.data;
          } else if (response.status == 400) {
            this.listBankError = { code: response.data.code };
          } else {
            this.listBankError = { code: response.code };
          }
        })
        .catch(error => {
          this.listBankError = { code: "500" };
        });
    },
    getAllTerminals() {
      this.listPOS = null;
      var formData = "?per_page=" + this.perPage + "&page=" + this.currentPage;

      formData += "&brand=" + this.selectedProduct.brand;

      if (this.filters.subsidiary !== "A") {
        formData += "&address=" + this.selectedWorkingPointAddress.wppId;
      }

      httpServiceAffiliate.affiliateService
        .getAllTerminals(
          this.affiliate.company_id,
          formData,
          Cookies.get("token")
        )
        .then(function(response) {
          return response;
        })
        .then(response => {
          if (response.status == 200) {
            this.listPOS = response.data;
          } else if (response.status == 400) {
            this.listPOSError = { code: response.data.code };
          } else {
            this.listPOSError = { code: response.code };
          }
        })
        .catch(error => {
          this.listPOSError = { code: "500" };
        });
    },
    convertChannelPaymentToText(channel) {
      var text = "";
      switch (channel) {
        case "1":
          text = "Offline";
          break;
        case "2":
          text = "Online";
          break;
        case "3":
          text = "Online/Offline";
          break;
        default:
          text = "";
          break;
      }
      return text;
    },
    resetAddTerminalForm() {
      this.posBank = null;
      this.posMID = null;
      this.posChannel = null;
      this.imagePOS = null;
    },
    uploadFile() {
      if (this.fileReceived.type !== "image/jpeg") {
        this.fileReceivedInvaliFormat = true;
        return;
      } else {
        this.fileReceivedInvaliFormat = false;
      }
      var reader = new FileReader();
      reader.onload = e => {
        if (!e) {
          this.imagePOS = btoa(reader.content);
        } else {
          this.imagePOS = btoa(e.target.result);
        }
      };
      //For Browsers other than IE.
      if (reader.readAsBinaryString) {
        reader.readAsBinaryString(this.fileReceived);
      } else {
        alert("This browser does not support HTML5.");
      }

      if (!this.fileReceived) {
        return;
      }
      // */
    },
    addNewTerminal() {
      if (!this.posBank) {
        this.sentPOSError = { code: "NO_BANK" };
        this.showModalPopup("modal_popup_info", true);
        return;
      } else if (!this.posMID) {
        this.sentPOSError = { code: "NO_MID" };
        this.showModalPopup("modal_popup_info", true);
        return;
      } else if (!this.posChannel) {
        this.sentPOSError = { code: "NO_CHANNEL" };
        this.showModalPopup("modal_popup_info", true);
        return;
      } else if (this.fileReceivedInvaliFormat) {
        this.sentPOSError = { code: "NO_VALID_FORMAT" };
        this.showModalPopup("modal_popup_info", true);
        return;
      } else if (
        !this.selectedWorkingPointAddress ||
        this.selectedWorkingPointAddress === ""
      ) {
        this.sentPOSError = { code: "NO_ADDRESS" };
        this.showModalPopup("modal_popup_info", true);
        return;
      }
      var formData = {
        actionType: "C",
        cascadeAction: this.allProductsC,
        bidId: this.posBank,
        mid: this.posMID,
        posTrans: true,
        onlineTrans: this.posChannel === "3" ? true : false,
        wppId: this.selectedWorkingPointAddress.wppId, // daca nu are adresa selectata ii dam eroare
        // product: this.selectedProduct.card,
        image: this.imagePOS,
        societyCodall: this.affiliate.company_id
      };
      this.sentPOSError = null;
      this.sentPOS = false;
      this.modifyPOS = false;
      this.deletedPOS = false;
      this.deletedPOSError = null;

      this.showModalPopup("modal_popup_loading", true);

      httpServiceAffiliate.affiliateService
        .addNewTerminal(
          this.affiliate.company_id,
          formData,
          Cookies.get("token")
        )
        .then(function(response) {
          return response;
        })
        .then(response => {
          if (response.status == 200) {
            this.sentPOS = true;
            this.sentPOSError = null;
            this.showModalPopup("modal_popup_loading", false);
            this.showModalPopup("modal_popup_info", true);
            this.getAllTerminals();
            this.resetAddTerminalForm();
          } else if (response.status == 400) {
            this.sentPOSError = { code: response.data.code };
            this.showModalPopup("modal_popup_loading", false);
            this.showModalPopup("modal_popup_info", true);
          } else {
            this.sentPOSError = { code: response.code };
            this.showModalPopup("modal_popup_loading", false);
            this.showModalPopup("modal_popup_info", true);
          }
        })
        .catch(error => {
          this.sentPOSError = { code: "500" };
          this.showModalPopup("modal_popup_loading", false);
          this.showModalPopup("modal_popup_info", true);
        });
    },
    modifyTerminal() {
      if (
        !this.selectedWorkingPointAddress ||
        this.selectedWorkingPointAddress === ""
      ) {
        this.sentPOSError = { code: "NO_ADDRESS" };
        this.showModalPopup("modal_popup_info", true);
        return;
      }

      this.sentPOSError = null;
      this.sentPOS = false;
      this.modifyPOS = false;
      this.deletedPOS = false;
      this.deletedPOSError = null;
      this.selectedPOS.mid = this.posMIDModify;
      this.selectedPOS.payment_method = this.posChannelModify;

      var formData = {
        id: this.selectedPOS.id,
        actionType: "U",
        cascadeAction: this.allProductsU,
        bidId: this.selectedPOS.bank_id,
        mid: this.selectedPOS.mid,
        posTrans: true,
        onlineTrans: this.selectedPOS.payment_method === "3" ? true : false,
        wppId: this.selectedWorkingPointAddress.wppId,
        societyCodall: this.affiliate.company_id
      };

      this.showModalPopup("modal_popup_modifyPOS", false);
      this.showModalPopup("modal_popup_loading", true);

      httpServiceAffiliate.affiliateService
        .addNewTerminal(
          this.affiliate.company_id,
          formData,
          Cookies.get("token")
        )
        .then(function(response) {
          return response;
        })
        .then(response => {
          if (response.status == 200) {
            this.modifyPOS = true;
            this.sentPOSError = null;
            this.getAllTerminals();
            this.showModalPopup("modal_popup_loading", false);
            this.showModalPopup("modal_popup_info", true);
          } else if (response.status == 400) {
            this.sentPOSError = { code: response.data.code };
            this.showModalPopup("modal_popup_loading", false);
            this.showModalPopup("modal_popup_info", true);
          } else {
            this.sentPOSError = { code: response.code };
            this.showModalPopup("modal_popup_loading", false);
            this.showModalPopup("modal_popup_info", true);
          }
        })
        .catch(error => {
          this.sentPOSError = { code: "500" };
          this.showModalPopup("modal_popup_loading", false);
          this.showModalPopup("modal_popup_info", true);
        });
    },
    deleteTerminal() {
      if (
        !this.selectedWorkingPointAddress ||
        this.selectedWorkingPointAddress === ""
      ) {
        this.sentPOSError = { code: "NO_ADDRESS" };
        this.showModalPopup("modal_popup_info", true);
        return;
      }

      this.deletedPOSError = null;
      this.sentPOSError = null;
      this.sentPOS = false;
      this.modifyPOS = false;
      this.deletedPOS = false;

      var formData = {
        id: this.selectedPOS.id,
        actionType: "D",
        cascadeAction: this.allProductsD,
        wppId: this.selectedWorkingPointAddress.wppId,
        societyCodall: this.affiliate.company_id
      };

      this.showModalPopup("modal_popup_deletePOS", false);
      this.showModalPopup("modal_popup_loading", true);

      httpServiceAffiliate.affiliateService
        .addNewTerminal(
          this.affiliate.company_id,
          formData,
          Cookies.get("token")
        )
        .then(function(response) {
          return response;
        })
        .then(response => {
          if (response.status == 200) {
            this.deletedPOS = true;
            this.deletedPOSError = null;
            this.getAllTerminals();
            this.showModalPopup("modal_popup_loading", false);
            this.showModalPopup("modal_popup_info", true);
          } else if (response.status == 400) {
            this.deletedPOSError = { code: response.data.code };
            this.showModalPopup("modal_popup_loading", false);
            this.showModalPopup("modal_popup_info", true);
          } else {
            this.deletedPOSError = { code: response.code };
            this.showModalPopup("modal_popup_loading", false);
            this.showModalPopup("modal_popup_info", true);
          }
        })
        .catch(error => {
          this.deletedPOSError = { code: "500" };
          this.showModalPopup("modal_popup_loading", false);
          this.showModalPopup("modal_popup_info", true);
        });
    },
    selectedPOSFromList(pos, action) {
      this.selectedPOS = pos;
      if (action === "modify") {
        this.showModalPopup("modal_popup_modifyPOS", true);
        this.posBankModify = this.selectedPOS.bank_name;
        this.posMIDModify = this.selectedPOS.mid;
        this.posChannelModify = this.selectedPOS.payment_method;
        this.posProductsModify = this.selectedPOS.product;
      } else {
        this.showModalPopup("modal_popup_deletePOS", true);
      }
    },
    customLabel({ codcom, address }) {
      return `${codcom} – ${address}`;
    },
    getNomenclatures(item) {
      let url = "type=" + item + "&per_page=1000&page=1";

      httpServiceCommon.companyService
        .getNomenclature(url, Cookies.get("token"))
        .then(response => {
          if (response.status == 200 || response.status == 204) {
            this.listBank = response.data;
          } else {
            this.listBankError = { code: response.data.code };
          }
          // this.listBankError = { code: response.code };
        })
        .catch(error => {
          if (error.response) {
            this.listBankError = { code: "500" };
          } else {
            this.listBankError = { code: "500" };
          }
        });
    },
    onlyAlphaNumericCharacters(variable) {
      if (/^[a-zA-Z0-9]*$/i.test(variable)) {
        return variable;
      } else {
        return variable.replace(/[&\/\\|`#\[\]\-,+=()$~%'":*!?<>{}]/g, "");
      }
    },
    updateInputCharacters() {
      if (this.posMID) {
        this.posMID = this.onlyAlphaNumericCharacters(this.posMID)
          .normalize("NFD")
          .replace(/\p{Diacritic}/gu, "")
          .trim("");
      }
      if (this.posMIDModify) {
        this.posMIDModify = this.onlyAlphaNumericCharacters(this.posMIDModify)
          .normalize("NFD")
          .replace(/\p{Diacritic}/gu, "")
          .trim("");
      }
      this.$forceUpdate();
    }
  },
  created() {
    this.selectProduct(0);
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  mounted() {
    // this.getAllBank();
    this.getNomenclatures("bank");
    // this.getAllTerminals();
  },
  computed: {
    filterWorkingPoints: function() {
      if (this.selectedWorkingPointAddress !== "") {
        const result = this.addressPoints.filter(
          x => x.codcom === this.selectedWorkingPointAddress.codcom
        );
        return result;
      } else {
        return this.addressPoints;
      }
    },
    cardProducts() {
      let cardProducts = [];
      if (this.affiliate.products) {
        for (let index in this.affiliate.products) {
          if (
            this.affiliate.products[index].card > 0 &&
            this.affiliate.products[index].card !== 15
          ) {
            cardProducts.push(this.affiliate.products[index]);
          }
        }
      }
      return cardProducts;
    }
  }
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped src="@/assets/styles/beneficiary.css"></style>
<style scoped>
#modal_popup_modifyPOS .modal-popup-content > p {
  border-bottom: 0 none;
}

#modal_popup_modifyPOS .modal-popup-content {
  text-align: left;
}
</style>

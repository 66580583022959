<template>
  <div>
    <table
      cellpadding="10"
      cellspacing="0"
      width="95%"
      class="hover-table invoices-table"
      align="center"
    >
      <thead>
        <tr>
          <td valign="middle">
            <strong>{{ $t("reimbursementHeaderID") }}</strong
            >&nbsp;
          </td>
          <td valign="middle">
            <strong>{{ $t("reimbursementHeaderStatus") }}</strong
            >&nbsp;
          </td>
          <td class="priority-1" valign="middle">
            <strong>{{ $t("reimbursementHeaderDate") }}</strong
            >&nbsp;
          </td>
          <td class="priority-2" valign="middle">
            <strong>{{ $t("reimbursementHeaderValue") }}</strong
            >&nbsp;
          </td>
          <td valign="middle"></td>
        </tr>
      </thead>
      <tbody>
        <tr
          v-if="
            this.reimbursements === null || this.reimbursements === undefined
          "
        >
          <td colspan="6">
            <div class="loading">
              <img src="@/assets/images/loading.gif" />
            </div>
          </td>
        </tr>
        <template v-for="(reimbursement, index) in reimbursements">
          <tr
            :key="index"
            class="trhover parent"
            :class="index % 2 === 0 && 'gri-cell'"
          >
            <td valign="middle">
              <a
                class="detailsOrderMobile priority-show-5 display-inline-block"
                @click="selectReimbursementMobile(index)"
              >
                <font-awesome-icon
                  icon="minus-circle"
                  v-if="
                    detailsMobile &&
                      reimbursement === reimbursementSelectedMobile
                  "
                />
                <font-awesome-icon icon="plus-circle" v-else />&nbsp;&nbsp;
              </a>
              <strong>{{ reimbursement.tally_sheet.tallysheet_id }}</strong>
            </td>
            <td valign="middle">
              {{
                $t(
                  "reimbursementStatus_" +
                    reimbursement.tally_sheet.states[0].state_id
                )
              }}
            </td>
            <td valign="middle" class="priority-1">
              {{ displayDate(reimbursement.tally_sheet.creation_date) }}
            </td>
            <td valign="middle" class="priority-2">
              {{ reimbursement.tally_sheet.total_value }} {{ $t("currency") }}
            </td>
            <td valign="middle">
              <a
                class="action-button"
                @click="selectReimbursementAction(reimbursement, 'details')"
              >
                <span>{{ $t("details") }}</span>
              </a>
            </td>
          </tr>
          <tr
            :key="index + reimbursements.length"
            class="child"
            :class="index % 2 === 0 && 'gri-cell'"
            v-show="
              detailsMobile && reimbursement === reimbursementSelectedMobile
            "
          >
            <td colspan="12">
              <p class="priority-show-1">
                <strong
                  >{{ $t("reimbursementHeaderDate") }} : &nbsp;&nbsp;</strong
                >
                <span>{{
                  displayDate(reimbursement.tally_sheet.creation_date)
                }}</span>
              </p>
              <p class="priority-show-2">
                <strong
                  >{{ $t("reimbursementHeaderValue") }} : &nbsp;&nbsp;</strong
                >
                <span
                  >{{ reimbursement.tally_sheet.total_value }}
                  {{ $t("currency") }}</span
                >
              </p>
            </td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>
<script>
import utils from "@/plugins/utils.js";
import moment from "moment";

export default {
  name: "ReimbursementsTable",
  props: {
    reimbursements: Array
  },
  data() {
    return {
      detailsMobile: false,
      reimbursementSelectedMobile: null,
      sortByField: "date",
      reverse: false
    };
  },
  methods: {
    displayDate(date) {
      return moment(new Date(date), "YYYY-MM-DDThh:mm:ss").format("DD/MM/YYYY");
    },
    selectReimbursementMobile(index) {
      this.reimbursementSelectedMobile = this.reimbursements[index];
      this.detailsMobile = !this.detailsMobile;
    },
    sortBy(fieldName) {
      if (this.sortByField !== fieldName) {
        this.reverse = false;
      }
      this.sortByField = fieldName;
      this.reverse = !this.reverse;
      this.$emit("change", fieldName, this.reverse ? "asc" : "desc");
    },
    selectReimbursementAction(reimbursement, action) {
      this.$emit("click", reimbursement, action);
    }
  }
};
</script>
<style scoped src="@/assets/styles/beneficiary.css"></style>

<template>
  <div>
    <div class="account-title">
      <h1>{{ $t("offersLabel") }}</h1>
    </div>
    <div v-for="x in 3" :key="x" class="offers">
      <div class="offer d-flex align-items-center flex-column flex-md-row">
        <div class="w-md-40 order-md-2 mb-md-0 mb-3">
          <img
            class="w-100"
            src="https://app.uponline.ro/img/affiliate/offer_2/bestsmart.jpg"
            alt=""
          />
        </div>
        <div class="w-md-60 order-md-1">
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            Pellentesque sit amet laoreet justo. Ut ac purus dapibus, varius
            justo ut, gravida tellus. Donec molestie velit ut quam facilisis
            ornare. Praesent tincidunt ligula nisl, ultrices porta lacus
            ultricies in. Maecenas nunc velit, volutpat in nisl a, semper cursus
            eros.
          </p>
          <br />
          <a 
          @click="pushRoute('affiliate_offers')"
           class="notification-action cursor-pointer">{{
            $t("addOfferButtonLabel")
          }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import httpServiceUser from "@/http/http-user.js";
import $ from "jquery";

export default {
  components: {
  },
  props: {
    companyDetails: Object,
    user: Object
  },
  data() {
    return {
      companyDataError: null,
      editMode: false,
      formData: null,
      usersAPI: [
        {
          email: "alvaradobright1@memora.com",
          role: 2,
          rights: ""
        },
        {
          email: "alvaradobright2@memora.com",
          role: 2,
          rights: ""
        },
        {
          email: "alvaradobright3@memora.com",
          role: 1,
          rights: ""
        },
        {
          email: "alvaradobright4@memora.com",
          role: 2,
          rights: ""
        },
        {
          email: "alvaradobright5@memora.com",
          role: 1,
          rights: ""
        }
      ],
      roles: [
        {
          role: 1
        },
        {
          role: 2
        }
      ],
      detailsMobile: false,
      userSelectedMobile: null,
      selectedUserForChangeRole: null,
      beforeSelectedUserForChangeRole: null,
      selectedRole: null,
      newUser: {
        email: "",
        role: 1
      }
    };
  },
  methods: {
    selectUserMobile(index) {
      this.userSelectedMobile = this.usersAPI[index];
      this.detailsMobile = !this.detailsMobile;
    },
    openModalChangeRole(event, user) {
      this.selectedUserForChangeRole = user;
      this.selectedRole = event;
      this.showModalPopup("modal_popup_confirm", true);
    },
    changeRole() {},
    noChangeRole() {
      this.usersAPI.map(u => {
        if (u.email === this.beforeSelectedUserForChangeRole.email) {
          u.role = this.beforeSelectedUserForChangeRole.role;
        }
      });
      this.showModalPopup("modal_popup_confirm", false);
    },
    beforeUserChangeRole(user) {
      this.beforeSelectedUserForChangeRole = user;
      // this.$forceUpdate();
    },
    showModalPopup(elementId, show) {
      if (show) {
        document.getElementById(elementId).style.visibility = "visible";
      } else {
        document.getElementById(elementId).style.visibility = "hidden";
      }
    },
    editData() {
      this.editMode = true;
      $("#first_name").prop("disabled", false);
      $("#last_name").prop("disabled", false);
      $("#email").prop("disabled", false);
      $("#mobile_phone").prop("disabled", false);
    },
    saveNewData() {
      httpServiceUser.userService
        .confirmUserData(
          this.url,
          {
            id: this.companyDetails.company_id,
            current_password: this.currentPassword,
            email: this.user.email
          },
          this.token
        )
        .then(function(response) {
          return response;
        })
        .then(response => {
          if (response.status == 200) {
            this.companyDataError = null;
            this.currentPassword = "";
            this.editMode = false;
            $("#email").prop("disabled", true);
            this.showModalPopup("modal_popup_changeData", false);
            this.showModalPopup("modal_popup_info", true);
            window.location.reload();
          } else if (response.status == 400) {
            this.companyDataError = response.data;
            this.editMode = false;
            $("#email").prop("disabled", true);
            this.showModalPopup("modal_popup_changeData", false);
            this.showModalPopup("modal_popup_info", true);
            window.location.reload();
          } else {
            this.companyDataError = { description: response.code };
            this.editMode = false;
            $("#email").prop("disabled", true);
            this.showModalPopup("modal_popup_changeData", false);
            this.showModalPopup("modal_popup_info", true);
            window.location.reload();
          }
        })
        .catch(error => {
          if (error.response) {
            this.companyDataError = error.response.data;
          } else {
            this.companyDataError = this.$t("errorMessage_undefined");
          }
          this.editMode = false;
          $("#email").prop("disabled", true);
          this.showModalPopup("modal_popup_changeData", false);
          this.showModalPopup("modal_popup_info", true);
          window.location.reload();
        });
    },
    resetData() {
      this.formData = {
        lastName: this.user.last_name,
        firstName: this.user.first_name,
        email: this.user.email,
        mobilePhone: this.user.mobile_phone
      };
      this.editMode = false;
      $("#first_name").prop("disabled", true);
      $("#last_name").prop("disabled", true);
      $("#email").prop("disabled", true);
      $("#mobile_phone").prop("disabled", true);
    },
    onlyAlphabetCharacters(variable) {
      if (/^[a-zA-Z]*$/i.test(variable)) {
        return variable;
      } else {
        return variable.replace(/[0-9&\/\\|`#@\[\]\-,+=()$~%.'":*!?<>{}]/g, "");
      }
    },
    onlyNumericCharacters(variable) {
      if (/^[0-9]*$/i.test(variable)) {
        return variable;
      } else {
        return variable.replace(
          /[a-zA-Z&\/\\|`#@\[\]\-,+=()$~%.'":*!?<>{}]/g,
          ""
        );
      }
      return variable;
    },
    onlyAlphaNumericCharacters(variable) {
      if (/^[a-zA-Z0-9@.]*$/i.test(variable)) {
        return variable;
      } else {
        return variable.replace(/[&\/\\|`#\[\]\-,+=()$~%'":*!?<>{}]/g, "");
      }
    },
    updateInputCharacters() {
      this.formData.lastName = this.onlyAlphabetCharacters(
        this.formData.lastName
      );
      this.formData.firstName = this.onlyAlphabetCharacters(
        this.formData.firstName
      );
      this.formData.mobilePhone = this.onlyNumericCharacters(
        this.formData.mobilePhone
      );
      this.formData.email = this.onlyAlphaNumericCharacters(
        this.formData.email
      );
      this.$forceUpdate();
    },
    addNewUser() {}
  },
  mounted() {},
  computed: {},
  created() {
    this.resetData();
  }
};
</script>
<style scoped src="@/assets/styles/beneficiary.css"></style>
<style scoped>
.offers {
  margin: 0 auto 20px;
  background: #fff;
  border-radius: 5px;
  border-bottom: 2px solid #ddd;
  padding: 20px;
}
.offer {
  /* padding: 10px;
  border: 1px solid #ddd;
  border-radius: 10px; */
}
.container-alb p {
  margin: 0 0 1rem;
}
</style>

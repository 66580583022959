export default [
	{
		electronic: [
			{
				id: "autocolant_2023",
				products: [1, 2, 4, 5, 6, 7, 11, 12, 13, 14, 15],
				file_name: "autocolant_2023.png",
				description: "Autocolant 2023",
			},
		],
		medium: [
			{
				id: "autocolant_2023",
				products: [1, 2, 4, 5, 6, 7, 11, 12, 13, 14, 15],
				file_name: "autocolant_2023.png",
				description: "Autocolant 2023",
			},
		],

	},
];
